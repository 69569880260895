import React, { Component, Fragment } from 'react';
import { Switch, Link, NavLink, Redirect, Route } from 'react-router-dom';
import { OverlayTrigger, Popover, Tooltip, Button, } from 'react-bootstrap';
import { translate } from 'react-i18next';
import StartDatePicker from './StartDatePicker/StartDatePicker';
import EndDatePicker from './EndDatePicker/EndDatePicker';
import lastOperationsIcon from './../img/last-operations-icon.png';
import { request } from './../../../config';
import 'bootstrap/dist/css/bootstrap.min.css';
import operationFilter from './../../../helpers/operationFilter';
import YesNoModal from '../../../copms/Modal/YesNoModal';
import InfoIcon from '../img/red_info_icon.svg';
import $, { data } from 'jquery';
import Loading from './../Loading/Loading';
import SalaryOperationModal from '../../../copms/Modal/SalaryOperationModal';
import OutsideClickHandler from 'react-outside-click-handler';

function base64ToArrayBuffer(base64) {
    let binaryString = window.atob(base64);
    let binaryLen = binaryString.length;
    let bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
        let ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes;
}

function saveFile(name, type, data) {
    if (data !== null && navigator.msSaveBlob)
        return navigator.msSaveBlob(new Blob([data], { type: type }), name);
    var a = $("<a style='display: none;'/>");
    var url = window.URL.createObjectURL(new Blob([data], { type: type }));
    a.attr("href", url);
    a.attr("download", name);
    $("body").append(a);
    a[0].click();
    window.URL.revokeObjectURL(url);
    a.remove();
}

class OperationList extends Component {
    state = {
        signLevel: null,
        signCount: null,
        operations: [],
        searchedValidation: false,
        minAmount: '',
        maxAmount: '',
        startDate: null,
        endDate: null,
        dtIban: null,
        operStateId: null,
        selectedStatus: "",
        selectedAccount: "",
        currency: "AZN",
        dropdownVisibilityStatus: false,
        dropdownVisibilityAccount: false,
        accounts: [],
        convertations: [],
        transfers: [],
        deleteModalOpen: false,
        deleteId: -1,
        accountData: false,
        open: false,
        id: -1,
        currentPage: 1,
        lastPage: 0,
    }

    updateAccount = (dtIban, currName, event) => {
        this.setState({ dtIban: dtIban, selectedAccount: event.target.textContent, dropdownVisibilityAccount: false, currency: currName });
    }
    updateDate = (name, value) => {
        this.setState({ [name]: value });
    }
    updateStatus = (id) => {
        let signCount;
        if (localStorage.getItem('custObject') && localStorage.getItem('compId')) {
            let custObject = JSON.parse(localStorage.getItem('custObject'));
            signCount = custObject.company.signCount;
        }
        // console.log('signCount' + signCount);

        switch (id) {
            case null: this.setState({ selectedStatus: this.props.t('all') }); break;
            case 1:
                if (signCount === 1) {
                    this.setState({ selectedStatus: this.props.t('authorization') });
                }
                else {
                    this.setState({ selectedStatus: this.props.t('2nd authorization') });
                }
                break;
            case 2: this.setState({ selectedStatus: this.props.t('1st authorization') }); break;
            case 3: this.setState({ selectedStatus: this.props.t('Confirmation is expected') }); break;
            case 4: this.setState({ selectedStatus: this.props.t('Approved') }); break;
            case 5: this.setState({ selectedStatus: this.props.t('refusal') }); break;
        }
        this.setState({ dropdownVisibilityStatus: false, operStateId: id });
    }
    updateAmount = (name, event) => {
        let mask = /^\d*\.?\d*$/;
        if (event.target.value !== event.target.value.replace(mask, '')) {
            event.target.value = event.target.value.replace(mask, '');
        }
        this.setState({ [name]: event.target.value });
    }
    changeDropdownVisibilityStatus = () => {
        this.setState({ dropdownVisibilityStatus: !this.state.dropdownVisibilityStatus });
    }
    changeDropdownVisibilityAccount = () => {
        this.setState({ dropdownVisibilityAccount: !this.state.dropdownVisibilityAccount });
    }
    getFormatedDate(date) {
        if (date) {
            let formatedDate = {
                day: (date.getDate() < 9) ? ('0' + date.getDate()) : date.getDate(),
                month: (date.getMonth() < 9) ? ('0' + (date.getMonth() + 1)) : (date.getMonth() + 1),
                year: date.getFullYear().toString()
            }
            return formatedDate.day + '.' + formatedDate.month + '.' + formatedDate.year;
        }
        return null;
    }
    async showAccounts() {
        this.setState({ isLoading: true, accountData: false });
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getNoCardAccountListForCustomerRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            isBalanceAccount: 0
        } : {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase(),
                isBalanceAccount: 0
            }

        await fetch(request("account/getNoCardAccountListFor" + customerType + "Customer", getNoCardAccountListForCustomerRequestBody))
            .then(res => res.text())
            .then((getNoCardAccountListForCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getNoCardAccountListForCustomerResponse = JSON.parse(getNoCardAccountListForCustomerResponseJSON);
                if (getNoCardAccountListForCustomerResponse.status.statusCode !== 1 && getNoCardAccountListForCustomerResponse.status.statusCode !== 126) {
                    if (getNoCardAccountListForCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getNoCardAccountListForCustomerResponse.status.statusCode));
                        this.setState({ isLoading: false });
                    }
                }
                else {
                    if (getNoCardAccountListForCustomerResponse.status.statusCode === 126) {
                        getNoCardAccountListForCustomerResponse.accountList = [];
                    }
                    for (let i = 0; i < getNoCardAccountListForCustomerResponse.accountList.length; i++) {
                        // // =========ID=========
                        let accountId = "";
                        if (typeof (getNoCardAccountListForCustomerResponse.accountList[i].accountId) === 'object' && getNoCardAccountListForCustomerResponse.account[i].accountId.hasOwnProperty('c')) {
                            getNoCardAccountListForCustomerResponse.account[i].id.c.forEach(part => {
                                accountId += part;
                            });
                        }
                        else {
                            accountId += getNoCardAccountListForCustomerResponse.accountList[i].accountId;
                        }
                        getNoCardAccountListForCustomerResponse.accountList[i].accountId = accountId;
                        // =========BALANCE=========
                        getNoCardAccountListForCustomerResponse.accountList[i].currentBalance = getNoCardAccountListForCustomerResponse.accountList[i].currentBalance.toFixed(2);
                    }
                    this.setState({ accounts: getNoCardAccountListForCustomerResponse.accountList, accountData: true });
                    this.setState({ isLoading: false });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }
    async searchSalary(initial) {
        if (!initial) {
            this.props.updateAppState('isLoadingTransparentBackground', true);
        }
        this.setState({ searchedValidation: false });
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let operationAdvancedSearchRequestBody = customerType === 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            operStateId: this.state.operStateId,
            dtIban: this.state.dtIban,
            minAmt: this.state.minAmount,
            maxAmt: this.state.maxAmount,
            startDate: this.getFormatedDate(this.state.startDate),
            endDate: this.getFormatedDate(this.state.endDate),
            pageNumber: this.state.currentPage,
            operNameId: null
        } :
            {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase(),
                operStateId: this.state.operStateId,
                dtIban: this.state.dtIban,
                minAmt: this.state.minAmount,
                maxAmt: this.state.maxAmount,
                startDate: this.getFormatedDate(this.state.startDate),
                endDate: this.getFormatedDate(this.state.endDate),
                pageNumber: this.state.currentPage,
                operNameId: null
            }
        await fetch(request("salary/salaryAdvancedSearch", operationAdvancedSearchRequestBody))
            .then(res => res.text())
            .then((operationAdvancedSearchResponseJSON) => {
                const JSON = require('true-json-bigint');
                let operationAdvancedSearchResponse = JSON.parse(operationAdvancedSearchResponseJSON);
                if (operationAdvancedSearchResponse.status.statusCode !== 1 && operationAdvancedSearchResponse.status.statusCode !== 240) {
                    if (operationAdvancedSearchResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(operationAdvancedSearchResponse.status.statusCode));
                        if (!initial) {
                            this.props.updateAppState('isLoadingTransparentBackground', false);
                        }
                    }
                }
                else {
                    this.setState({ lastPage: operationAdvancedSearchResponse.pageCount ? operationAdvancedSearchResponse.pageCount : 0 })

                    if (operationAdvancedSearchResponse.status.statusCode === 240) {
                        operationAdvancedSearchResponse.operationList = [];
                        this.setState({ operations: [], searchedValidation: true });
                    }
                    else {
                        for (let i = 0; i < operationAdvancedSearchResponse.advancedSearches.length; i++) {
                            // =========AMOUNT=========
                            if (operationAdvancedSearchResponse.advancedSearches[i].amount) {
                                if (operationAdvancedSearchResponse.advancedSearches[i].amount.toString() === "0") {
                                    operationAdvancedSearchResponse.advancedSearches[i].organizedAmount = {
                                        wholePart: "0",
                                        fractionalPart: "00"
                                    }
                                }
                                else {
                                    if (operationAdvancedSearchResponse.advancedSearches[i].amount.toString().indexOf('.') !== -1) {
                                        let currentBalanceArray = operationAdvancedSearchResponse.advancedSearches[i].amount.toString().split('.');
                                        operationAdvancedSearchResponse.advancedSearches[i].organizedAmount = {
                                            wholePart: currentBalanceArray[0],
                                            fractionalPart: (currentBalanceArray[1].length === 1) ? (currentBalanceArray[1] + '0') : currentBalanceArray[1]
                                        }
                                    }
                                    else {
                                        operationAdvancedSearchResponse.advancedSearches[i].organizedAmount = {
                                            wholePart: operationAdvancedSearchResponse.advancedSearches[i].amount.toString(),
                                            fractionalPart: "00"
                                        }
                                    }
                                }
                            }
                        }
                        this.setState({ operations: operationAdvancedSearchResponse.advancedSearches });
                    }
                    if (!initial) {
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    async componentDidMount() {
        if (localStorage.getItem('custObject') && localStorage.getItem('compId')) {
            let custObject = JSON.parse(localStorage.getItem('custObject'));
            this.setState({ signLevel: custObject.company.signLevel, signCount: custObject.company.signCount });
        }
        await this.showAccounts();
        await this.searchSalary(true);
    }

    selectPage(e, page) {
        e.preventDefault();
        this.setState({ currentPage: page }, () => {
            this.searchSalary(false);
        });
    }

    downloadExcel(id) {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let downloadExcelRequestBody = customerType == 'Juridical' ? {
            compId: localStorage.getItem('compId'),
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            excelId: id,
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                excelId: id,
                lang: localStorage.getItem('i18nextLng').toUpperCase()
            }

        fetch(request("salary/getUploadedExcel", downloadExcelRequestBody))
            .then(res => res.text())
            .then((downloadExcelResponseJSON) => {
                const JSON = require('true-json-bigint');
                let downloadExcelResponse = JSON.parse(downloadExcelResponseJSON);
                if (downloadExcelResponse.status.statusCode !== 1) {
                    if (downloadExcelResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(downloadExcelResponse.status.statusCode));
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    let arrrayBuffer = base64ToArrayBuffer(downloadExcelResponse.bytes); //data is the base64 encoded string
                    saveFile("salary_operations_" + id + ".xlsx", "application/xlsx", arrrayBuffer);
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            });
    }

    downloadAdoc(id) {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let getAsanDocByOperIdRequestBody = {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            excelId: id
        }
        fetch(request("salary/getAsanDocByExcelId", getAsanDocByOperIdRequestBody))
            .then(res => res.text())
            .then((getAsanDocByOperIdResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getAsanDocByOperIdResponse = JSON.parse(getAsanDocByOperIdResponseJSON);
                if (getAsanDocByOperIdResponse.status.statusCode !== 1) {
                    if (getAsanDocByOperIdResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getAsanDocByOperIdResponse.status.statusCode));
                    }
                }
                else {
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                    let arrrayBuffer = base64ToArrayBuffer(getAsanDocByOperIdResponse.bytes); //data is the base64 encoded string

                    saveFile("salary_" + id + ".adoc", "application/adoc", arrrayBuffer)
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    deleteConfirm() {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let downloadExcelRequestBody = customerType == 'Juridical' ? {
            compId: localStorage.getItem('compId'),
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            excelId: this.state.deleteId,
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                excelId: this.state.deleteId,
                lang: localStorage.getItem('i18nextLng').toUpperCase()
            }

        fetch(request("salary/deleteUploadedExcel", downloadExcelRequestBody))
            .then(res => res.text())
            .then((downloadExcelResponseJSON) => {
                const JSON = require('true-json-bigint');
                let downloadExcelResponse = JSON.parse(downloadExcelResponseJSON);
                if (downloadExcelResponse.status.statusCode !== 1) {
                    if (downloadExcelResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(downloadExcelResponse.status.statusCode));
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    var operations = this.state.operations;
                    operations.splice(operations.findIndex(el => el.excelId === this.state.deleteId), 1);
                    this.setState({ operations: operations });
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            });

    }

    render() {
        let { updateAppState, alertState, t, } = this.props;
        let { signCount, accountData, operations, selectedStatus, id, selectedAccount, minAmount, maxAmount, dropdownVisibilityStatus, dropdownVisibilityAccount, operStateId, dtIban, accounts, currency, transfers, convertations, searchedValidation, deleteModalOpen, isLoading, open } = this.state;

        return (
            <Fragment>
                <SalaryOperationModal history={this.props.history} logout={() => this.props.logout()} alertState={this.props.alertState} open={open} id={id} updateAppState={updateAppState} onClose={() => this.setState({ open: false })} />
                <YesNoModal open={deleteModalOpen} title={t('Are you sure you want to delete the operation?')} onClose={() => this.setState({ deleteModalOpen: false })} triggerFunc={val => val && this.deleteConfirm()} />
                <div className="dashboard-header-route">
                    <div className="dashboard-header-route-left">
                        <button onClick={() => this.props.history.goBack()}>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.7344 21.2188L12.6719 20.3281C12.9062 20.0938 12.9062 19.7188 12.6719 19.5312L5.40625 12.2188H21.4375C21.7188 12.2188 22 11.9844 22 11.6562V10.3438C22 10.0625 21.7188 9.78125 21.4375 9.78125H5.40625L12.6719 2.51562C12.9062 2.32812 12.9062 1.95312 12.6719 1.71875L11.7344 0.828125C11.5469 0.59375 11.1719 0.59375 10.9375 0.828125L1.14062 10.625C0.90625 10.8594 0.90625 11.1875 1.14062 11.4219L10.9375 21.2188C11.1719 21.4531 11.5469 21.4531 11.7344 21.2188Z" fill="#2E3131" />
                            </svg>
                        </button>
                        <h2>{t('List of operations')}</h2>
                    </div>
                </div>
                {
                    isLoading ?
                        <div className="dashboard-container-main-section">
                            <Loading />
                        </div>
                        :
                        <div className="dashboard-container-main-section-vertical">
                            <div className="dashboard-transfer-search-form-section">
                                <div className="dashboard-transfer-search-form-section-item">
                                    <OutsideClickHandler onOutsideClick={() => { this.setState({ dropdownVisibilityAccount: false }) }}>
                                        <div className="dashboard-payment-container-data-section-content-container-form-select-box">
                                            <button className="dashboard-payment-container-data-section-content-container-form-select-box-button" onClick={this.changeDropdownVisibilityAccount}>
                                                <div className="dashboard-payment-container-data-section-content-container-form-select" style={!dtIban && !(accounts && accounts.length === 0 && accountData) ? { color: '#BDC3C7' } : {}}>
                                                    {
                                                        dtIban ? selectedAccount : accounts && accounts.length === 0 && accountData ? t('126') : t("all")
                                                    }
                                                </div>
                                                <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                    <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                    </svg>
                                                </div>
                                            </button>
                                            {
                                                accounts.length !== 0 &&
                                                <ul className={(dropdownVisibilityAccount === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                    <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item">
                                                        <button type="button" className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button" onClick={this.updateAccount.bind(this, null, "AZN")}>{t("all")}</button>
                                                    </li>
                                                    {
                                                        accounts.map(account => (
                                                            <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={account.accountId}>
                                                                <button type="button" className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button" onClick={this.updateAccount.bind(this, account.iban, account.currName)}>{account.iban} / {account.currentBalance} {account.currName}</button>
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                            }
                                        </div>
                                    </OutsideClickHandler>
                                </div>
                                <div className="dashboard-transfer-search-form-section-item">
                                    <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                        <input type="text" className="dashboard-payment-container-data-section-content-container-form-input" value={minAmount} placeholder={t("From the amount")} onChange={e => /^\d*\.{0,1}(\d{1,2})?$/.test(e.target.value) && this.setState({ minAmount: e.target.value })} />
                                        <div className="dashboard-payment-container-data-section-content-container-form-icon dashboard-payment-container-data-section-content-container-form-icon__currency">{currency}</div>
                                    </div>
                                </div>
                                <div className="dashboard-transfer-search-form-section-item">
                                    <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                        <input type="text" className="dashboard-payment-container-data-section-content-container-form-input" value={maxAmount} placeholder={t("To the amount")} onChange={e => /^\d*\.{0,1}(\d{1,2})?$/.test(e.target.value) && this.setState({ maxAmount: e.target.value })} />
                                        <div className="dashboard-payment-container-data-section-content-container-form-icon dashboard-payment-container-data-section-content-container-form-icon__currency">{currency}</div>
                                    </div>
                                </div>
                                <div className="dashboard-transfer-search-form-section-item">
                                    <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                        <StartDatePicker value={this.state.startDate} placeholder={t("Start date")} updateDate={this.updateDate} />
                                    </div>
                                </div>
                                <div className="dashboard-transfer-search-form-section-item">
                                    <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                        <EndDatePicker value={this.state.endDate} placeholder={t("End date")} updateDate={this.updateDate} />
                                    </div>
                                </div>
                                <div className="dashboard-transfer-search-form-section-item">
                                    <OutsideClickHandler onOutsideClick={() => { this.setState({ dropdownVisibilityStatus: false }) }}>
                                        <div className="dashboard-payment-container-data-section-content-container-form-select-box">
                                            <button className="dashboard-payment-container-data-section-content-container-form-select-box-button" onClick={this.changeDropdownVisibilityStatus}>
                                                {
                                                    operStateId ?
                                                        <div className="dashboard-payment-container-data-section-content-container-form-select">{selectedStatus}</div>
                                                        :
                                                        <div className="dashboard-payment-container-data-section-content-container-form-select" style={{ color: '#BDC3C7' }}>{t("all")}</div>
                                                }
                                                <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                    <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                    </svg>
                                                </div>
                                            </button>
                                            <ul className={(dropdownVisibilityStatus === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item">
                                                    <button type="button" className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button" onClick={this.updateStatus.bind(this, null)}>{t('all')}</button>
                                                </li>
                                                {
                                                    localStorage.getItem('compId') && signCount === 2 &&
                                                    <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item">
                                                        <button type="button" className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button" onClick={this.updateStatus.bind(this, 2)}>{t('1st authorization')}</button>
                                                    </li>
                                                }
                                                <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item">
                                                    <button type="button" className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button" onClick={this.updateStatus.bind(this, 1)}>
                                                        {
                                                            signCount === 1 && localStorage.getItem('compId') ?
                                                                t('authorization')
                                                                :
                                                                t('2nd authorization')
                                                        }
                                                    </button>
                                                </li>
                                                <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item">
                                                    <button type="button" className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button" onClick={this.updateStatus.bind(this, 3)}>{t('Confirmation is expected')}</button>
                                                </li>
                                                <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item">
                                                    <button type="button" className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button" onClick={this.updateStatus.bind(this, 4)}>{t('Approved')}</button>
                                                </li>
                                                <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item">
                                                    <button type="button" className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button" onClick={this.updateStatus.bind(this, 5)}>{t('refusal')}</button>
                                                </li>
                                            </ul>
                                        </div>
                                    </OutsideClickHandler>
                                </div>
                                <div className="dashboard-transfer-search-form-section-item">
                                    <button className="dashboard-transfer-search-form-section-item-button" onClick={() => this.searchSalary(false)}>{t("Search")}</button>
                                </div>
                            </div>
                            <div className="dashboard-transfer-search-list-section">
                                {
                                    operations.length === 0 ?
                                        <div className="dashboard-last-operations-section-content" style={{ minHeight: '70vh', justifyContent: 'center' }}>
                                            <img src={lastOperationsIcon} className="dashboard-last-operations-section-content-image" alt="lastOperationsIcon" />
                                            <p className="dashboard-last-operations-section-content-status">{t('you do not currently have any active transactions')}</p>
                                        </div>
                                        :
                                        <>
                                            <ul className="dashboard-transfer-history-content" style={{ minHeight: '70vh' }}>
                                                {
                                                    operations.length !== 0 ?
                                                        <table className="dashboard-transfer-list-table">
                                                            <thead className="dashboard-transfer-list-table-head">
                                                                <tr className="dashboard-transfer-list-table-row dashboard-transfer-list-table-row__head">
                                                                    <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head">{t("HİSTORY")}</th>
                                                                    <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head">{t("APPOINTMENT")}</th>
                                                                    <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head">{t("NAME OF THE SENDER")}</th>
                                                                    <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head">{t("AMOUNT")}</th>
                                                                    <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head">{t("CURRENCY")}</th>
                                                                    <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head">{t("STATUS")}</th>
                                                                    <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head">ADOC</th>
                                                                    <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head">EXCEL</th>
                                                                    <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head"></th>
                                                                    <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head"></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="dashboard-transfer-list-table-body">
                                                                {
                                                                    operations.map((item, index) => {
                                                                        return (
                                                                            <tr className="dashboard-transfer-list-table-row" key={index}>
                                                                                {
                                                                                    item.createdDate ? <td className="dashboard-transfer-list-table-cell">{item.createdDate.split(" ")[0]}</td> : <td className="dashboard-transfer-list-table-cell"></td>
                                                                                }
                                                                                <td className="dashboard-transfer-list-table-cell">{item.purpose}</td>
                                                                                <td className="dashboard-transfer-list-table-cell">{item.dtCustName}</td>
                                                                                <td className="dashboard-transfer-list-table-cell">{item.amount.toFixed(2)}</td>
                                                                                <td className="dashboard-transfer-list-table-cell">AZN</td>
                                                                                <td className="dashboard-transfer-list-table-cell">
                                                                                    {
                                                                                        item.operStateId === 1 && signCount === 1 && t('authorization')
                                                                                    }
                                                                                    {
                                                                                        item.operStateId === 1 && signCount === 2 && t('2nd authorization')
                                                                                    }
                                                                                    {
                                                                                        item.operStateId === 2 && t('1st authorization')
                                                                                    }
                                                                                    {
                                                                                        item.operStateId === 3 && t('Confirmation is expected')
                                                                                    }
                                                                                    {
                                                                                        item.operStateId === 4 && t('Approved')
                                                                                    }
                                                                                    {
                                                                                        item.operStateId === 5 && t('refusal')
                                                                                    }
                                                                                    {
                                                                                        item.errorReason && item.errorReason.length > 0 &&
                                                                                        <OverlayTrigger
                                                                                            trigger="click"
                                                                                            key={'bottom'}
                                                                                            placement={'bottom'}
                                                                                            rootClose={true}
                                                                                            overlay={
                                                                                                <Popover id={`popover-positioned-bottom`}>
                                                                                                    {/* <Popover.Title as="h3">{`Popover `}</Popover.Title> */}
                                                                                                    <Popover.Content>
                                                                                                        {item.errorReason}
                                                                                                    </Popover.Content>
                                                                                                </Popover>
                                                                                            }
                                                                                        >
                                                                                            <button style={{ marginLeft: '15px' }}>
                                                                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                    <path d="M8 1.03226C11.8065 1.03226 14.9677 4.16129 14.9677 8C14.9677 11.871 11.8387 14.9677 8 14.9677C4.12903 14.9677 1.03226 11.871 1.03226 8C1.03226 4.16129 4.12903 1.03226 8 1.03226ZM8 0C3.58065 0 0 3.6129 0 8C0 12.4194 3.58065 16 8 16C12.3871 16 16 12.4194 16 8C16 3.6129 12.3871 0 8 0ZM6.83871 11.0968C6.6129 11.0968 6.45161 11.2903 6.45161 11.4839V11.7419C6.45161 11.9677 6.6129 12.129 6.83871 12.129H9.16129C9.35484 12.129 9.54839 11.9677 9.54839 11.7419V11.4839C9.54839 11.2903 9.35484 11.0968 9.16129 11.0968H8.77419V6.58065C8.77419 6.3871 8.58064 6.19355 8.3871 6.19355H6.83871C6.6129 6.19355 6.45161 6.3871 6.45161 6.58065V6.83871C6.45161 7.06452 6.6129 7.22581 6.83871 7.22581H7.22581V11.0968H6.83871ZM8 3.35484C7.41935 3.35484 6.96774 3.83871 6.96774 4.3871C6.96774 4.96774 7.41935 5.41935 8 5.41935C8.54839 5.41935 9.03226 4.96774 9.03226 4.3871C9.03226 3.83871 8.54839 3.35484 8 3.35484Z" fill="#2873F0" />
                                                                                                </svg>
                                                                                            </button>
                                                                                        </OverlayTrigger>
                                                                                    }
                                                                                </td>
                                                                                <td>
                                                                                    {
                                                                                        item.operStateId !== 1 &&
                                                                                        <button onClick={() => this.downloadAdoc(item.excelId)}>
                                                                                            <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                <path d="M6.75 7.40625C6.75 7.1875 6.5625 7.03125 6.375 7.03125H5.625C5.40625 7.03125 5.25 7.1875 5.25 7.40625V10.0312H3.71875C3.375 10.0312 3.21875 10.4375 3.4375 10.6562L5.6875 12.9062C5.84375 13.0625 6.125 13.0625 6.28125 12.9062L8.53125 10.6562C8.75 10.4375 8.59375 10.0312 8.25 10.0312H6.75V7.40625ZM11.5312 3.0625L8.90625 0.46875C8.625 0.1875 8.25 0 7.875 0H1.46875C0.65625 0.03125 0 0.6875 0 1.53125V14.5312C0 15.3438 0.65625 16 1.46875 16H10.4688C11.3125 16 12 15.3438 12 14.5312V4.125C12 3.75 11.8125 3.34375 11.5312 3.0625ZM7.96875 1.625L10.375 4.03125H7.96875V1.625ZM10.5 14.5312H1.46875V1.53125H6.46875V4.78125C6.46875 5.1875 6.8125 5.53125 7.21875 5.53125H10.5V14.5312Z" fill="#586268" />
                                                                                            </svg>
                                                                                        </button>
                                                                                    }
                                                                                </td>
                                                                                <td>
                                                                                    <button onClick={() => this.downloadExcel(item.excelId)}>
                                                                                        <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                            <path d="M6.75 7.40625C6.75 7.1875 6.5625 7.03125 6.375 7.03125H5.625C5.40625 7.03125 5.25 7.1875 5.25 7.40625V10.0312H3.71875C3.375 10.0312 3.21875 10.4375 3.4375 10.6562L5.6875 12.9062C5.84375 13.0625 6.125 13.0625 6.28125 12.9062L8.53125 10.6562C8.75 10.4375 8.59375 10.0312 8.25 10.0312H6.75V7.40625ZM11.5312 3.0625L8.90625 0.46875C8.625 0.1875 8.25 0 7.875 0H1.46875C0.65625 0.03125 0 0.6875 0 1.53125V14.5312C0 15.3438 0.65625 16 1.46875 16H10.4688C11.3125 16 12 15.3438 12 14.5312V4.125C12 3.75 11.8125 3.34375 11.5312 3.0625ZM7.96875 1.625L10.375 4.03125H7.96875V1.625ZM10.5 14.5312H1.46875V1.53125H6.46875V4.78125C6.46875 5.1875 6.8125 5.53125 7.21875 5.53125H10.5V14.5312Z" fill="#586268" />
                                                                                        </svg>
                                                                                    </button>
                                                                                </td>
                                                                                <td>
                                                                                    {
                                                                                        item.operStateId === 1 &&
                                                                                        <button onClick={() => this.setState({ deleteId: item.excelId }, () => this.setState({ deleteModalOpen: true }))}>
                                                                                            <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                <path d="M8.375 13H9.125C9.3125 13 9.5 12.8438 9.5 12.625V5.875C9.5 5.6875 9.3125 5.5 9.125 5.5H8.375C8.15625 5.5 8 5.6875 8 5.875V12.625C8 12.8438 8.15625 13 8.375 13ZM13.5 2.5H10.9062L9.84375 0.75C9.59375 0.34375 9.03125 0 8.5625 0H5.40625C4.9375 0 4.375 0.34375 4.125 0.75L3.0625 2.5H0.5C0.21875 2.5 0 2.75 0 3V3.5C0 3.78125 0.21875 4 0.5 4H1V14.5C1 15.3438 1.65625 16 2.5 16H11.5C12.3125 16 13 15.3438 13 14.5V4H13.5C13.75 4 14 3.78125 14 3.5V3C14 2.75 13.75 2.5 13.5 2.5ZM5.34375 1.59375C5.375 1.5625 5.46875 1.5 5.5 1.5H5.53125H8.46875C8.5 1.5 8.59375 1.5625 8.625 1.59375L9.15625 2.5H4.8125L5.34375 1.59375ZM11.5 14.5H2.5V4H11.5V14.5ZM4.875 13H5.625C5.8125 13 6 12.8438 6 12.625V5.875C6 5.6875 5.8125 5.5 5.625 5.5H4.875C4.65625 5.5 4.5 5.6875 4.5 5.875V12.625C4.5 12.8438 4.65625 13 4.875 13Z" fill="#586268" />
                                                                                            </svg>
                                                                                        </button>
                                                                                    }
                                                                                </td>
                                                                                <td>
                                                                                    {
                                                                                        item.operStateId === 4 &&
                                                                                        <button onClick={() => this.setState({ id: item.excelId }, () => this.setState({ open: true }))}>
                                                                                            <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                <path d="M8.01391 2.29167C7.76348 2.32031 7.37391 2.3776 7.12348 2.4349C7.23478 2.63542 7.31826 3.00781 7.34609 3.20833C7.34609 4.09635 6.62261 4.8125 5.78783 4.8125C5.56522 4.8125 5.20348 4.72656 5.03652 4.61198C4.95304 4.86979 4.89739 5.24219 4.89739 5.5C4.89739 7.27604 6.2887 8.70833 8.01391 8.70833C9.73913 8.70833 11.1304 7.27604 11.1304 5.5C11.1304 3.7526 9.73913 2.29167 8.01391 2.29167ZM15.9165 5.09896C14.4139 2.0625 11.4087 0 8.01391 0C4.5913 0 1.58609 2.0625 0.0834783 5.09896C0.0278261 5.21354 0 5.38542 0 5.52865C0 5.64323 0.0278261 5.8151 0.0834783 5.92969C1.58609 8.96615 4.5913 11 8.01391 11C11.4087 11 14.4139 8.96615 15.9165 5.92969C15.9722 5.8151 16 5.64323 16 5.5C16 5.38542 15.9722 5.21354 15.9165 5.09896ZM8.01391 9.625C5.25913 9.625 2.72696 8.04948 1.3913 5.5C2.72696 2.95052 5.25913 1.375 8.01391 1.375C10.7409 1.375 13.273 2.95052 14.6087 5.5C13.273 8.04948 10.7409 9.625 8.01391 9.625Z" fill="#586268" />
                                                                                            </svg>
                                                                                        </button>
                                                                                    }
                                                                                </td>


                                                                            </tr>
                                                                        )
                                                                    })
                                                                }

                                                            </tbody>
                                                        </table>
                                                        :
                                                        <div className="dashboard-last-operations-section-content">
                                                            <img src={lastOperationsIcon} className="dashboard-last-operations-section-content-image" alt="lastOperationsIcon" />
                                                            <p className="dashboard-last-operations-section-content-status">{t('you do not currently have any active transactions')}</p>
                                                        </div>
                                                }
                                            </ul>
                                            <div style={{ width: '100%', marginTop: '15px', marginBottom: '15px', display: 'flex', justifyContent: 'center' }}>
                                                <ul className="dashboard-pagination">
                                                    {this.state.currentPage != 1 && <li><a href="#" onClick={(e) => this.selectPage(e, this.state.currentPage - 1)}>{t('previous-page')}</a></li>}
                                                    {
                                                        Array.from({ length: this.state.lastPage }).map((item, index) => {
                                                            if (this.state.lastPage > 3) {
                                                                if (this.state.currentPage - 1 == index + 1) {
                                                                    return (
                                                                        <>
                                                                            {this.state.currentPage > 2 && <li><a href="#" onClick={(e) => this.selectPage(e, 1)}>1</a></li>}

                                                                            {this.state.currentPage > 3 && <li><a href="#" style={{ cursor: 'default' }} onClick={(e) => { e.preventDefault(); }}>...</a></li>}
                                                                            <li><a href="#" onClick={(e) => this.selectPage(e, index + 1)}>{index + 1}</a></li>
                                                                        </>
                                                                    )
                                                                }
                                                                if (this.state.currentPage == index + 1) {
                                                                    return (
                                                                        <>
                                                                            <li className="active" ><a href="#" style={{ cursor: 'default' }} onClick={e => e.preventDefault()}>{index + 1}</a></li>
                                                                        </>
                                                                    )
                                                                }
                                                                if (this.state.currentPage + 1 == index + 1) {
                                                                    return (
                                                                        <>
                                                                            <li><a href="#" onClick={(e) => this.selectPage(e, index + 1)}>{index + 1}</a></li>
                                                                            {this.state.currentPage < this.state.lastPage - 2 && <li ><a href="#" style={{ cursor: 'default' }} onClick={(e) => { e.preventDefault(); }}>...</a></li>}

                                                                            {this.state.currentPage + 1 < this.state.lastPage && <li ><a href="#" onClick={(e) => this.selectPage(e, this.state.lastPage)}>{this.state.lastPage}</a></li>}
                                                                        </>
                                                                    )
                                                                }
                                                            } else {
                                                                return (
                                                                    <li className={index + 1 == this.state.currentPage ? "active" : ""}><a href="#" style={this.state.lastPage == 1 ? { borderRadius: '5px' } : {}} onClick={(e) => this.selectPage(e, index + 1)}>{index + 1}</a></li>
                                                                )
                                                            }
                                                        })

                                                    }
                                                    {this.state.currentPage != this.state.lastPage && this.state.lastPage > 1 && <li><a href="#" onClick={(e) => this.selectPage(e, this.state.currentPage + 1)}>{t('next-page')}</a></li>}
                                                </ul>
                                            </div>
                                        </>
                                }

                            </div>
                        </div>
                }
            </Fragment >
        )
    }
}

export default translate("translation")(OperationList);