import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { NavLink, Link } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import EndDatePicker from './EndDatePicker/EndDatePicker';
import lastOperationsIcon from './../img/last-operations-icon.png';
import { request } from '../../../config';
import $ from 'jquery';

function base64ToArrayBuffer(base64) {
    let binaryString = window.atob(base64);
    let binaryLen = binaryString.length;
    let bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
        let ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes;
}
function saveFile(name, type, data) {
    if (data != null && navigator.msSaveBlob)
        return navigator.msSaveBlob(new Blob([data], { type: type }), name);
    var a = $("<a style='display: none;'/>");
    var url = window.URL.createObjectURL(new Blob([data], { type: type }));
    a.attr("href", url);
    a.attr("download", name);
    $("body").append(a);
    a[0].click();
    window.URL.revokeObjectURL(url);
    a.remove();
}

class History extends Component {
    state = {
        status: 1,
        excelFileOutline: false,
        endDate: null,
        search: '',
        currentPage: 1,
        lastPage: 0,
        cards: [],
    }

    setValues = (value, name) => {
        let newFormData = {
            ...this.state.formData,
            [name]: value
        };
        this.setState({ formData: newFormData })
    };

    updateDate = (name, value) => {
        this.setState({ [name]: value });
    };

    getFormatedDate(date) {
        if (date) {
            let formatedDate = {
                day: (date.getDate() < 9) ? ('0' + date.getDate()) : date.getDate(),
                month: (date.getMonth() < 9) ? ('0' + (date.getMonth() + 1)) : (date.getMonth() + 1),
                year: date.getFullYear().toString()
            }
            return formatedDate.year + '-' + formatedDate.month + '-' + formatedDate.day;
        }
        return null;
    }

    selectPage(e, page) {
        e.preventDefault();
        this.setState({ currentPage: page }, () => this.getList())
    }

    componentDidMount() {
        this.getList();
    }

    getList = () => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let getBatchSalaryOrderCardsRequestBody = {
            token: localStorage.getItem('token'),
            expiry: this.getFormatedDate(this.state.endDate),
            pageNumber: this.state.currentPage,
            search: this.state.search
        }
        fetch(request("salary/getBatchSalaryOrderCards", getBatchSalaryOrderCardsRequestBody))
            .then(res => res.text())
            .then((getBatchSalaryOrderCardsResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getBatchSalaryOrderCardsResponse = JSON.parse(getBatchSalaryOrderCardsResponseJSON);
                if (getBatchSalaryOrderCardsResponse.status.statusCode !== 1) {
                    if (getBatchSalaryOrderCardsResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getBatchSalaryOrderCardsResponse.status.statusCode));
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                    this.setState({
                        lastPage: getBatchSalaryOrderCardsResponse.totalPage,
                        cards: getBatchSalaryOrderCardsResponse.cards || []
                    });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    exportList = () => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let exportBatchSalaryCardsRequestBody = {
            token: localStorage.getItem('token')
        }
        fetch(request("salary/exportBatchSalaryCards", exportBatchSalaryCardsRequestBody))
            .then(res => res.text())
            .then((exportBatchSalaryCardsResponseJSON) => {
                const JSON = require('true-json-bigint');
                let exportBatchSalaryCardsResponse = JSON.parse(exportBatchSalaryCardsResponseJSON);
                if (exportBatchSalaryCardsResponse.status.statusCode !== 1) {
                    if (exportBatchSalaryCardsResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(exportBatchSalaryCardsResponse.status.statusCode));
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    let arrrayBuffer = base64ToArrayBuffer(exportBatchSalaryCardsResponse.file);
                    saveFile(exportBatchSalaryCardsResponse.fileName, `application/${exportBatchSalaryCardsResponse.fileName.substring(exportBatchSalaryCardsResponse.fileName.lastIndexOf('.'))}`, arrrayBuffer)
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    render() {
        let { cards, search } = this.state;
        let { t } = this.props;
        return (
            <>
                {/* <YesNoModal open={open} title={t('Əməliyyatı silmək istədiyinizdən əminsiniz?')} onClose={() => this.setState({ open: false })} triggerFunc={(bool) => bool ? this.deleteSalaryOrder(id) : this.setState({ open: false })} /> */}
                <div className="dashboard-header-route">
                    <div className="dashboard-header-route-left">
                        <button onClick={() => this.props.history.goBack()}>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.7344 21.2188L12.6719 20.3281C12.9062 20.0938 12.9062 19.7188 12.6719 19.5312L5.40625 12.2188H21.4375C21.7188 12.2188 22 11.9844 22 11.6562V10.3438C22 10.0625 21.7188 9.78125 21.4375 9.78125H5.40625L12.6719 2.51562C12.9062 2.32812 12.9062 1.95312 12.6719 1.71875L11.7344 0.828125C11.5469 0.59375 11.1719 0.59375 10.9375 0.828125L1.14062 10.625C0.90625 10.8594 0.90625 11.1875 1.14062 11.4219L10.9375 21.2188C11.1719 21.4531 11.5469 21.4531 11.7344 21.2188Z" fill="#2E3131" />
                            </svg>
                        </button>
                        <h2>{t('orders')}</h2>
                    </div>
                </div>
                <div className="dashboard-salary-container">
                    <div className="dashboard-salary-container-data-section" style={{ marginTop: '1px' }}>
                        <div className="dashboard-transfer-list-header">
                            <div className="dashboard-transfer-list-header-container dashboard-transfer-list-header-container__left">
                                <NavLink exact to="/dashboard/salary-project/order" className="dashboard-transfer-list-header-link" activeClassName="dashboard-transfer-list-header-container dashboard-transfer-list-header-link__active">{t("Order")}</NavLink>
                                <NavLink exact to="/dashboard/salary-project/history" className="dashboard-transfer-list-header-link" activeClassName="dashboard-transfer-list-header-container dashboard-transfer-list-header-link__active">{t("history")}</NavLink>
                                <NavLink exact to="/dashboard/salary-project/authorization" className="dashboard-transfer-list-header-link" activeClassName="dashboard-transfer-list-header-container dashboard-transfer-list-header-link__active">{t("authorization")}</NavLink>
                                <NavLink exact to="/dashboard/salary-project/list" className="dashboard-transfer-list-header-link" activeClassName="dashboard-transfer-list-header-container dashboard-transfer-list-header-link__active">{t("List of active salary cards")}</NavLink>
                            </div>
                        </div>
                        <div className="dashboard-salary-order-container">
                            <div className="dashboard-salary-order-container-box">
                                <div className="dashboard-salary-order-container-box-select">
                                    <label>{t("First name, Last name, Patronymic")}</label>
                                    <TextField
                                        name="search"
                                        value={search}
                                        placeholder={t("First name, Last name, Patronymic")}
                                        onChange={(e) => this.setState({ search: e.target.value })}
                                    />
                                </div>
                                <div className="dashboard-salary-order-container-box-select dashboard-salary-order-container-box-select-filter">
                                    <label>{t("expiration date")}</label>
                                    <div className="dashboard-salary-order-container-box-select-datepicker">
                                        <div className="dashboard-salary-order-container-box-select-datepicker-input">
                                            <EndDatePicker placeholder={t("End date")} value={this.state.endDate} updateDate={this.updateDate} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="dashboard-salary-order-container-box justify-content-end">
                                <button className="dashboard-salary-order-container-box-button dashboard-salary-order-container-box-button-main" onClick={this.getList}>{t("Search")}</button>
                                <button className="dashboard-salary-order-container-box-button dashboard-salary-order-container-box-button-outline" onClick={() => this.setState({ search: "", endDate: null })}>{t("Clear")}</button>
                                <button className="dashboard-salary-order-container-box-button dashboard-salary-order-container-box-button-outline" disabled={cards.length == 0} onClick={this.exportList}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none" >
                                        <path d="M18 12.5V13.5C18 14.9001 18 15.6002 17.7275 16.135C17.4878 16.6054 17.1054 16.9878 16.635 17.2275C16.1002 17.5 15.4001 17.5 14 17.5H7C5.59987 17.5 4.8998 17.5 4.36502 17.2275C3.89462 16.9878 3.51217 16.6054 3.27248 16.135C3 15.6002 3 14.9001 3 13.5V12.5M14.6667 8.33333L10.5 12.5M10.5 12.5L6.33333 8.33333M10.5 12.5V2.5" stroke="#0179D2" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                    {t("download")}
                                </button>
                            </div>
                            {cards.length === 0 ?
                                <div className="dashboard-last-operations-section-content" style={{ minHeight: '70vh', justifyContent: 'center' }}>
                                    <img src={lastOperationsIcon} className="dashboard-last-operations-section-content-image" alt="lastOperationsIcon" />
                                    <p className="dashboard-last-operations-section-content-status">{t('you do not currently have any active transactions')}</p>
                                </div>
                                : <>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>{t("FIN")}</th>
                                                <th>{t("FLP")}</th>
                                                <th>{t("account number")}</th>
                                                <th>{t("Card type")}</th>
                                                <th>{t("Status")}</th>
                                                <th>{t("expiration date")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {cards.length !== 0 && cards.map(item => (
                                                <tr>
                                                    <td>{item.pin}</td>
                                                    <td>{item.fullname}</td>
                                                    <td>{item.iban}</td>
                                                    <td>{item.cardType}</td>
                                                    <td>{item.cardStatus}</td>
                                                    <td>{item.expiry}</td>
                                                    {/* <td>
                                                        <button>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                                <g clip-path="url(#clip0_4_6361)">
                                                                    <path d="M14.0003 14H8.66701M1.66699 14.3334L5.36651 12.9105C5.60313 12.8195 5.72145 12.774 5.83214 12.7146C5.93046 12.6618 6.02419 12.6009 6.11235 12.5324C6.21161 12.4554 6.30124 12.3658 6.48051 12.1865L14.0003 4.66671C14.7367 3.93033 14.7367 2.73642 14.0003 2.00004C13.264 1.26366 12.0701 1.26366 11.3337 2.00004L3.81385 9.51985C3.63458 9.69912 3.54494 9.78876 3.46792 9.88801C3.39951 9.97617 3.33859 10.0699 3.28582 10.1682C3.2264 10.2789 3.18089 10.3972 3.08988 10.6339L1.66699 14.3334ZM1.66699 14.3334L3.03907 10.766C3.13726 10.5107 3.18635 10.3831 3.27055 10.3246C3.34414 10.2735 3.43519 10.2542 3.52319 10.271C3.62388 10.2902 3.72058 10.3869 3.91398 10.5803L5.42004 12.0864C5.61344 12.2798 5.71014 12.3765 5.72937 12.4772C5.74617 12.5652 5.72685 12.6562 5.67576 12.7298C5.61729 12.814 5.48965 12.8631 5.23437 12.9613L1.66699 14.3334Z" stroke="#667085" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                                </g>

                                                            </svg>
                                                        </button>

                                                    </td> */}
                                                    {/* <td>
                                                        <button className='button-close'>
                                                            Bağlı
                                                        </button>
                                                        <button className="button-open">
                                                            Açıq
                                                        </button>
                                                    </td> */}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <div style={{ width: '100%', marginTop: '15px', marginBottom: '15px', display: 'flex', justifyContent: 'center' }}>
                                        <ul className="dashboard-pagination">
                                            {this.state.currentPage != 1 && <li><a href="#" onClick={(e) => this.selectPage(e, this.state.currentPage - 1)}>{t('previous-page')}</a></li>}
                                            {
                                                Array.from({ length: this.state.lastPage }).map((item, index) => {
                                                    if (this.state.lastPage > 3) {
                                                        if (this.state.currentPage - 1 == index + 1) {
                                                            return (
                                                                <>
                                                                    {this.state.currentPage > 2 && <li><a href="#" onClick={(e) => this.selectPage(e, 1)}>1</a></li>}

                                                                    {this.state.currentPage > 3 && <li><a href="#" style={{ cursor: 'default' }} onClick={(e) => { e.preventDefault(); }}>...</a></li>}
                                                                    <li><a href="#" onClick={(e) => this.selectPage(e, index + 1)}>{index + 1}</a></li>
                                                                </>
                                                            )
                                                        }
                                                        if (this.state.currentPage == index + 1) {
                                                            return (
                                                                <>
                                                                    <li className="active" ><a href="#" style={{ cursor: 'default' }} onClick={e => e.preventDefault()}>{index + 1}</a></li>
                                                                </>
                                                            )
                                                        }
                                                        if (this.state.currentPage + 1 == index + 1) {
                                                            return (
                                                                <>
                                                                    <li><a href="#" onClick={(e) => this.selectPage(e, index + 1)}>{index + 1}</a></li>
                                                                    {this.state.currentPage < this.state.lastPage - 2 && <li ><a href="#" style={{ cursor: 'default' }} onClick={(e) => { e.preventDefault(); }}>...</a></li>}

                                                                    {this.state.currentPage + 1 < this.state.lastPage && <li ><a href="#" onClick={(e) => this.selectPage(e, this.state.lastPage)}>{this.state.lastPage}</a></li>}
                                                                </>
                                                            )
                                                        }
                                                    } else {
                                                        return (
                                                            <li className={index + 1 == this.state.currentPage ? "active" : ""}><a href="#" style={this.state.lastPage == 1 ? { borderRadius: '5px' } : {}} onClick={(e) => this.selectPage(e, index + 1)}>{index + 1}</a></li>
                                                        )
                                                    }
                                                })

                                            }
                                            {this.state.currentPage != this.state.lastPage && this.state.lastPage > 1 && <li><a href="#" onClick={(e) => this.selectPage(e, this.state.currentPage + 1)}>{t('next-page')}</a></li>}
                                        </ul>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default translate("translation")(History);