import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Order from './Order';
import History from './History';
import Authorization from './Authorization';
import List from './List';
import NotFound from './../../../copms/NotFound/NotFound';
import NewOperation from './NewOperation';
import OperationList from './OperationList';
import FirstAuthorisation from './FirstAuthorisation/FirstAuthorisation';
import SecondAuthorisation from './SecondAuthorisation/SecondAuthorisation';
import OTPFirstAuthorisation from './OTP/FirstAuthorisation/FirstAuthorisation';
import OTPSecondAuthorisation from './OTP/SecondAuthorisation/SecondAuthorisation';

export default class Salary extends Component {

    render() {
        let { updateAppState, updateDashboardState, alertState, signLevel, signCount, logout } = this.props;
        return (
            <Switch>
                {localStorage.getItem('compId') && <>
                    <Redirect exact from="/dashboard/salary-project/" to="/dashboard/salary-project/order" />
                    <Route path="/dashboard/salary-project/order" render={(routeProps) => <Order logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route path="/dashboard/salary-project/edit/order/:id" render={(routeProps) => <Order logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route path="/dashboard/salary-project/history" render={(routeProps) => <History logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route path="/dashboard/salary-project/authorization" render={(routeProps) => <Authorization logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route path="/dashboard/salary-project/list" render={(routeProps) => <List logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                </>
                }
                <Redirect exact from="/dashboard/salary/" to="/dashboard/salary/new-operation" />
                <Route path="/dashboard/salary/new-operation" render={(routeProps) => <NewOperation logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                <Route path="/dashboard/salary/operations" render={(routeProps) => <OperationList logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                <Route path="/dashboard/salary/2nd-authorization" render={(routeProps) => <FirstAuthorisation logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                <Route path="/dashboard/salary/1st-authorization" render={(routeProps) => <SecondAuthorisation logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                {
                    localStorage.getItem('custObject') && localStorage.getItem('compId') && JSON.parse(localStorage.getItem('custObject')).customer.isOtpSign == 1 &&
                    <Route path="/dashboard/salary/otp/2nd-authorization" render={(routeProps) => <OTPFirstAuthorisation logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                }
                {
                    localStorage.getItem('custObject') && localStorage.getItem('compId') && JSON.parse(localStorage.getItem('custObject')).customer.isOtpSign == 1 &&
                    <Route path="/dashboard/salary/otp/1st-authorization" render={(routeProps) => <OTPSecondAuthorisation logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                }
                <Route render={(routeProps) => (<NotFound />)} />
            </Switch>
        )
    }
}
