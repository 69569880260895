import React, { Component, Fragment } from 'react';
import { translate } from 'react-i18next';
import { request } from './../../../../config';
import transferConfirmation from './../../img/transfer-confirmation.svg';
import currencyFilter from './../../../../helpers/currencyFilter';
import UpdateModal from './../../../../copms/Modal/UpdateModal';
import CreateTransferTemplate from './../../../../copms/Modal/CreateTransferTemplate';
import NotFound from '../../../../copms/NotFound/NotFound';
import Loading from './../../Loading/Loading';
import $ from 'jquery';
import OutsideClickHandler from 'react-outside-click-handler';

class AbroadEdit extends Component {
    state = {
        feedbackErrorModal: null,
        createFeedbackMessage: false,
        open: false,
        confirmedOperation: false,
        dropdownVisibilityAccount: false,
        accounts: [],
        currency: "",
        dtAccountId: null,
        selectedAccount: null,
        dtAccontIdValidation: false,
        amount: "",
        crrAmount: "",
        amountValidation: false,
        organizedAmount: {},
        purpose: "",
        purposeValidation: false,
        note: "",
        noteValidation: false,
        crCorrBankName: "",
        crCorrBankNameValidation: false,
        crCorrBankSwift: "",
        crCorrBankSwiftValidation: false,
        crCorrBankCountry: "",
        crCorrBankCountryValidation: false,
        crCorrBankCity: "",
        crCorrBankCityValidation: false,
        crCorrBankAccount: "",
        crCorrBankAccountValidation: false,
        crCorrBankBranch: "",
        crCorrBankBranchValidation: false,
        crCustName: "",
        crCustNameValidation: false,
        crIban: "",
        crIbanValidation: false,
        crCustAddress: "",
        crCustAddressValidation: false,
        crCustPhone: "",
        crCustPhoneValidation: false,
        crBankName: "",
        crBankNameValidation: false,
        crBankSwift: "",
        crBankSwiftValidation: false,
        crBankBranch: "",
        crBankBranchValidation: false,
        crBankCountry: "",
        crBankCountryValidation: false,
        crBankCountryOutline: false,
        crBankCity: "",
        crBankCityValidation: false,
        operationId: 0,
        tempHave: false,
        isLoading: false,
        accountData: false,
        custOperNo: null,
        dtCustName: null,
        dtCustTaxid: null,
        files: [],
        budgetAccounts: [],
        dropdownVisibilityCrIban: false,
        bankSwifts: [],
        dropdownVisibilityCrBankSwift: false,
        crBankCorrAcc: "",
        crBankCorrAccValidation: false,

        amountOutline: false,
        purposeOutline: false,
        crBankNameOutline: false,
        crCustNameOutline: false,
        crCustAddressOutline: false,

        crCustPin: "",
        crCustTaxid: "",

        // ====================

        selectedCountryCount: 0,
        selectedCountryIndex: -1,
        dropdownVisibilityCountry: false,
        countryFilter: '',
        countries: [],

        selectedCityCount: 0,
        selectedCityIndex: -1,
        dropdownVisibilityCity: false,
        cityFilter: '',
        cities: [],

        selectedCorCountryCount: 0,
        selectedCorCountryIndex: -1,
        dropdownVisibilityCorCountry: false,
        corCountryFilter: '',

        selectedCorCityCount: 0,
        selectedCorCityIndex: -1,
        dropdownVisibilityCorCity: false,
        corCityFilter: '',
        corCities: [],

        loadingStatus: 0,
        feeAmount: 0,
        info: ""
    }
    saveFile = (name, type, data) => {
        if (data !== null && navigator.msSaveBlob)
            return navigator.msSaveBlob(new Blob([data], { type: type }), name);
        var a = $("<a style='display: none;'/>");
        var url = window.URL.createObjectURL(new Blob([data], { type: type }));
        a.attr("href", url);
        a.attr("download", name);
        $("body").append(a);
        a[0].click();
        window.URL.revokeObjectURL(url);
        a.remove();
    }
    getBase64 = (file, cb) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result, file)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }
    base64ToArrayBuffer(byteArray, filename) {
        let bstr = atob(byteArray);
        let n = bstr.length;
        let u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename);
    }
    chooseFile = (event) => {
        let files = this.state.files;
        for (let i = 0; i < event.target.files.length; i++) {
            files.push({ files: event.target.files[i], fileName: event.target.files[i].name });
        }
        this.setState({ files: files });
    }
    deleteFile = (index) => {
        let files = this.state.files;
        files.splice(index, 1);
        this.setState({ files: files });
    }
    downloadFile = (index) => {
        let arrrayBuffer = this.state.files[index].files;
        this.saveFile(this.state.files[index].fileName, null, arrrayBuffer);
    }
    amountSplit = (amount) => {
        var splitedAmount = amount.toString().split('.');
        if (splitedAmount.length > 1) {
            return {
                part1: splitedAmount[0],
                part2: splitedAmount[1].length > 1 ? splitedAmount[1] : splitedAmount[1] + '0'
            }
        } else {
            return {
                part1: amount,
                part2: '00'
            }
        }
    }
    updateDtAccountId = (dtAccountId, dtIban, currency, event) => {
        this.setState({ dtAccountId: dtAccountId, dtIban: dtIban, selectedAccount: event.target.textContent, currency: currency, dtAccontIdValidation: true, dropdownVisibilityAccount: false });
    }
    updateDtAccountIdFromTemplate = (iban) => {
        let account = this.state.accounts.find(account => account.iban === iban);
        this.setState({
            dtAccountId: account ? account.accountId : null,
            dtIban: account ? account.iban : null,
            selectedAccount: account ? (account.iban + ' / ' + account.organizedCurrentBalance.wholePart + '.' + account.organizedCurrentBalance.fractionalPart + ' ' + account.currName) : null,
            dtAccontIdValidation: account ? true : false
        }, () => this.showTransferAbroad());
    }
    changeDropdownVisibilityAccount = (event) => {
        event.preventDefault();
        this.setState({ dropdownVisibilityAccount: !this.state.dropdownVisibilityAccount });
    }
    closeDropdownVisibilityAccount = () => {
        this.setState({ dropdownVisibilityAccount: false });
    }
    checkCrBankName = (event) => {
        this.setState({ crBankName: event.target.value });
        if (event.target.value.trim() === "") {
            this.setState({ crBankNameValidation: false });
        }
        else {
            this.setState({ crBankNameValidation: true });
            this.setState({ crBankNameOutline: false });
        }
    }
    checkCrBankSwift = (event) => {
        this.setState({ crBankSwiftValidation: false });
        let mask = /[^a-zA-Z0-9]/g;
        if (event.target.value !== event.target.value.replace(mask, '')) {
            event.target.value = event.target.value.replace(mask, '');
        }
        // if (event.target.value.length > 11) {
        //     event.target.value = event.target.value.substring(0, 11);
        // }
        event.target.value = event.target.value.toUpperCase();
        if (event.target.value.length > 0) {
            this.setState({ crBankSwiftValidation: true });
        }
        this.setState({ crBankSwift: event.target.value });
    }
    checkCrBankBranch = (event) => {
        this.setState({ crBankBranch: event.target.value });
        if (event.target.value.trim() === "") {
            this.setState({ crBankBranchValidation: false });
        }
        else {
            this.setState({ crBankBranchValidation: true })
        }
    }
    checkCrBankCorrAcc = (event) => {
        this.setState({ crBankCorrAccValidation: false });
        let mask = /[^a-zA-Z0-9]/g;
        if (event.target.value !== event.target.value.replace(mask, '')) {
            event.target.value = event.target.value.replace(mask, '');
        }
        event.target.value = event.target.value.toUpperCase();
        if (event.target.value.length > 0) {
            this.setState({ crBankCorrAccValidation: true });
        }
        this.setState({ crBankCorrAcc: event.target.value });
    }
    checkCrBankCountry = (event) => {
        this.setState({ crBankCountry: event.target.value });
        if (event.target.value.trim() === "") {
            this.setState({ crBankCountryValidation: false });
        }
        else {
            this.setState({ crBankCountryValidation: true });
            this.setState({ crBankCountryOutline: false });
        }
    }
    checkCrBankCity = (event) => {
        this.setState({ crBankCity: event.target.value });
        if (event.target.value.trim() === "") {
            this.setState({ crBankCityValidation: false });
        }
        else {
            this.setState({ crBankCityValidation: true })
        }
    }
    checkCrCustName = (event) => {
        if (event.target.value[0] === " ") event.target.value = event.target.value.substring(1);
        let value = event.target.value;
        this.setState({ crCustNameValidation: false });
        if (value.length > 0) {
            this.setState({ crCustNameValidation: true });
            this.setState({ crCustNameOutline: false });
        }
        this.setState({ crCustName: value });
    }
    checkCrCustAddress = (event) => {
        this.setState({ crCustAddress: event.target.value });
        if (event.target.value.trim() === "") {
            this.setState({ crCustAddressValidation: false });
        }
        else {
            this.setState({ crCustAddressValidation: true });
            this.setState({ crCustAddressOutline: false });
        }
    }
    checkCrIban = (event) => {
        this.setState({ crIbanValidation: false });
        let mask = /[^a-zA-Z0-9]/g;
        if (event.target.value !== event.target.value.replace(mask, '')) {
            event.target.value = event.target.value.replace(mask, '');
        }
        // if (event.target.value.length > 28) {
        //     event.target.value = event.target.value.substring(0, 28);
        // }
        event.target.value = event.target.value.toUpperCase();
        if (event.target.value.length > 0) {
            this.setState({ crIbanValidation: true });
        }
        this.setState({ crIban: event.target.value });
    }
    checkCrCustPhone = (event) => {
        let mask = /[^0-9]/g;
        if (event.target.value !== event.target.value.replace(mask, '')) {
            event.target.value = event.target.value.replace(mask, '');
        }
        if (event.target.value.length === 0) {
            this.setState({ crCustPhoneValidation: false });
        }
        else {
            this.setState({ crCustPhoneValidation: true });
        }
        this.setState({ crCustPhone: event.target.value });
    }
    checkAmount = (event) => {
        this.setState({ amountValidation: false });
        let mask = /[^0-9.]/g;
        if (event.target.value !== event.target.value.replace(mask, '')) {
            event.target.value = event.target.value.replace(mask, '');
        }
        if (event.target.value.indexOf('.') !== -1) {
            let amount = event.target.value;
            let firstPointIndex = amount.indexOf('.');
            let wholePartAmount = amount.substr(0, firstPointIndex);
            let fractionalPartAmount = "";
            let afterPoint = 0;
            for (let i = firstPointIndex + 1; i < amount.length; i++) {
                if (amount[i] !== '.') {
                    fractionalPartAmount += amount[i];
                    afterPoint++;
                }
                if (afterPoint === 2) break;
            }
            if (firstPointIndex === 0) {
                wholePartAmount = 0;
            }
            event.target.value = wholePartAmount + '.' + fractionalPartAmount;
            this.setState({ amount: wholePartAmount + '.' + fractionalPartAmount });
            this.setState({ organizedAmount: { whole: parseInt(wholePartAmount), fractional: parseInt(fractionalPartAmount) } });
        }
        else {
            this.setState({ amount: event.target.value });
        }
        // =========BALANCE=========
        let amount = event.target.value;
        if (amount.toString() === "0") {
            this.setState({ organizedAmount: { whole: "0", fractional: "00" } });
        }
        else {
            if (amount.toString().indexOf('.') !== -1) {
                let currentBalanceArray = amount.toString().split('.');
                let fractional = currentBalanceArray[1].length === 1 ? currentBalanceArray[1] + '0' : currentBalanceArray[1];
                this.setState({ organizedAmount: { whole: currentBalanceArray[0], fractional: fractional } });
            }
            else {
                if (event.target.value !== "") {
                    this.setState({ organizedAmount: { whole: amount.toString(), fractional: "00" } });
                }
            }
        }
        if (isNaN(parseFloat(event.target.value)) === true || parseFloat(amount) <= 0) {
            this.setState({ amountValidation: false });
        }
        else {
            this.setState({ amountValidation: true });
            this.setState({ amountOutline: false });
        }
    }
    checkPurpose = (event) => {
        if (event.target.value[0] === " ") event.target.value = event.target.value.substring(1);
        let value = event.target.value.substring(0, 250);
        this.setState({ purpose: value });
        if (value === "") {
            this.setState({ purposeValidation: false });
        }
        else {
            this.setState({ purposeValidation: true });
            this.setState({ purposeOutline: false });
        }
    }
    checkNote = (event) => {
        if (event.target.value[0] === " ") event.target.value = event.target.value.substring(1);
        let value = event.target.value.substring(0, 250);
        this.setState({ note: value });
        if (value === "") {
            this.setState({ noteValidation: false });
        }
        else {
            this.setState({ noteValidation: true })
        }
    }
    checkCrCorrBankName = (event) => {
        this.setState({ crCorrBankName: event.target.value });
        if (event.target.value.trim() === "") {
            this.setState({ crCorrBankNameValidation: false });
        }
        else {
            this.setState({ crCorrBankNameValidation: true })
        }
    }
    checkCrCorrBankCountry = (event) => {
        this.setState({ crCorrBankCountry: event.target.value });
        if (event.target.value.trim() === "") {
            this.setState({ crCorrBankCountryValidation: false });
        }
        else {
            this.setState({ crCorrBankCountryValidation: true })
        }
    }
    checkCrCorrBankCity = (event) => {
        this.setState({ crCorrBankCity: event.target.value });
        if (event.target.value.trim() === "") {
            this.setState({ crCorrBankCityValidation: false });
        }
        else {
            this.setState({ crCorrBankCityValidation: true })
        }
    }
    checkCrCorrBankBranch = (event) => {
        this.setState({ crCorrBankBranch: event.target.value });
        if (event.target.value.trim() === "") {
            this.setState({ crCorrBankBranchValidation: false });
        }
        else {
            this.setState({ crCorrBankBranchValidation: true })
        }
    }
    checkCrCorrBankAccount = (event) => {
        this.setState({ crCorrBankAccountValidation: false });
        let mask = /[^a-zA-Z0-9]/g;
        if (event.target.value !== event.target.value.replace(mask, '')) {
            event.target.value = event.target.value.replace(mask, '');
        }
        // if (event.target.value.length > 28) {
        //     event.target.value = event.target.value.substring(0, 28);
        // }
        event.target.value = event.target.value.toUpperCase();
        if (event.target.value.length > 0) {
            this.setState({ crCorrBankAccountValidation: true });
        }
        this.setState({ crCorrBankAccount: event.target.value });
    }
    checkCrCorrBankSwift = (event) => {
        this.setState({ crCorrBankSwiftValidation: false });
        let mask = /[^a-zA-Z0-9]/g;
        if (event.target.value !== event.target.value.replace(mask, '')) {
            event.target.value = event.target.value.replace(mask, '');
        }
        // if (event.target.value.length > 11) {
        //     event.target.value = event.target.value.substring(0, 11);
        // }
        event.target.value = event.target.value.toUpperCase();
        if (event.target.value.length > 0) {
            this.setState({ crCorrBankSwiftValidation: true });
        }
        this.setState({ crCorrBankSwift: event.target.value });
    }
    // showTransferAbroad = () => {
    //     this.setState({ confirmedOperation: true });
    // }
    showTransferAbroad = () => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let getTransferComissionRequestBody = {
            operationTypeId: 4,
            operationNameId: 3,
            amount: this.state.amount,
            accountId: this.state.dtAccountId,
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
        }
        fetch(request("operation/getCommission", getTransferComissionRequestBody))
            .then(res => res.text())
            .then((getTransferComissionResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getTransferComissionResponse = JSON.parse(getTransferComissionResponseJSON);
                if (getTransferComissionResponse.status.statusCode !== 1) {
                    if (getTransferComissionResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.setState({ createFeedbackMessage: false, open: true, feedbackErrorModal: this.props.t(getTransferComissionResponse.status.statusCode) });
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    this.setState({ feeAmount: getTransferComissionResponse.commission.amount, info: getTransferComissionResponse?.commission?.info, crrAmount: this.state.amount });
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }
    cancelTransferAbroad = () => {
        this.props.history.push('/dashboard/transfer/transfers');
    }
    updateAbroadOperation = () => {
        this.setState({ feedbackErrorModal: null });
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let updateAbroadOperationRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),

            dtAccountId: this.state.dtAccountId,
            crBankName: this.state.crBankName,
            crBankSwift: this.state.crBankSwift,
            crBankBranch: this.state.crBankBranch,
            crBankAddress: this.state.crBankAddress,
            // crBankCountry: this.state.crBankCountry,
            // crBankCity: this.state.crBankCity,
            crCustName: this.state.crCustName && this.state.crCustName.trim(),
            crIban: this.state.crIban,
            crCustAddress: this.state.crCustAddress,
            crCustPhone: this.state.crCustPhone,
            amount: this.state.amount,
            purpose: this.state.purpose && this.state.purpose.trim(),
            crCorrBankName: this.state.crCorrBankName,
            crCorrBankSwift: this.state.crCorrBankSwift,
            // crCorrBankCountry: this.state.crCorrBankCountry,
            // crCorrBankCity: this.state.crCorrBankCity,
            crCorrBankAccount: this.state.crCorrBankAccount,
            crCorrBankBranch: this.state.crCorrBankBranch,
            // ===

            operId: this.props.match.params.id,
            dtIban: this.state.dtIban,
            // ====
            operNameId: this.state.operNameId,
            operStateId: this.state.operStateId,
            operTypeId: this.state.operTypeId,
            custOperNo: this.state.custOperNo,
            crBankCode: this.state.crBankCode,
            crBankTaxid: this.state.crBankTaxid,
            crBankCorrAcc: this.state.crBankCorrAcc,
            dtCustName: this.state.dtCustName,
            dtCustTaxid: this.state.dtCustTaxid,
            budgetCode: null,
            budgetLvl: null,
            note: this.state.note && this.state.note.trim(),
            operationType: null,
            crTaxNo: null,
            crBankAddress: null,
            dtBranchId: null,
            crBranchId: null,
            dtAmount: null,
            crAmount: null,
            exchangeRate: null,
            exchangeOperationType: null,
            crCustTaxid: this.state.crCustTaxid,
            custOperNo: this.state.custOperNo,
            crBranchId: null,
            crBankCode: null,
            crCustPin: this.state.crCustPin,

            crBankCountryId: this.state.countries[this.state.selectedCountryIndex]?.id,
            crBankCityId: this.state.cities[this.state.selectedCityIndex]?.id,
            crCorrBankCountryId: this.state.countries[this.state.selectedCorCountryIndex]?.id,
            crCorrBankCityId: this.state.corCities[this.state.selectedCorCityIndex]?.id
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),

            dtAccountId: this.state.dtAccountId,
            crBankName: this.state.crBankName,
            crBankSwift: this.state.crBankSwift,
            crBankBranch: this.state.crBankBranch,
            crBankAddress: this.state.crBankAddress,
            // crBankCountry: this.state.crBankCountry,
            // crBankCity: this.state.crBankCity,
            crCustName: this.state.crCustName && this.state.crCustName.trim(),
            crIban: this.state.crIban,
            crCustAddress: this.state.crCustAddress,
            crCustPhone: this.state.crCustPhone,
            amount: this.state.amount,
            purpose: this.state.purpose && this.state.purpose.trim(),
            crCorrBankName: this.state.crCorrBankName,
            crCorrBankSwift: this.state.crCorrBankSwift,
            // crCorrBankCountry: this.state.crCorrBankCountry,
            // crCorrBankCity: this.state.crCorrBankCity,
            crCorrBankAccount: this.state.crCorrBankAccount,
            crCorrBankBranch: this.state.crCorrBankBranch,
            // ===

            operId: this.props.match.params.id,
            dtIban: this.state.dtIban,
            // ====
            operNameId: this.state.operNameId,
            operStateId: this.state.operStateId,
            operTypeId: this.state.operTypeId,
            custOperNo: this.state.custOperNo,
            crBankCode: this.state.crBankCode,
            crBankTaxid: this.state.crBankTaxid,
            crBankCorrAcc: this.state.crBankCorrAcc,
            dtCustName: this.state.dtCustName,
            dtCustTaxid: this.state.dtCustTaxid,
            budgetCode: null,
            budgetLvl: null,
            note: this.state.note && this.state.note.trim(),
            operationType: null,
            crTaxNo: null,
            crBankAddress: null,
            dtBranchId: null,
            crBranchId: null,
            dtAmount: null,
            crAmount: null,
            exchangeRate: null,
            exchangeOperationType: null,
            crCustTaxid: this.state.crCustTaxid,
            custOperNo: this.state.custOperNo,
            crBranchId: null,
            crBankCode: null,
            crCustPin: this.state.crCustPin,

            crBankCountryId: this.state.countries[this.state.selectedCountryIndex]?.id,
            crBankCityId: this.state.cities[this.state.selectedCityIndex]?.id,
            crCorrBankCountryId: this.state.countries[this.state.selectedCorCountryIndex]?.id,
            crCorrBankCityId: this.state.corCities[this.state.selectedCorCityIndex]?.id
        }
        if (localStorage.getItem("compId") && localStorage.getItem("isOperNo") === "1") {
            updateAbroadOperationRequestBody.abroadOpNumber = this.state.abroadOpNumber;
        }
        let data = new FormData();
        data.append('reqObject', JSON.stringify(updateAbroadOperationRequestBody));
        this.state.files.map(file => {
            data.append('files', file.files, file.name);
        });
        fetch(request("operation/updateOperationByIdWithForm", data, "POST_FORM"))
            .then(res => res.text())
            .then((updateAbroadOperationResponseJSON) => {
                const JSON = require('true-json-bigint');
                let updateAbroadOperationResponse = JSON.parse(updateAbroadOperationResponseJSON);
                if (updateAbroadOperationResponse.status.statusCode !== 1) {
                    if (updateAbroadOperationResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.setState({ createFeedbackMessage: false, open: true, feedbackErrorModal: this.props.t(updateAbroadOperationResponse.status.statusCode) });
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    this.setState({ createFeedbackMessage: true, open: true, operationId: updateAbroadOperationResponse.operationId });
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }
    updateOpenModal = (value) => {
        this.setState({ open: value });
    }
    getOperationAllInfoById = () => {
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getOperationAllInfoByIdRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            operId: this.props.match.params.id,
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
            custId: localStorage.getItem('custId'),
            operId: this.props.match.params.id,
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }

        fetch(request("operation/getOperationAllInfoById", getOperationAllInfoByIdRequestBody))
            .then(res => res.text())
            .then((getOperationAllInfoByIdResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getOperationAllInfoByIdResponse = JSON.parse(getOperationAllInfoByIdResponseJSON);
                if (getOperationAllInfoByIdResponse.status.statusCode !== 1 && getOperationAllInfoByIdResponse.status.statusCode !== 126) {
                    if (getOperationAllInfoByIdResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        // this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getOperationAllInfoByIdResponse.status.statusCode));
                        this.props.updateAppState('isLoading', false);
                    }
                }
                else {
                    let files = [];
                    getOperationAllInfoByIdResponse.updFile.map(file => {
                        files.push({ files: this.base64ToArrayBuffer(file.files, file.fileName), fileName: file.fileName });
                    });
                    this.setState({
                        crCustPin: getOperationAllInfoByIdResponse.crCustPin,
                        custOperNo: getOperationAllInfoByIdResponse.custOperNo,
                        operStateId: getOperationAllInfoByIdResponse.operStateId,
                        operNameId: getOperationAllInfoByIdResponse.operNameId,
                        dtAccontId: getOperationAllInfoByIdResponse.dtAccontId,
                        amount: getOperationAllInfoByIdResponse.amt,
                        crrAmount: getOperationAllInfoByIdResponse.amt,
                        crBankName: getOperationAllInfoByIdResponse.crBankName,
                        crBankSwift: getOperationAllInfoByIdResponse.crBankSwift,
                        crCustName: getOperationAllInfoByIdResponse.crCustName && getOperationAllInfoByIdResponse.crCustName.trim(),
                        crCustAddress: getOperationAllInfoByIdResponse.crCustAddress,
                        crIban: getOperationAllInfoByIdResponse.crIban,
                        purpose: getOperationAllInfoByIdResponse.operPurpose && getOperationAllInfoByIdResponse.operPurpose.trim().substring(0, 250),
                        currency: getOperationAllInfoByIdResponse.amtCcy,
                        dtCustName: getOperationAllInfoByIdResponse.dtCustName,
                        dtCustTaxid: getOperationAllInfoByIdResponse.dtCustTaxid,
                        amountValidation: true,
                        purposeValidation: true,
                        crCustNameValidation: true,
                        crIbanValidation: true,
                        crCustAddressValidation: true,
                        crBankNameValidation: true,
                        crBankSwiftValidation: true,
                        crBankCorrAcc: getOperationAllInfoByIdResponse.crBankCorrAcc,
                        note: getOperationAllInfoByIdResponse.note && getOperationAllInfoByIdResponse.note.trim().substring(0, 250),
                        crTaxNo: null,
                        crBankBranch: getOperationAllInfoByIdResponse.crBankBranch,
                        crBankCountry: getOperationAllInfoByIdResponse.crBankCountry,
                        crBankCity: getOperationAllInfoByIdResponse.crBankCity,
                        crCustPhone: getOperationAllInfoByIdResponse.crCustPhone,
                        crCorrBankName: getOperationAllInfoByIdResponse.crCorrBankName,
                        crCorrBankSwift: getOperationAllInfoByIdResponse.crCorrBankSwift,
                        crCorrBankCountry: getOperationAllInfoByIdResponse.crCorrBankCountry,
                        crCorrBankCity: getOperationAllInfoByIdResponse.crCorrBankCity,
                        crCorrBankAccount: getOperationAllInfoByIdResponse.crCorrBankAccount,
                        crCorrBankBranch: getOperationAllInfoByIdResponse.crCorrBankBranch,
                        dtBranchId: null,
                        crBranchId: null,
                        crCustTaxid: getOperationAllInfoByIdResponse.crCustTaxid,
                        crBankCountry: getOperationAllInfoByIdResponse.crBankCountry,
                        custOperNo: getOperationAllInfoByIdResponse.custOperNo,
                        tempHave: true,
                        files: files,

                        // ==================
                        selectedCountryIndex: this.state.countries.findIndex(item => (item.id === getOperationAllInfoByIdResponse.crBankCountryId)),
                        countryFilter: this.state.countries.find(item => (item.id === getOperationAllInfoByIdResponse.crBankCountryId))?.name,
                        crBankCountryValidation: true,
                        crBankCountryOutline: false,
                        // ============================
                        selectedCorCountryIndex: this.state.countries.findIndex(item => (item.id === getOperationAllInfoByIdResponse.crCorrBankCountryId)),
                        corCountryFilter: this.state.countries.find(item => (item.id === getOperationAllInfoByIdResponse.crCorrBankCountryId))?.name,

                    }, () => {
                        this.updateDtAccountIdFromTemplate(getOperationAllInfoByIdResponse.dtIban);
                        this.showCities(getOperationAllInfoByIdResponse.crBankCityId);
                        this.showCorCities(getOperationAllInfoByIdResponse.crCorrBankCityId);
                    });
                    this.setState({ isLoading: false });
                }
            });
    }

    updateCrIbanFilterAll = async (event) => {
        event.persist();
        let value = event.target.value;
        this.setState({ crIbanValidation: false });
        let mask = /[^a-zA-Z0-9]/g;
        if (value !== value.replace(mask, '')) {
            value = value.replace(mask, '');
        }
        value = value.toUpperCase();
        this.setState({ crIban: value });

        if (value.length > 1) {
            await this.showCrIbanAll(value);
            this.setState({ dropdownVisibilityCrIban: true });
        }
        else {
            this.setState({ dropdownVisibilityCrIban: false, budgetAccounts: [] });
        }
        if (value.length > 0) {
            this.setState({ crIbanValidation: true });
        }
    }
    showCrIbanAll = async (iban) => {
        // this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getBudgetAccountListByIbanRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            crIban: iban,
            operNameId: 3
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            crIban: iban,
            operNameId: 3
        }


        fetch(request("operation/getCrIbanListByCrIban", getBudgetAccountListByIbanRequestBody))
            .then(res => res.text())
            .then((getBudgetAccountListByIbanResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getBudgetAccountListByIbanResponse = JSON.parse(getBudgetAccountListByIbanResponseJSON);
                if (getBudgetAccountListByIbanResponse.status.statusCode !== 1) {
                    if (getBudgetAccountListByIbanResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.setState({ budgetAccounts: [] });
                        // this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getBudgetAccountListByIbanResponse.status.statusCode));
                        // this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    this.setState({ budgetAccounts: getBudgetAccountListByIbanResponse.operationList });
                    // this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }
    changeDropdownVisibilityCrIban = () => {
        this.setState({ dropdownVisibilityCrIban: !this.state.dropdownVisibilityCrIban });
    }
    openDropdownVisibilityCrIban = (event) => {
        event.preventDefault();
        this.setState({ dropdownVisibilityCrIban: true });
    }
    closeDropdownVisibilityCrIban = () => {
        this.setState({ dropdownVisibilityCrIban: false });
    }
    updateSelectedCrIbanAll = (budgetAccount) => {
        this.setState({
            crIban: budgetAccount.crIban,
            crIbanValidation: true,
            dropdownVisibilityCrIban: false
        }, () => {
            this.showBudgetAccountAll();
        });
    }
    showBudgetAccountAll = () => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getCustInfoByCrIbanRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            crIban: this.state.crIban,
            operNameId: 3
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            crIban: this.state.crIban,
            operNameId: 3
        }
        fetch(request("operation/getCustInfoByCrIban", getCustInfoByCrIbanRequestBody))
            .then(res => res.text())
            .then((getCustInfoByCrIbanResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getCustInfoByCrIbanResponse = JSON.parse(getCustInfoByCrIbanResponseJSON);
                if (getCustInfoByCrIbanResponse.status.statusCode !== 1) {
                    if (getCustInfoByCrIbanResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                        // this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getCustInfoByCrIbanResponse.status.statusCode));
                    }
                }
                else {
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                    if (getCustInfoByCrIbanResponse.crBankCity) {
                        this.setState({
                            crBankCity: getCustInfoByCrIbanResponse.crBankCity,
                            crBankCityValidation: true
                        });
                    }
                    if (getCustInfoByCrIbanResponse.crBankCountryId !== null) {
                        this.setState({
                            selectedCountryIndex: this.state.countries.findIndex(item => (item.id === getCustInfoByCrIbanResponse.crBankCountryId)),
                            countryFilter: this.state.countries.find(item => (item.id === getCustInfoByCrIbanResponse.crBankCountryId)).name,
                            crBankCountryValidation: true,
                            crBankCountryOutline: false
                        }, () => {
                            this.showCities(getCustInfoByCrIbanResponse.crBankCityId);
                        });
                    }
                    if (getCustInfoByCrIbanResponse.crBankName) {
                        this.setState({
                            crBankName: getCustInfoByCrIbanResponse.crBankName,
                            crBankNameValidation: true,
                            crBankNameOutline: false
                        });
                    }
                    if (getCustInfoByCrIbanResponse.crBankSwift) {
                        this.setState({
                            crBankSwift: getCustInfoByCrIbanResponse.crBankSwift,
                            crBankSwiftValidation: true
                        });
                    }
                    if (getCustInfoByCrIbanResponse.crCustAddress) {
                        this.setState({
                            crCustAddress: getCustInfoByCrIbanResponse.crCustAddress,
                            crCustAddressValidation: true,
                            crCustAddressOutline: false

                        });
                    }
                    if (getCustInfoByCrIbanResponse.crCustPhone) {
                        this.setState({
                            crCustPhone: getCustInfoByCrIbanResponse.crCustPhone,
                            crCustPhoneValidation: true
                        });
                    }
                    if (getCustInfoByCrIbanResponse.crBankBranch) {
                        this.setState({
                            crBankBranch: getCustInfoByCrIbanResponse.crBankBranch,
                            crBankBranchValidation: true
                        });
                    }
                    if (getCustInfoByCrIbanResponse.custName) {
                        this.setState({
                            crCustName: getCustInfoByCrIbanResponse.custName,
                            crCustNameValidation: true,
                            crCustNameOutline: false
                        });
                    }
                    if (getCustInfoByCrIbanResponse.crBankCorrAcc) {
                        this.setState({
                            crBankCorrAcc: getCustInfoByCrIbanResponse.crBankCorrAcc,
                            crBankCorrAccValidation: true
                        });
                    }
                    if (getCustInfoByCrIbanResponse.crCustPin) {
                        this.setState({
                            crCustPin: getCustInfoByCrIbanResponse.crCustPin,
                        });
                    }
                    if (getCustInfoByCrIbanResponse.crCustTaxid) {
                        this.setState({
                            crCustTaxid: getCustInfoByCrIbanResponse.crCustTaxid,
                        });
                    }
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }
    updateCrBankSwiftFilterAll = async (event) => {
        event.persist();
        let value = event.target.value;
        this.setState({ crBankSwiftValidation: false });
        let mask = /[^a-zA-Z0-9]/g;
        if (value !== value.replace(mask, '')) {
            value = value.replace(mask, '');
        }
        value = value.toUpperCase();
        this.setState({ crBankSwift: value });

        if (value.length > 1) {
            await this.showCrBankSwiftAll(value);
            this.setState({ dropdownVisibilityCrBankSwift: true });
        }
        else {
            this.setState({ dropdownVisibilityCrBankSwift: false, budgetAccounts: [] });
        }
        if (value.length > 0) {
            this.setState({ crBankSwiftValidation: true });
        }
    }
    showCrBankSwiftAll = async (crBankSwift) => {
        // this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getBudgetAccountListByIbanRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            crBankSwift: crBankSwift,
            operNameId: 3
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            crBankSwift: crBankSwift,
            operNameId: 3
        }

        fetch(request("operation/getCrBankSwiftListByCrBankSwift", getBudgetAccountListByIbanRequestBody))
            .then(res => res.text())
            .then((getBudgetAccountListByIbanResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getBudgetAccountListByIbanResponse = JSON.parse(getBudgetAccountListByIbanResponseJSON);
                if (getBudgetAccountListByIbanResponse.status.statusCode !== 1) {
                    if (getBudgetAccountListByIbanResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.setState({ bankSwifts: [] });
                        // this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getBudgetAccountListByIbanResponse.status.statusCode));
                        // this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    this.setState({ bankSwifts: getBudgetAccountListByIbanResponse.operationList });
                    // this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }
    changeDropdownVisibilityCrBankSwift = () => {
        this.setState({ dropdownVisibilityCrBankSwift: !this.state.dropdownVisibilityCrBankSwift });
    }
    openDropdownVisibilityCrBankSwift = (event) => {
        event.preventDefault();
        this.setState({ dropdownVisibilityCrBankSwift: true });
    }
    closeDropdownVisibilityCrBankSwift = () => {
        this.setState({ dropdownVisibilityCrBankSwift: false });
    }
    updateSelectedCrBankSwiftAll = (bankSwift) => {
        this.setState({
            crBankSwift: bankSwift.crBankSwift,
            crBankSwiftValidation: true,
            crBankNameOutline: false,
            dropdownVisibilityCrBankSwift: false
        }, () => {
            this.showBudgetBankAll();
        });
    }
    showBudgetBankAll = () => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getCustInfoByCrIbanRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            crBankSwift: this.state.crBankSwift,
            operNameId: 3
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            crBankSwift: this.state.crBankSwift,
            operNameId: 3
        }
        fetch(request("operation/getCustInfoByCrIban", getCustInfoByCrIbanRequestBody))
            .then(res => res.text())
            .then((getCustInfoByCrIbanResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getCustInfoByCrIbanResponse = JSON.parse(getCustInfoByCrIbanResponseJSON);
                if (getCustInfoByCrIbanResponse.status.statusCode !== 1) {
                    if (getCustInfoByCrIbanResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getCustInfoByCrIbanResponse.status.statusCode));
                    }
                }
                else {
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                    if (getCustInfoByCrIbanResponse.crBankCity) {
                        this.setState({
                            crBankCity: getCustInfoByCrIbanResponse.crBankCity,
                            crBankCityValidation: true
                        });
                    }
                    if (getCustInfoByCrIbanResponse.crBankCountryId !== null) {
                        this.setState({
                            selectedCountryIndex: this.state.countries.findIndex(item => (item.id === getCustInfoByCrIbanResponse.crBankCountryId)),
                            countryFilter: this.state.countries.find(item => (item.id === getCustInfoByCrIbanResponse.crBankCountryId)).name,
                            crBankCountryValidation: true,
                            crBankCountryOutline: false
                        }, () => {
                            this.showCities(getCustInfoByCrIbanResponse.crBankCityId);
                        });
                    }
                    if (getCustInfoByCrIbanResponse.crBankName) {
                        this.setState({
                            crBankName: getCustInfoByCrIbanResponse.crBankName,
                            crBankNameValidation: true
                        });
                    }
                    if (getCustInfoByCrIbanResponse.crBankBranch) {
                        this.setState({
                            crBankBranch: getCustInfoByCrIbanResponse.crBankBranch,
                            crBankBranchValidation: true
                        });
                    }
                    if (getCustInfoByCrIbanResponse.crBankCorrAcc) {
                        this.setState({
                            crBankCorrAcc: getCustInfoByCrIbanResponse.crBankCorrAcc,
                            crBankCorrAccValidation: true
                        });
                    }
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }
    setAmountOutline = () => {
        if (!this.state.amountValidation) {
            this.setState({ amountOutline: true });
        }
        else {
            this.setState({ amountOutline: false });
        }
    }
    setPurposeOutline = () => {
        if (!this.state.purposeValidation) {
            this.setState({ purposeOutline: true });
        }
        else {
            this.setState({ purposeOutline: false });
        }
    }
    setCrBankNameOutline = () => {
        if (!this.state.crBankNameValidation) {
            this.setState({ crBankNameOutline: true });
        }
        else {
            this.setState({ crBankNameOutline: false });
        }
    }
    setCrBankCountryOutline = () => {
        if (!this.state.crBankCountryValidation) {
            this.setState({ crBankCountryOutline: true });
        }
        else {
            this.setState({ crBankCountryOutline: false });
        }
    }
    setCrCustNameOutline = () => {
        if (!this.state.crCustNameValidation) {
            this.setState({ crCustNameOutline: true });
        }
        else {
            this.setState({ crCustNameOutline: false });
        }
    }
    setCrCustAddressOutline = () => {
        if (!this.state.crCustAddressValidation) {
            this.setState({ crCustAddressOutline: true });
        }
        else {
            this.setState({ crCustAddressOutline: false });
        }
    }


    // ============================
    showAccounts = () => {
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getNoCardAccountListForCustomerRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            isBalanceAccount: 0
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            isBalanceAccount: 0
        }

        fetch(request("account/getNoCardAccountListFor" + customerType + "Customer", getNoCardAccountListForCustomerRequestBody))
            .then(res => res.text())
            .then((getNoCardAccountListForCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getNoCardAccountListForCustomerResponse = JSON.parse(getNoCardAccountListForCustomerResponseJSON);
                if (getNoCardAccountListForCustomerResponse.status.statusCode !== 1 && getNoCardAccountListForCustomerResponse.status.statusCode !== 126) {
                    if (getNoCardAccountListForCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getNoCardAccountListForCustomerResponse.status.statusCode));
                        this.setState({ isLoading: false });
                    }
                }
                else {
                    if (getNoCardAccountListForCustomerResponse.status.statusCode === 126) {
                        getNoCardAccountListForCustomerResponse.accountList = [];
                    }
                    for (let i = 0; i < getNoCardAccountListForCustomerResponse.accountList.length; i++) {
                        // // =========ID=========
                        let accountId = "";
                        if (typeof (getNoCardAccountListForCustomerResponse.accountList[i].accountId) === 'object' && getNoCardAccountListForCustomerResponse.account[i].accountId.hasOwnProperty('c')) {
                            getNoCardAccountListForCustomerResponse.account[i].id.c.forEach(part => {
                                accountId += part;
                            });
                        }
                        else {
                            accountId += getNoCardAccountListForCustomerResponse.accountList[i].accountId;
                        }
                        getNoCardAccountListForCustomerResponse.accountList[i].accountId = accountId;
                        // =========BALANCE=========
                        if (getNoCardAccountListForCustomerResponse.accountList[i].currentBalance.toString() === "0") {
                            getNoCardAccountListForCustomerResponse.accountList[i].organizedCurrentBalance = {
                                wholePart: "0",
                                fractionalPart: "00"
                            }
                        }
                        else {
                            if (getNoCardAccountListForCustomerResponse.accountList[i].currentBalance.toString().indexOf('.') !== -1) {
                                let currentBalanceArray = getNoCardAccountListForCustomerResponse.accountList[i].currentBalance.toString().split('.');
                                getNoCardAccountListForCustomerResponse.accountList[i].organizedCurrentBalance = {
                                    wholePart: currentBalanceArray[0],
                                    fractionalPart: (currentBalanceArray[1].length === 1) ? (currentBalanceArray[1] + '0') : currentBalanceArray[1]
                                }
                            }
                            else {
                                let currentBalanceArray = getNoCardAccountListForCustomerResponse.accountList[i].currentBalance.toString().split('.');
                                getNoCardAccountListForCustomerResponse.accountList[i].organizedCurrentBalance = {
                                    wholePart: getNoCardAccountListForCustomerResponse.accountList[i].currentBalance.toString(),
                                    fractionalPart: "00"
                                }
                            }
                        }
                    }
                    this.setState({ accounts: getNoCardAccountListForCustomerResponse.accountList, accountData: true });
                    this.setState({ loadingStatus: this.state.loadingStatus + 1 });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    showCountries = () => {
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getCountryListRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }
        return fetch(request("util/getCountryList", getCountryListRequestBody))
            .then(res => res.text())
            .then((getCountryListResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getCountryListResponse = JSON.parse(getCountryListResponseJSON);
                if (getCountryListResponse.status.statusCode !== 1) {
                    if (getCountryListResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getCountryListResponse.status.statusCode));
                    }
                }
                else {
                    if (getCountryListResponse.countryList && getCountryListResponse.countryList.length > 0) {
                        this.setState({
                            countries: getCountryListResponse.countryList
                        });
                    }
                    else {
                        this.setState({
                            countries: []
                        });
                    }
                    this.setState({ loadingStatus: this.state.loadingStatus + 1 });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    showCities = (cityId) => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getCityListRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            id: this.state.countries[this.state.selectedCountryIndex]?.id
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            id: this.state.countries[this.state.selectedCountryIndex]?.id
        }
        fetch(request("util/getCityList", getCityListRequestBody))
            .then(res => res.text())
            .then((getCityListResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getCityListResponse = JSON.parse(getCityListResponseJSON);
                if (getCityListResponse.status.statusCode !== 1) {
                    if (getCityListResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                        // this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getCityListResponse.status.statusCode));
                    }
                }
                else {
                    if (getCityListResponse.cityList && getCityListResponse.cityList.length > 0) {
                        this.setState({
                            cities: getCityListResponse.cityList
                        }, () => {
                            if (cityId) {
                                this.setState({
                                    selectedCityIndex: this.state.cities.findIndex(item => (item.id === cityId)),
                                    cityFilter: this.state.cities.find(item => (item.id === cityId)).name
                                })
                            }
                        });
                    }
                    else {
                        this.setState({
                            cities: []
                        });
                    }
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    showCorCities = (corCityId) => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getCityListRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            id: this.state.countries[this.state.selectedCorCountryIndex]?.id
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            id: this.state.countries[this.state.selectedCorCountryIndex]?.id
        }
        fetch(request("util/getCityList", getCityListRequestBody))
            .then(res => res.text())
            .then((getCityListResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getCityListResponse = JSON.parse(getCityListResponseJSON);
                if (getCityListResponse.status.statusCode !== 1) {
                    if (getCityListResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                        // this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getCityListResponse.status.statusCode));
                    }
                }
                else {

                    if (getCityListResponse.cityList && getCityListResponse.cityList.length > 0) {
                        this.setState({
                            corCities: getCityListResponse.cityList
                        }, () => {
                            if (corCityId) {
                                this.setState({
                                    selectedCorCityIndex: this.state.corCities.findIndex(item => (item.id === corCityId)),
                                    corCityFilter: this.state.corCities.find(item => (item.id === corCityId)).name
                                })
                            }
                        });
                    }
                    else {
                        this.setState({
                            corCities: []
                        });
                    }
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    updateCountry = (index, event) => {
        event.preventDefault();
        this.setState({
            cityFilter: '',
            countryFilter: this.state.countries[index].name,
            selectedCountryIndex: index,
            cities: [],
            selectedCityIndex: -1,
            selectedCityCount: 0,
            dropdownVisibilityCountry: false,
            crBankCountryValidation: true
        }, () => {
            this.showCities();
        })
    }

    updateCity = (index, event) => {
        event.preventDefault();
        this.setState({
            cityFilter: this.state.cities[index].name,
            selectedCityIndex: index,
            selectedCurrencyIndex: -1,
            selectedCurrencyCount: 0,
            dropdownVisibilityCity: false
        });
    }

    updateCountryFilter = (e) => {
        this.setState({
            countryFilter: e.target.value,
            selectedCountryIndex: -1,
            cities: [],
            selectedCityIndex: -1,
            selectedCityCount: 0,
            cityFilter: ''
        })
    }

    updateCityFilter = (e) => {
        this.setState({
            cityFilter: e.target.value,
            selectedCityIndex: -1,
        })
    }

    updateCountryCount = () => {
        this.setState({ dropdownVisibilityCountry: true });
        if (this.state.selectedCountryIndex < 0) {
            this.setState({ selectedCountryCount: ++this.state.selectedCountryCount });
        }
    }

    updateCityCount = () => {
        this.setState({ dropdownVisibilityCity: true });
        if (this.state.selectedCityIndex < 0) {
            this.setState({ selectedCityCount: ++this.state.selectedCityCount });
        }
    }

    // ===================================

    updateCorCountry = (index, event) => {
        event.preventDefault();
        this.setState({
            corCityFilter: '',
            corCountryFilter: this.state.countries[index].name,
            selectedCorCountryIndex: index,
            corCities: [],
            selectedCorCityIndex: -1,
            selectedCorCityCount: 0,
            dropdownVisibilityCorCountry: false
        }, () => {
            this.showCorCities();
        })
    }


    updateCorCity = (index, event) => {
        event.preventDefault();
        this.setState({
            corCityFilter: this.state.corCities[index].name,
            selectedCorCityIndex: index,
            selectedCorCurrencyIndex: -1,
            selectedCorCurrencyCount: 0,
            dropdownVisibilityCorCity: false
        });
    }

    updateCorCountryFilter = (e) => {
        this.setState({
            corCountryFilter: e.target.value,
            selectedCorCountryIndex: -1,
            corCities: [],
            selectedCorCityIndex: -1,
            selectedCorCityCount: 0,
            corCityFilter: ''
        })
    }

    updateCorCityFilter = (e) => {
        this.setState({
            corCityFilter: e.target.value,
            selectedCorCityIndex: -1,
        })
    }

    updateCorCountryCount = () => {
        this.setState({ dropdownVisibilityCorCountry: true });
        if (this.state.selectedCorCountryIndex < 0) {
            this.setState({ selectedCorCountryCount: ++this.state.selectedCorCountryCount });
        }
    }

    updateCorCityCount = () => {
        this.setState({ dropdownVisibilityCorCity: true });
        if (this.state.selectedCorCityIndex < 0) {
            this.setState({ selectedCorCityCount: ++this.state.selectedCorCityCount });
        }
    }

    componentDidMount() {
        this.setState({ isLoading: true });
        this.showAccounts();
        this.showCountries();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.loadingStatus !== this.state.loadingStatus) {
            if (this.state.loadingStatus == 2) {
                this.getOperationAllInfoById();
            }
        }
    }

    render() {
        let { t, history } = this.props
        let { open, createFeedbackMessage, feedbackErrorModal,
            crBankName, crBankSwift, crCustName, crCustAddress,
            confirmedOperation, accounts, selectedAccount, tempHave, dropdownVisibilityAccount, currency, organizedAmount, crIban, dtIban, amount, purpose, note,
            dtAccontIdValidation, amountValidation, purposeValidation,
            crBankNameValidation, crBankSwiftValidation, custOperNo,
            crCustNameValidation, crIbanValidation, crCustAddressValidation, isLoading, accountData, abroadOpNumber,
            crCorrBankName, crCorrBankCountry, crCorrBankCity, crCorrBankBranch, crCorrBankSwift, crBankCity, crCustPhone, crBankCountry, crBankBranch, crCorrBankAccount,
            budgetAccounts, dropdownVisibilityCrIban, bankSwifts, dropdownVisibilityCrBankSwift, crBankCorrAcc,
            amountOutline, purposeOutline, crBankNameOutline, crCustNameOutline, crCustAddressOutline, crBankCountryValidation, crBankCountryOutline,
            selectedCountryCount, selectedCountryIndex, dropdownVisibilityCountry, countryFilter, countries,
            selectedCityCount, dropdownVisibilityCity, selectedCityIndex, cityFilter, cities,
            selectedCorCountryCount, selectedCorCountryIndex, dropdownVisibilityCorCountry, corCountryFilter, selectedCorCityIndex, selectedCorCityCount, dropdownVisibilityCorCity, corCityFilter, corCities, feeAmount, info, crrAmount
        } = this.state;
        return (
            <Fragment>
                <UpdateModal open={open} createTemplate={() => this.setState({ confirmedOperation: false, openTemp: true })} successClose={() => { history.push('/dashboard/transfer/transfers/first-authorization') }} errorClose={() => this.setState({ confirmedOperation: false })} updateOpenModal={this.updateOpenModal} createFeedbackMessage={createFeedbackMessage} feedbackErrorModal={feedbackErrorModal} history={history} />
                <Fragment>
                    <div className="dashboard-header-route">
                        <div className="dashboard-header-route-left">
                            <button onClick={() => this.props.history.goBack()}>
                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.7344 21.2188L12.6719 20.3281C12.9062 20.0938 12.9062 19.7188 12.6719 19.5312L5.40625 12.2188H21.4375C21.7188 12.2188 22 11.9844 22 11.6562V10.3438C22 10.0625 21.7188 9.78125 21.4375 9.78125H5.40625L12.6719 2.51562C12.9062 2.32812 12.9062 1.95312 12.6719 1.71875L11.7344 0.828125C11.5469 0.59375 11.1719 0.59375 10.9375 0.828125L1.14062 10.625C0.90625 10.8594 0.90625 11.1875 1.14062 11.4219L10.9375 21.2188C11.1719 21.4531 11.5469 21.4531 11.7344 21.2188Z" fill="#2E3131" />
                                </svg>
                            </button>
                            <h2>{t('Abroad')}</h2>
                        </div>
                    </div>
                    {
                        isLoading ?
                            <div className="dashboard-container-main-section">
                                <Loading />
                            </div>
                            :
                            !tempHave ?
                                <NotFound />
                                :
                                <div className="dashboard-payment-container">
                                    <div className="dashboard-payment-container-data-section">
                                        <div className="dashboard-payment-container-data-section-header">
                                            <h3 className="dashboard-payment-container-data-section-header-title">{t('Make a transfer')}</h3>
                                        </div>
                                        <form className="dashboard-payment-container-data-section-content">
                                            <div className="dashboard-payment-container-data-section-content-container">
                                                <h3 className="dashboard-payment-container-data-section-content-container-title">{t('Receiving bank')}</h3>
                                                <div className="dashboard-payment-container-data-section-content-container-form dashboard-payment-container-data-section-content-container-form__border-bottom">
                                                    <div className="dashboard-payment-container-data-section-content-container-form-container">
                                                        {
                                                            localStorage.getItem("isOperNo") === "1" &&
                                                            <div style={{ width: '100%' }}>
                                                                <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                                    <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Transfer number')} <span style={{ color: 'red' }}>*</span></label>
                                                                    <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                                        <input type="text" disabled={true} className="dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole" value={custOperNo} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Name of the beneficiary bank')} <span style={{ color: 'red' }}>*</span></label>
                                                            <div className={"dashboard-payment-container-data-section-content-container-form-input-box " + (crBankNameOutline ? "input-outline" : "")}>
                                                                <input value={crBankName} type="text" className="dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole" disabled={confirmedOperation} onChange={this.checkCrBankName} onBlur={this.setCrBankNameOutline} />
                                                            </div>
                                                        </div>
                                                        {/* ========================== */}
                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Select a country')} <span style={{ color: 'red' }}>*</span></label>
                                                            <OutsideClickHandler onOutsideClick={() => { this.setState({ dropdownVisibilityCountry: false }) }}>
                                                                <div className={"dashboard-payment-container-data-section-content-container-form-select-box " +
                                                                    (selectedCountryCount > 1 &&
                                                                        selectedCountryIndex < 0
                                                                        || selectedCountryCount === 1 && selectedCountryIndex < 0
                                                                        && !dropdownVisibilityCountry ? "input-outline" : "")}>
                                                                    <button type='button' className={confirmedOperation ? "dashboard-payment-container-data-section-content-container-form-select-box-button dashboard-payment-container-data-section-content-container-form-select-box-button__disable" : "dashboard-payment-container-data-section-content-container-form-select-box-button"} disabled={Boolean(confirmedOperation)}>
                                                                        <input type="text" className="dashboard-payment-container-data-section-content-container-form-input" disabled={confirmedOperation} value={countryFilter}
                                                                            placeholder={t("Choose")}
                                                                            onChange={this.updateCountryFilter}
                                                                            onFocus={this.updateCountryCount} />
                                                                        <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                                            <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                                            </svg>
                                                                        </div>
                                                                    </button>
                                                                    <ul className={(dropdownVisibilityCountry === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                                        {
                                                                            countries && countries.length > 0 &&
                                                                            countries.map((country, index) => {
                                                                                if (country?.name?.toLowerCase().includes(countryFilter?.toLowerCase())) {
                                                                                    return (
                                                                                        <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={index}>
                                                                                            <button onClick={this.updateCountry.bind(this, index)} className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button">{country.name}</button>
                                                                                        </li>
                                                                                    )
                                                                                }
                                                                            })
                                                                        }

                                                                    </ul>
                                                                </div>
                                                            </OutsideClickHandler>
                                                        </div>
                                                        {/* <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Its country')}  <span style={{ color: 'red' }}>*</span></label>
                                                            <div className={"dashboard-payment-container-data-section-content-container-form-input-box " + (crBankCountryOutline ? "input-outline" : "")}>
                                                                <input type="text" value={crBankCountry} className="dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole" disabled={confirmedOperation} onChange={this.checkCrBankCountry} onBlur={this.setCrBankCountryOutline} />
                                                            </div>
                                                        </div> */}
                                                        {/* <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Its city')}</label>
                                                            <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                                <input type="text" value={crBankCity} className="dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole" disabled={confirmedOperation} onChange={this.checkCrBankCity} />
                                                            </div>
                                                        </div> */}
                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Select a city')}</label>
                                                            <OutsideClickHandler onOutsideClick={() => { this.setState({ dropdownVisibilityCity: false }) }}>
                                                                <div className={"dashboard-payment-container-data-section-content-container-form-select-box " +
                                                                    (selectedCityCount > 1 &&
                                                                        selectedCityIndex < 0
                                                                        || selectedCityCount === 1 && selectedCityIndex < 0
                                                                        && !dropdownVisibilityCity ? "input-outline" : "")}>
                                                                    <button type='button' className={(confirmedOperation || selectedCountryIndex < 0) ? "dashboard-payment-container-data-section-content-container-form-select-box-button dashboard-payment-container-data-section-content-container-form-select-box-button__disable" : "dashboard-payment-container-data-section-content-container-form-select-box-button"} disabled={Boolean(confirmedOperation || selectedCountryIndex < 0)}>
                                                                        {/* {
                                                            cities && cities.length > 0 && selectedCityIndex >= 0 ?
                                                                <div className="dashboard-payment-container-data-section-content-container-form-select">{cities[selectedCityIndex].cityName}</div>
                                                                :
                                                                <div className="dashboard-payment-container-data-section-content-container-form-select" style={{ color: '#BDC3C7' }}>{t("Choose")}</div>
                                                        } */}
                                                                        <input type="text" className="dashboard-payment-container-data-section-content-container-form-input" disabled={confirmedOperation || selectedCountryIndex < 0} value={cityFilter}
                                                                            placeholder={t("Choose")}
                                                                            onChange={this.updateCityFilter}
                                                                            onFocus={this.updateCityCount} />
                                                                        <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                                            <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                                            </svg>
                                                                        </div>
                                                                    </button>
                                                                    <ul className={(dropdownVisibilityCity === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                                        {
                                                                            cities && cities.length > 0 &&
                                                                            cities.map((city, index) => {
                                                                                // return (
                                                                                //     <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={index}>
                                                                                //         <button onClick={this.updateCity.bind(this, index)} className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button">{city.cityName}</button>
                                                                                //     </li>
                                                                                // );
                                                                                if (city?.name?.toLowerCase().includes(cityFilter?.toLowerCase())) {
                                                                                    return (
                                                                                        <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={index}>
                                                                                            <button onClick={this.updateCity.bind(this, index)} className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button">{city.name}</button>
                                                                                        </li>
                                                                                    )
                                                                                }
                                                                            })
                                                                        }

                                                                    </ul>
                                                                </div>
                                                            </OutsideClickHandler>
                                                        </div>
                                                        {/* ======================= */}
                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Its department')}</label>
                                                            <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                                <input value={crBankBranch} type="text" className="dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole" disabled={confirmedOperation} onChange={this.checkCrBankBranch} />
                                                            </div>
                                                        </div>
                                                        {/* <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('SWIFT')} <span style={{ color: 'red' }}>*</span></label>
                                                            <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                                <input value={crBankSwift} type="text" className="dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole" disabled={confirmedOperation} onChange={this.checkCrBankSwift} />
                                                            </div>
                                                        </div> */}
                                                        {/* =========== */}
                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('SWIFT')} <span style={{ color: 'red' }}>*</span></label>
                                                            <OutsideClickHandler onOutsideClick={this.closeDropdownVisibilityCrBankSwift}>
                                                                <div className={"dashboard-payment-container-data-section-content-container-form-select-box " + (!crBankSwiftValidation ? "input-outline" : "")}>
                                                                    <button type="button" className={confirmedOperation ? "dashboard-payment-container-data-section-content-container-form-select-box-button dashboard-payment-container-data-section-content-container-form-select-box-button__disable" : "dashboard-payment-container-data-section-content-container-form-select-box-button"} disabled={confirmedOperation}>
                                                                        <input type="text" className="dashboard-payment-container-data-section-content-container-form-input" disabled={confirmedOperation} value={crBankSwift} onChange={this.updateCrBankSwiftFilterAll} onFocus={this.openDropdownVisibilityCrBankSwift} />
                                                                        {/* <div className="dashboard-payment-container-data-section-content-container-form-icon" onClick={this.changeDropdownVisibilityCrBankSwift}>
                                                                        <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                                        </svg>
                                                                    </div> */}
                                                                    </button>
                                                                    {
                                                                        bankSwifts && bankSwifts.length !== 0 &&
                                                                        <ul className={(dropdownVisibilityCrBankSwift === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                                            {
                                                                                bankSwifts.map((bankSwift, index) => (
                                                                                    <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={index}>
                                                                                        <button type="button" className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button"
                                                                                            onClick={this.updateSelectedCrBankSwiftAll.bind(this, bankSwift)}
                                                                                        >{bankSwift.crBankSwift}</button>
                                                                                    </li>
                                                                                ))
                                                                            }
                                                                        </ul>
                                                                    }
                                                                </div>
                                                            </OutsideClickHandler>
                                                        </div>
                                                        {/* =========== */}
                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Correspondent account')}</label>
                                                            <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                                <input type="text" value={crBankCorrAcc} className="dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole" disabled={confirmedOperation} onChange={this.checkCrBankCorrAcc} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="dashboard-payment-container-data-section-content-container">
                                                <h3 className="dashboard-payment-container-data-section-content-container-title">{t('The person receiving the funds')}</h3>
                                                <div className="dashboard-payment-container-data-section-content-container-form dashboard-payment-container-data-section-content-container-form__border-bottom">
                                                    <div className="dashboard-payment-container-data-section-content-container-form-container">
                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Benefisiar')} <span style={{ color: 'red' }}>*</span></label>
                                                            <div className={"dashboard-payment-container-data-section-content-container-form-input-box " + (crCustNameOutline ? "input-outline" : "")}>
                                                                <input value={crCustName} type="text" className="dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole" disabled={confirmedOperation} onChange={this.checkCrCustName} onBlur={this.setCrCustNameOutline} />
                                                            </div>
                                                        </div>
                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Address')} <span style={{ color: 'red' }}>*</span></label>
                                                            <div className={"dashboard-payment-container-data-section-content-container-form-input-box " + (crCustAddressOutline ? "input-outline" : "")}>
                                                                <input value={crCustAddress} type="text" className="dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole" disabled={confirmedOperation} onChange={this.checkCrCustAddress} onBlur={this.setCrCustAddressOutline} />
                                                            </div>
                                                        </div>
                                                        {/* <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Its account')} <span style={{ color: 'red' }}>*</span></label>
                                                            <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                                <input value={crIban} type="text" className="dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole" disabled={confirmedOperation} onChange={this.checkCrIban} />
                                                            </div>
                                                        </div> */}
                                                        {/* ====================find iban==================== */}
                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Recipients account (IBAN)')} <span style={{ color: 'red' }}>*</span></label>
                                                            <OutsideClickHandler onOutsideClick={this.closeDropdownVisibilityCrIban}>
                                                                <div className={"dashboard-payment-container-data-section-content-container-form-select-box " + (!crIbanValidation ? "input-outline" : "")}>
                                                                    <button type="button" className={confirmedOperation ? "dashboard-payment-container-data-section-content-container-form-select-box-button dashboard-payment-container-data-section-content-container-form-select-box-button__disable" : "dashboard-payment-container-data-section-content-container-form-select-box-button"} disabled={confirmedOperation}>
                                                                        <input type="text" className="dashboard-payment-container-data-section-content-container-form-input" disabled={confirmedOperation} value={crIban} onChange={this.updateCrIbanFilterAll} onFocus={this.openDropdownVisibilityCrIban} />
                                                                        {/* <div className="dashboard-payment-container-data-section-content-container-form-icon" onClick={this.changeDropdownVisibilityCrIban}>
                                                                        <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                                        </svg>
                                                                    </div> */}
                                                                    </button>
                                                                    {
                                                                        budgetAccounts && budgetAccounts.length !== 0 &&
                                                                        <ul className={(dropdownVisibilityCrIban === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                                            {
                                                                                budgetAccounts.map((budgetAccount, index) => (
                                                                                    <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={index}>
                                                                                        <button type="button" className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button"
                                                                                            onClick={this.updateSelectedCrIbanAll.bind(this, budgetAccount)}
                                                                                        >{budgetAccount.crIban}</button>
                                                                                    </li>
                                                                                ))
                                                                            }
                                                                        </ul>
                                                                    }
                                                                </div>
                                                            </OutsideClickHandler>
                                                        </div>
                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('phone number')}</label>
                                                            <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                                <input value={crCustPhone} type="text" className="dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole" disabled={confirmedOperation} onChange={this.checkCrCustPhone} />
                                                            </div>
                                                        </div>
                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Recipients PIN')}</label>
                                                            <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                                <input type="text" value={this.state.crCustPin} className="dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole" onChange={(event) => { this.setState({ crCustPin: event.target.value }) }} disabled={confirmedOperation} />
                                                            </div>
                                                        </div>
                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Recipients TIN')}</label>
                                                            <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                                <input type="text" value={this.state.crCustTaxid} className="dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole" onChange={(event) => { this.setState({ crCustTaxid: event.target.value }) }} disabled={confirmedOperation} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="dashboard-payment-container-data-section-content-container">
                                                <h3 className="dashboard-payment-container-data-section-content-container-title">{t('Mediator bank')}</h3>
                                                <div className="dashboard-payment-container-data-section-content-container-form dashboard-payment-container-data-section-content-container-form__border-bottom">
                                                    <div className="dashboard-payment-container-data-section-content-container-form-container">
                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Banks name')}</label>
                                                            <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                                <input value={crCorrBankName} type="text" className="dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole" disabled={confirmedOperation} onChange={this.checkCrCorrBankName} />
                                                            </div>
                                                        </div>
                                                        {/* ====================================== */}
                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Select a country')}</label>
                                                            <OutsideClickHandler onOutsideClick={() => { this.setState({ dropdownVisibilityCorCountry: false }) }}>
                                                                <div className={"dashboard-payment-container-data-section-content-container-form-select-box " +
                                                                    (selectedCorCountryCount > 1 &&
                                                                        selectedCorCountryIndex < 0
                                                                        || selectedCorCountryCount === 1 && selectedCorCountryIndex < 0
                                                                        && !dropdownVisibilityCorCountry ? "input-outline" : "")}>
                                                                    <button type='button' className={confirmedOperation ? "dashboard-payment-container-data-section-content-container-form-select-box-button dashboard-payment-container-data-section-content-container-form-select-box-button__disable" : "dashboard-payment-container-data-section-content-container-form-select-box-button"} disabled={Boolean(confirmedOperation)}>
                                                                        <input type="text" className="dashboard-payment-container-data-section-content-container-form-input" disabled={confirmedOperation} value={corCountryFilter}
                                                                            placeholder={t("Choose")}
                                                                            onChange={this.updateCorCountryFilter}
                                                                            onFocus={this.updateCorCountryCount} />
                                                                        <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                                            <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                                            </svg>
                                                                        </div>
                                                                    </button>
                                                                    <ul className={(dropdownVisibilityCorCountry === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                                        {
                                                                            countries && countries.length > 0 &&
                                                                            countries.map((country, index) => {
                                                                                if (country?.name?.toLowerCase().includes(corCountryFilter?.toLowerCase())) {
                                                                                    return (
                                                                                        <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={index}>
                                                                                            <button onClick={this.updateCorCountry.bind(this, index)} className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button">{country.name}</button>
                                                                                        </li>
                                                                                    )
                                                                                }
                                                                            })
                                                                        }

                                                                    </ul>
                                                                </div>
                                                            </OutsideClickHandler>
                                                        </div>
                                                        {/* <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Its country')}</label>
                                                            <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                                <input type="text" value={crCorrBankCountry} className="dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole" disabled={confirmedOperation} onChange={this.checkCrCorrBankCountry} />
                                                            </div>
                                                        </div> */}
                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Select a city')}</label>
                                                            <OutsideClickHandler onOutsideClick={() => { this.setState({ dropdownVisibilityCorCity: false }) }}>
                                                                <div className={"dashboard-payment-container-data-section-content-container-form-select-box " +
                                                                    (selectedCorCityCount > 1 &&
                                                                        selectedCorCityIndex < 0
                                                                        || selectedCorCityCount === 1 && selectedCorCityIndex < 0
                                                                        && !dropdownVisibilityCorCity ? "input-outline" : "")}>
                                                                    <button type='button' className={(confirmedOperation || selectedCorCountryIndex < 0) ? "dashboard-payment-container-data-section-content-container-form-select-box-button dashboard-payment-container-data-section-content-container-form-select-box-button__disable" : "dashboard-payment-container-data-section-content-container-form-select-box-button"} disabled={Boolean(confirmedOperation || selectedCorCountryIndex < 0)}>
                                                                        {/* {
                                                            cities && cities.length > 0 && selectedCityIndex >= 0 ?
                                                                <div className="dashboard-payment-container-data-section-content-container-form-select">{cities[selectedCityIndex].cityName}</div>
                                                                :
                                                                <div className="dashboard-payment-container-data-section-content-container-form-select" style={{ color: '#BDC3C7' }}>{t("Choose")}</div>
                                                        } */}
                                                                        <input type="text" className="dashboard-payment-container-data-section-content-container-form-input" disabled={confirmedOperation || selectedCorCountryIndex < 0} value={corCityFilter}
                                                                            placeholder={t("Choose")}
                                                                            onChange={this.updateCorCityFilter}
                                                                            onFocus={this.updateCorCityCount} />
                                                                        <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                                            <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                                            </svg>
                                                                        </div>
                                                                    </button>
                                                                    <ul className={(dropdownVisibilityCorCity === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                                        {
                                                                            corCities && corCities.length > 0 &&
                                                                            corCities.map((city, index) => {
                                                                                // return (
                                                                                //     <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={index}>
                                                                                //         <button onClick={this.updateCity.bind(this, index)} className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button">{city.cityName}</button>
                                                                                //     </li>
                                                                                // );
                                                                                if (city?.name?.toLowerCase().includes(corCityFilter?.toLowerCase())) {
                                                                                    return (
                                                                                        <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={index}>
                                                                                            <button onClick={this.updateCorCity.bind(this, index)} className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button">{city.name}</button>
                                                                                        </li>
                                                                                    )
                                                                                }
                                                                            })
                                                                        }

                                                                    </ul>
                                                                </div>
                                                            </OutsideClickHandler>
                                                        </div>
                                                        {/* <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Its city')}</label>
                                                            <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                                <input type="text" value={crCorrBankCity} className="dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole" disabled={confirmedOperation} onChange={this.checkCrCorrBankCity} />
                                                            </div>
                                                        </div> */}
                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Its department')}</label>
                                                            <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                                <input value={crCorrBankBranch} type="text" className="dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole" disabled={confirmedOperation} onChange={this.checkCrCorrBankBranch} />
                                                            </div>
                                                        </div>
                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('SWIFT')}</label>
                                                            <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                                <input type="text" value={crCorrBankSwift} className="dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole" disabled={confirmedOperation} onChange={this.checkCrCorrBankSwift} />
                                                            </div>
                                                        </div>
                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Correspondent account')}</label>
                                                            <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                                <input type="text" value={crCorrBankAccount} className="dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole" disabled={confirmedOperation} onChange={this.checkCrCorrBankAccount} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="dashboard-payment-container-data-section-content-container">
                                                <h3 className="dashboard-payment-container-data-section-content-container-title">{t('Payment settings')}</h3>
                                                <div className="dashboard-payment-container-data-section-content-container-form">
                                                    <div className="dashboard-payment-container-data-section-content-container-form-container">
                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('sum')} <span style={{ color: 'red' }}>*</span></label>
                                                            <div className={"dashboard-payment-container-data-section-content-container-form-input-box " + (amountOutline ? "input-outline" : "")}>
                                                                <input value={amount} type="text" className="dashboard-payment-container-data-section-content-container-form-input" disabled={confirmedOperation} placeholder="0" onChange={this.checkAmount} onBlur={this.setAmountOutline} />
                                                                <div className={confirmedOperation ?
                                                                    "dashboard-payment-container-data-section-content-container-form-icon dashboard-payment-container-data-section-content-container-form-icon__currency dashboard-payment-container-data-section-content-container-form-icon__disable" :
                                                                    "dashboard-payment-container-data-section-content-container-form-icon dashboard-payment-container-data-section-content-container-form-icon__currency"
                                                                }>{currency}</div>
                                                            </div>
                                                        </div>
                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Funds will be deducted from the account')} <span style={{ color: 'red' }}>*</span></label>
                                                            <OutsideClickHandler onOutsideClick={this.closeDropdownVisibilityAccount}>
                                                                <div className={"dashboard-payment-container-data-section-content-container-form-select-box " + (!dtAccontIdValidation ? "input-outline" : "")}>
                                                                    <button className={confirmedOperation ? "dashboard-payment-container-data-section-content-container-form-select-box-button dashboard-payment-container-data-section-content-container-form-select-box-button__disable" : "dashboard-payment-container-data-section-content-container-form-select-box-button"} disabled={confirmedOperation} onClick={this.changeDropdownVisibilityAccount}>
                                                                        <div className="dashboard-payment-container-data-section-content-container-form-select">
                                                                            {
                                                                                accounts && accounts.length === 0 && accountData ? t('126') : selectedAccount
                                                                            }
                                                                        </div>
                                                                        <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                                            <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                                            </svg>
                                                                        </div>
                                                                    </button>
                                                                    {
                                                                        accounts.length !== 0 &&
                                                                        <ul className={(dropdownVisibilityAccount === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                                            {
                                                                                accounts.map(account => (
                                                                                    <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={account.accountId}>
                                                                                        <button type="button" className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button" onClick={this.updateDtAccountId.bind(this, account.accountId, account.iban, account.currName)}>{account.iban} / {account.organizedCurrentBalance.wholePart}.{account.organizedCurrentBalance.fractionalPart} {account.currName}</button>
                                                                                    </li>
                                                                                ))
                                                                            }
                                                                        </ul>
                                                                    }

                                                                </div>
                                                            </OutsideClickHandler>
                                                        </div>
                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Appointment')} <span style={{ color: 'red' }}>*</span></label>
                                                            <div className={"dashboard-payment-container-data-section-content-container-form-input-box " + (purposeOutline ? "input-outline" : "")}>
                                                                <input value={purpose} type="text" className="dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole" disabled={confirmedOperation} onChange={this.checkPurpose} onBlur={this.setPurposeOutline} />
                                                            </div>
                                                        </div>
                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Additional Information')}</label>
                                                            <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                                <input value={note} type="text" className="dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole" disabled={confirmedOperation} onChange={this.checkNote} />
                                                            </div>
                                                        </div>
                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Contract and invoices')}</label>
                                                            <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                                <input type="text" className="dashboard-payment-container-data-section-content-container-form-input" />
                                                                <div className="dashboard-payment-container-data-section-content-container-form-icon dashboard-payment-container-data-section-content-container-form-icon__choose">{t('Choose')}</div>
                                                                <input id="file" type="file" className="dashboard-payment-container-data-section-content-container-form-input__file" accept="image/jpeg,image/png,application/pdf,zip,application/zip,application/x-zip,application/x-zip-compressed,.rar,application/x-rar-compressed" multiple={true} onChange={this.chooseFile} />
                                                            </div>
                                                        </div>
                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                            <ul className="dashboard-file-list">
                                                                {
                                                                    this.state.files.map((item, index) => (
                                                                        <li className="dashboard-file-item" key={index}>
                                                                            <div className="dashboard-file-item-left">
                                                                                <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M2.09375 14.6562C3.90625 16.4688 6.84375 16.4688 8.65625 14.6562C10.3438 12.9688 13.0938 10.2188 14.7812 8.53125C14.9375 8.375 14.9375 8.15625 14.7812 8L14.25 7.46875C14.0938 7.3125 13.875 7.3125 13.7188 7.46875C12.0312 9.15625 9.28125 11.9062 7.59375 13.5938C6.375 14.8125 4.375 14.8125 3.15625 13.5938C1.9375 12.375 1.9375 10.375 3.15625 9.15625L10.1875 2.125C11 1.3125 12.3125 1.3125 13.125 2.125C13.9375 2.9375 13.9375 4.25 13.125 5.0625L7.21875 10.9688C6.8125 11.375 6.15625 11.375 5.75 10.9688C5.375 10.5938 5.375 9.9375 5.75 9.53125L10.7812 4.53125C10.9375 4.375 10.9375 4.15625 10.7812 4L10.25 3.46875C10.0938 3.3125 9.875 3.3125 9.71875 3.46875L4.71875 8.46875C3.71875 9.46875 3.71875 11.0625 4.71875 12.0312C5.6875 13.0312 7.28125 13.0312 8.25 12.0312L14.1875 6.125C15.5938 4.71875 15.5938 2.46875 14.1875 1.0625C12.7812 -0.34375 10.5312 -0.34375 9.125 1.0625L2.09375 8.09375C0.28125 9.90625 0.28125 12.8438 2.09375 14.6562Z" fill="#849097" />
                                                                                </svg>
                                                                                <button type="button" className="dashboard-file-item-title" onClick={this.downloadFile.bind(this, index)}>{item.fileName}</button>
                                                                            </div>
                                                                            <button type="button" className="dashboard-file-item-right" onClick={this.deleteFile.bind(this, index)}>
                                                                                <svg className="dashboard-transfer-list-table-icon" width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M8.375 13H9.125C9.3125 13 9.5 12.8438 9.5 12.625V5.875C9.5 5.6875 9.3125 5.5 9.125 5.5H8.375C8.15625 5.5 8 5.6875 8 5.875V12.625C8 12.8438 8.15625 13 8.375 13ZM13.5 2.5H10.9062L9.84375 0.75C9.59375 0.34375 9.03125 0 8.5625 0H5.40625C4.9375 0 4.375 0.34375 4.125 0.75L3.0625 2.5H0.5C0.21875 2.5 0 2.75 0 3V3.5C0 3.78125 0.21875 4 0.5 4H1V14.5C1 15.3438 1.65625 16 2.5 16H11.5C12.3125 16 13 15.3438 13 14.5V4H13.5C13.75 4 14 3.78125 14 3.5V3C14 2.75 13.75 2.5 13.5 2.5ZM5.34375 1.59375C5.375 1.5625 5.46875 1.5 5.5 1.5H5.53125H8.46875C8.5 1.5 8.59375 1.5625 8.625 1.59375L9.15625 2.5H4.8125L5.34375 1.59375ZM11.5 14.5H2.5V4H11.5V14.5ZM4.875 13H5.625C5.8125 13 6 12.8438 6 12.625V5.875C6 5.6875 5.8125 5.5 5.625 5.5H4.875C4.65625 5.5 4.5 5.6875 4.5 5.875V12.625C4.5 12.8438 4.65625 13 4.875 13Z" fill="#586268" />
                                                                                </svg>
                                                                            </button>
                                                                        </li>
                                                                    ))
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="dashboard-payment-container-confirmation-section">
                                        <div className="dashboard-payment-container-confirmation-section-header">
                                            <h3 className="dashboard-payment-container-confirmation-section-header-title">{t('Transfer update')}</h3>
                                        </div>
                                        <div className="dashboard-payment-container-confirmation-section-content">

                                            <h3 className="dashboard-payment-container-confirmation-section-amount">
                                                <span className={"dashboard-payment-container-confirmation-section-amount-currency dashboard-payment-container-confirmation-section-amount-currency__" + currency.toLocaleLowerCase()}>{currencyFilter(currency)}</span>
                                                <span className="dashboard-payment-container-confirmation-section-amount-whole">{this.amountSplit(amount).part1}</span>
                                                <span className="dashboard-payment-container-confirmation-section-amount-fractional">{this.amountSplit(amount).part2}</span>
                                            </h3>
                                            <ul className="dashboard-payment-container-confirmation-section-list">
                                                <li className="dashboard-payment-container-confirmation-section-list-item">
                                                    <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Name of the service')}</p>
                                                    <p className="dashboard-payment-container-data-section-content-container-form-group-data">{t('Abroad')} {t('Transfer')}</p>
                                                </li>
                                                <li className="dashboard-payment-container-confirmation-section-list-item">
                                                    <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('To account')}</p>
                                                    <p className="dashboard-payment-container-data-section-content-container-form-group-data">{crIban}</p>
                                                </li>
                                                <li className="dashboard-payment-container-confirmation-section-list-item">
                                                    <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('From account')}</p>
                                                    <p className="dashboard-payment-container-data-section-content-container-form-group-data">{dtIban}</p>
                                                </li>
                                                <li className="dashboard-payment-container-confirmation-section-list-item">
                                                    <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('feeAmount')}</p>
                                                    <p className="dashboard-payment-container-data-section-content-container-form-group-data">{feeAmount} {currency}</p>
                                                </li>
                                                {info &&
                                                    <li className="dashboard-payment-container-confirmation-section-list-item">
                                                        <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Information')}</p>
                                                        <p className="dashboard-payment-container-data-section-content-container-form-group-data"><b>{info}</b></p>
                                                    </li>
                                                }
                                            </ul>
                                            <div className="dashboard-payment-container-data-section-content-container-form-footer">
                                                {
                                                    dtAccontIdValidation && amountValidation && crBankNameValidation && crBankSwiftValidation && crBankCountryValidation
                                                        && crCustNameValidation && crCustAddressValidation && crIbanValidation && purposeValidation
                                                        && ((localStorage.getItem("isOperNo") === "1" && abroadOpNumber) || (localStorage.getItem("isOperNo") !== "1"))
                                                        //  && crCustPhoneValidation && crBankNameValidation && crBankBranchValidation && crBankCountryValidation && crBankCityValidation && noteValidation &&
                                                        //     crCorrBankNameValidation && crCorrBankSwiftValidation && crCorrBankCountryValidation && crCorrBankCityValidation 
                                                        //     && crCorrBankAccountValidation && crCorrBankBranchValidation
                                                        ?
                                                        <button onClick={() => crrAmount != amount ? this.showTransferAbroad() : this.updateAbroadOperation()} className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__blue dashboard-payment-container-data-section-content-container-form-footer-button__left">{crrAmount != amount ? t('Calculate commission') : t('Update')}</button>
                                                        :
                                                        <button className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__disable dashboard-payment-container-data-section-content-container-form-footer-button__left dashboard-payment-container-data-section-content-container-form-footer-button__disable">{t('Update')}</button>
                                                }
                                                {/* <button onClick={this.updateAbroadOperation} className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__blue dashboard-payment-container-data-section-content-container-form-footer-button__left" onClick={this.updateAbroadOperation}>{t('Update')}</button> */}
                                                <button onClick={this.cancelTransferAbroad} className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__gray dashboard-payment-container-data-section-content-container-form-footer-button__right">{t('cancel')}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                    }
                </Fragment>
            </Fragment>
        )
    }
}
export default translate("translation")(AbroadEdit);
