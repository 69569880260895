import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import accountIcon from './../../img/account-icon.svg';
import cardsIcon from './../../img/cards-icon.png';
import pdfIcon from '../../img/pdf-icon.svg';
import signIcon from '../../img/sign.svg';
import { request } from './../../../../config';
import Dropdown from './../../../../copms/Dropdown/Dropdown';
import Modal from './../../../../copms/Modal/AccountModal.js';
import { translate } from 'react-i18next';
import currencyFilter from './../../../../helpers/currencyFilter';
import Loading from './../../Loading/Loading';
import $ from 'jquery';
import AsanVerification from '../../../../copms/Modal/AsanVerification';
import SimpleModal from '../../../../copms/Modal/SimpleModal';

function saveFile(name, type, data) {
    if (data !== null && navigator.msSaveBlob)
        return navigator.msSaveBlob(new Blob([data], { type: type }), name);
    var a = $("<a style='display: none;'/>");
    var url = window.URL.createObjectURL(new Blob([data], { type: type }));
    a.attr("href", url);
    a.attr("download", name);
    $("body").append(a);
    a[0].click();
    window.URL.revokeObjectURL(url);
    a.remove();
}

function base64ToArrayBuffer(base64) {
    let binaryString = window.atob(base64);
    let binaryLen = binaryString.length;
    let bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
        let ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes;
}

class Accounts extends Component {
    state = {
        accounts: [],
        totalAmountAZN: 0,
        totalAmountUSD: 0,
        totalAmountEUR: 0,
        open: false,
        modalAccountObj: {},
        isLoading: false,
        verificationOpen: false,
        verificationCode: null,
        signSuccessOpen: false,
        status: 1
    }
    updateOpenModal = (value) => {
        this.setState({ open: value });
    }
    numberToString = (number) => {
        if (number.toString() === "0") {
            return "0.00"
        }
        else {
            if (number.toString().indexOf('.') !== -1) {
                let numberArray = number.toString().split('.');
                let x = (numberArray[1].length === 1) ? (numberArray[1] + '0') : numberArray[1];
                return numberArray[0] + "." + x;
            }
            else {
                return number.toString() + ".00"
            }
        }
    }
    dropdownItemHandler = (index, account) => {
        switch (index) {
            case 1:
                this.showRequisites(account.accountId);
                break;
            case 2:
                this.setState({
                    open: true,
                    modalAccountObj: account
                });
                break;
        }
    }
    showAccounts = () => {
        this.setState({ isLoading: true });
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getAccountListForCustomerRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            isBalanceAccount: 0
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            isBalanceAccount: 0
        }

        fetch(request(this.state.status == 1 ? "account/getAccountListFor" + customerType + "Customer" : this.state.status == 2 ? "order/getAccountOrderList" : "order/getCancelledAccountOrderList", getAccountListForCustomerRequestBody))
            .then(res => res.text())
            .then((getAccountListForCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getAccountListForCustomerResponse = JSON.parse(getAccountListForCustomerResponseJSON);
                if (getAccountListForCustomerResponse.status.statusCode !== 1 && getAccountListForCustomerResponse.status.statusCode !== 126) {
                    if (getAccountListForCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getAccountListForCustomerResponse.status.statusCode));
                        this.setState({ isLoading: false });
                    }
                }
                else {
                    if (getAccountListForCustomerResponse.status.statusCode === 126) {
                        getAccountListForCustomerResponse.accountList = [];
                    }
                    let totalAmount = {
                        azn: 0,
                        usd: 0,
                        eur: 0
                    }

                    for (let i = 0; i < getAccountListForCustomerResponse.accountList.length; i++) {
                        if (this.state.status == 1) {
                            // // =========ID=========
                            let accountId = "";
                            if (typeof (getAccountListForCustomerResponse.accountList[i].accountId) === 'object' && getAccountListForCustomerResponse.account[i].accountId.hasOwnProperty('c')) {
                                getAccountListForCustomerResponse.account[i].id.c.forEach(part => {
                                    accountId += part;
                                });
                            }
                            else {
                                accountId += getAccountListForCustomerResponse.accountList[i].accountId;
                            }
                            getAccountListForCustomerResponse.accountList[i].accountId = accountId;
                            // =========BALANCE=========
                            if (getAccountListForCustomerResponse.accountList[i].currentBalance.toString() === "0") {
                                getAccountListForCustomerResponse.accountList[i].organizedCurrentBalance = {
                                    wholePart: "0",
                                    fractionalPart: "00"
                                }
                            }
                            else {
                                if (getAccountListForCustomerResponse.accountList[i].currentBalance.toString().indexOf('.') !== -1) {
                                    let currentBalanceArray = getAccountListForCustomerResponse.accountList[i].currentBalance.toString().split('.');
                                    getAccountListForCustomerResponse.accountList[i].organizedCurrentBalance = {
                                        wholePart: currentBalanceArray[0],
                                        fractionalPart: (currentBalanceArray[1].length === 1) ? (currentBalanceArray[1] + '0') : currentBalanceArray[1]
                                    }
                                }
                                else {
                                    let currentBalanceArray = getAccountListForCustomerResponse.accountList[i].currentBalance.toString().split('.');
                                    getAccountListForCustomerResponse.accountList[i].organizedCurrentBalance = {
                                        wholePart: getAccountListForCustomerResponse.accountList[i].currentBalance.toString(),
                                        fractionalPart: "00"
                                    }
                                }
                            }
                            // =========TOTALAMOUNT=========
                            if (getAccountListForCustomerResponse.accountList[i].currName === "AZN") {
                                totalAmount.azn += getAccountListForCustomerResponse.accountList[i].currentBalance;
                            }
                            if (getAccountListForCustomerResponse.accountList[i].currName === "USD") {
                                totalAmount.usd += getAccountListForCustomerResponse.accountList[i].currentBalance;
                            }
                            if (getAccountListForCustomerResponse.accountList[i].currNames === "EUR") {
                                totalAmount.eur += getAccountListForCustomerResponse.accountList[i].currentBalance;
                            }
                            this.setState({ totalAmountAZN: this.numberToString(totalAmount.azn.toFixed(2)) });
                            this.setState({ totalAmountUSD: this.numberToString(totalAmount.usd.toFixed(2)) });
                            this.setState({ totalAmountEUR: this.numberToString(totalAmount.eur.toFixed(2)) });
                        }
                        // =========CURRENCY=========
                        getAccountListForCustomerResponse.accountList[i].currencyIcon = currencyFilter(getAccountListForCustomerResponse.accountList[i].currName);
                    }

                    this.setState({ accounts: getAccountListForCustomerResponse.accountList });
                    this.setState({ isLoading: false });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }
    showRequisites = (id) => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getAccountRequisiteIndividualByteArrayRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            accountId: id,
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            accountId: id,
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }
        fetch(request("account/getAccountRequisite" + customerType + "ByteArray", getAccountRequisiteIndividualByteArrayRequestBody))
            .then(res => res.json())
            .then((getAccountRequisiteByteArrayResponse) => {
                if (getAccountRequisiteByteArrayResponse.status.statusCode !== 1) {
                    if (getAccountRequisiteByteArrayResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    let arrrayBuffer = base64ToArrayBuffer(getAccountRequisiteByteArrayResponse.bytes); //data is the base64 encoded string
                    let blob = new Blob([arrrayBuffer], { type: "application/pdf" });
                    let link = window.URL.createObjectURL(blob);
                    var newWin = window.open(link, '');
                    if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
                        saveFile(this.props.t('Account_Requisites') + "_" + this.getFormatedDate(this.state.startDate) + "-" + this.getFormatedDate(this.state.endDate) + ".pdf", "application/pdf", arrrayBuffer)
                    }
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });

    }

    handleSign = (orderId) => {
        this.props.updateAppState('isLoadingTransparentBackground', true);

        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let certCode = customerType == 'Juridical' ? JSON.parse(localStorage.getItem('custObject')).company.certCode : JSON.parse(localStorage.getItem('custObject')).certCode

        let getSignFileRequestBody = {
            orderId: orderId,
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            langType: localStorage.getItem('i18nextLng').toUpperCase(),
            certCode: certCode,
            userId: localStorage.getItem('userId'),
            phoneNumber: localStorage.getItem('phoneNumber')
        }

        fetch(request("order/getAccountOrderSignFile", getSignFileRequestBody))
            .then(res => res.text())
            .then((getSignFileResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getSignFileResponse = JSON.parse(getSignFileResponseJSON);
                if (getSignFileResponse.status.statusCode !== 1) {
                    if (getSignFileResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getSignFileResponse.status.statusCode));
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    this.props.updateAppState('isLoadingTransparentBackground', false);

                    this.setState({ verificationOpen: true, verificationCode: getSignFileResponse.verificationCode });
                    let signFileRequestBody = {
                        token: localStorage.getItem('token'),
                        transactionId: getSignFileResponse.transactionId,
                        userId: localStorage.getItem('userId'),
                        orderId: orderId,
                        certCode: certCode,
                        phoneNumber: localStorage.getItem('phoneNumber')
                    }
                    fetch(request("order/signAccountOrderFile", signFileRequestBody))
                        .then(res => res.text())
                        .then((signDepositFileResponseJSON) => {
                            const JSON = require('true-json-bigint');
                            let signDepositFileResponse = JSON.parse(signDepositFileResponseJSON);
                            if (signDepositFileResponse.status.statusCode !== 1) {
                                if (signDepositFileResponse.status.statusCode === 200) {
                                    this.props.logout();
                                } else {
                                    this.props.updateAppState('isLoadingTransparentBackground', false);
                                    this.setState({ verificationOpen: false });
                                    this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(signDepositFileResponse.status.statusCode), '/dashboard/home');
                                }
                            } else {
                                this.props.updateAppState('isLoadingTransparentBackground', false);
                                this.setState({ verificationOpen: false, signSuccessOpen: true });
                            }
                        });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    downloadContract = (file, filename) => {
        let arrrayBuffer = base64ToArrayBuffer(file); //data is the base64 encoded string
        let blob = new Blob([arrrayBuffer], { type: "application/pdf" });
        let link = window.URL.createObjectURL(blob);
        window.open(link, filename);
        let a = document.createElement("a");
        a.href = window.URL.createObjectURL(blob);
        a.download = filename || "filename.pdf"; // Set the desired filename
        a.click();
        window.URL.revokeObjectURL(a.href);
    }

    downloadADOC = (file, filename) => {
        let arrrayBuffer = base64ToArrayBuffer(file); //data is the base64 encoded string
        saveFile(filename + ".adoc", "application/adoc", arrrayBuffer);
    }

    downloadEDOC = (file, filename) => {
        let arrrayBuffer = base64ToArrayBuffer(file); //data is the base64 encoded string
        saveFile(filename + ".edoc", "application/edoc", arrrayBuffer);
    }

    componentDidMount() {
        if (this.props.location.state) {
            this.setState({ status: this.props.location.state?.status }, () => {
                this.props.history.replace();
            });
        }
        this.showAccounts();
    }

    render() {
        let { accounts, dropdownItems, totalAmountAZN, totalAmountUSD, totalAmountEUR, open, modalAccountObj, isLoading, status, verificationOpen, verificationCode, signSuccessOpen } = this.state;
        let { t, updateAppState, alertState, history } = this.props;
        return (
            <Fragment>
                <AsanVerification open={verificationOpen} verificationCode={verificationCode} />
                <SimpleModal open={signSuccessOpen} close={() => this.setState({ signSuccessOpen: false }, () => this.componentDidMount())} title={t("The contract was successfully signed. Awaiting confirmation from the bank.")} />

                <div className="dashboard-header-route">
                    <div className="dashboard-header-route-left">
                        <button onClick={() => this.props.history.goBack()}>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.7344 21.2188L12.6719 20.3281C12.9062 20.0938 12.9062 19.7188 12.6719 19.5312L5.40625 12.2188H21.4375C21.7188 12.2188 22 11.9844 22 11.6562V10.3438C22 10.0625 21.7188 9.78125 21.4375 9.78125H5.40625L12.6719 2.51562C12.9062 2.32812 12.9062 1.95312 12.6719 1.71875L11.7344 0.828125C11.5469 0.59375 11.1719 0.59375 10.9375 0.828125L1.14062 10.625C0.90625 10.8594 0.90625 11.1875 1.14062 11.4219L10.9375 21.2188C11.1719 21.4531 11.5469 21.4531 11.7344 21.2188Z" fill="#2E3131" />
                            </svg>
                        </button>
                        <h2>{t('accounts')}</h2>
                    </div>
                    <div>
                        {
                            localStorage.getItem('checkResident') == "1" && (
                                localStorage.getItem("isAsan") === "true" ?
                                    <Link to='/dashboard/orders/account' class="dashboard-header-route-button">{t('Open an account')}</Link>
                                    : <button onClick={() => alertState(true, 'warning', null, t('log in with Asan İmza'))} class="dashboard-header-route-button">{t('Open an account')}</button>)
                        }
                    </div>
                </div>
                <Modal open={open} modalAccountObj={modalAccountObj} updateOpenModal={this.updateOpenModal} updateAppState={updateAppState} showAccounts={this.showAccounts} />
                <div className="dashboard-container-main-section">
                    {
                        isLoading ?
                            <Loading />
                            :
                            (status == 1 && accounts.length === 0) ?
                                <div className="dashboard-finance-section-invisible-cards-section">
                                    <img src={cardsIcon} className="dashboard-finance-section-invisible-cards-section-image" />
                                    <div className="dashboard-finance-section-invisible-cards-section-paragraph">
                                        <p className="dashboard-finance-section-invisible-cards-section-text">{t('you do not currently have an active account.')}</p>
                                        <p className="dashboard-finance-section-invisible-cards-section-text">{t('if you want a new account, please order.')}</p>
                                    </div>
                                    {localStorage.getItem('checkResident') === "1" &&
                                        (
                                            localStorage.getItem("isAsan") === "true" ?
                                                <Link to='/dashboard/orders/account' class="dashboard-finance-section-invisible-cards-section-button">{t('Open an account')}</Link>
                                                : <button onClick={() => alertState(true, 'warning', null, t('log in with Asan İmza'))} class="dashboard-finance-section-invisible-cards-section-button">{t('Open an account')}</button>
                                        )}
                                </div>
                                :
                                <div className="dashboard-finance-section-cards-section">
                                    <div className="dashboard-finance-section-cards-section-statistics-section">

                                        <div className="dashboard-finance-section-cards-section-statistics-section-item">
                                            <div className="dashboard-finance-section-cards-section-statistics-section-item-left-side">
                                                <p className="dashboard-finance-section-cards-section-statistics-section-item-balance"><span className="dashboard-finance-section-cards-section-statistics-icon dashboard-finance-section-cards-section-statistics-icon__azn">₼</span>{totalAmountAZN}</p>
                                                <p className="dashboard-finance-section-cards-section-statistics-section-item-currency">{t("account balance")} (AZN)</p>
                                            </div>
                                            <div className="dashboard-finance-section-cards-section-statistics-section-item-left-side">
                                                <svg className="dashboard-finance-section-cards-section-statistics-section-item-graphics" width="162" height="61" viewBox="0 0 162 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1 56.4446V36.9446L4 37.4446L11.5 36.9446L20.5 35.4446L31 33.4446L39.5 32.9446L47 34.9446L53.5 37.4446L62.5 40.4446L71 42.4446C74.8333 42.9446 78.6 44.4446 79 44.4446C79.4 44.4446 90.1667 43.9446 93.5 43.9446L103.5 41.4446L118.5 36.4446L136.5 28.4446L148 24.4446L154.5 22.9446H161V56.4446H1Z" fill="url(#paint0_linear)" />
                                                    <path d="M1.53467 37.3042C12.0258 38.6957 28.2515 31.6169 40.8712 33.2614C53.2112 35.4517 57.709 40.964 76.1321 43.7489C109.473 48.9169 141.225 20.052 160.419 23.001" stroke="#0066B2" strokeWidth="1.5" strokeLinecap="round" />
                                                    <defs>
                                                        <linearGradient id="paint0_linear" x1="0.999999" y1="-0.0554165" x2="1" y2="45.9446" gradientUnits="userSpaceOnUse">
                                                            <stop stopColor="#0066B2" />
                                                            <stop offset="1" stopColor="white" stopOpacity="0" />
                                                        </linearGradient>
                                                    </defs>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="dashboard-finance-section-cards-section-statistics-section-item">
                                            <div className="dashboard-finance-section-cards-section-statistics-section-item-left-side">

                                                <p className="dashboard-finance-section-cards-section-statistics-section-item-balance"><span className="dashboard-finance-section-cards-section-statistics-icon">$</span>{totalAmountUSD}</p>
                                                <p className="dashboard-finance-section-cards-section-statistics-section-item-currency">{t("account balance")} (USD)</p>
                                            </div>
                                            <div className="dashboard-finance-section-cards-section-statistics-section-item-left-side">
                                                <svg className="dashboard-finance-section-cards-section-statistics-section-item-graphics" width="162" height="61" viewBox="0 0 162 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1 56.4446V36.9446L4 37.4446L11.5 36.9446L20.5 35.4446L31 33.4446L39.5 32.9446L47 34.9446L53.5 37.4446L62.5 40.4446L71 42.4446C74.8333 42.9446 78.6 44.4446 79 44.4446C79.4 44.4446 90.1667 43.9446 93.5 43.9446L103.5 41.4446L118.5 36.4446L136.5 28.4446L148 24.4446L154.5 22.9446H161V56.4446H1Z" fill="url(#paint0_linear)" />
                                                    <path d="M1.53467 37.3042C12.0258 38.6957 28.2515 31.6169 40.8712 33.2614C53.2112 35.4517 57.709 40.964 76.1321 43.7489C109.473 48.9169 141.225 20.052 160.419 23.001" stroke="#6DD230" strokeWidth="1.5" strokeLinecap="round" />
                                                    <defs>
                                                        <linearGradient id="paint0_linear" x1="0.999999" y1="-0.0554165" x2="1" y2="45.9446" gradientUnits="userSpaceOnUse">
                                                            <stop stopColor="#6DD230" />
                                                            <stop offset="1" stopColor="white" stopOpacity="0" />
                                                        </linearGradient>
                                                    </defs>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="dashboard-finance-section-cards-section-statistics-section-item">
                                            <div className="dashboard-finance-section-cards-section-statistics-section-item-left-side">
                                                <p className="dashboard-finance-section-cards-section-statistics-section-item-balance"><span className="dashboard-finance-section-cards-section-statistics-icon">€</span>{totalAmountEUR}</p>
                                                <p className="dashboard-finance-section-cards-section-statistics-section-item-currency">{t("account balance")} (EUR)</p>
                                            </div>
                                            <div className="dashboard-finance-section-cards-section-statistics-section-item-left-side">
                                                <svg className="dashboard-finance-section-cards-section-statistics-section-item-graphics" width="162" height="61" viewBox="0 0 162 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1 56.4446V36.9446L4 37.4446L11.5 36.9446L20.5 35.4446L31 33.4446L39.5 32.9446L47 34.9446L53.5 37.4446L62.5 40.4446L71 42.4446C74.8333 42.9446 78.6 44.4446 79 44.4446C79.4 44.4446 90.1667 43.9446 93.5 43.9446L103.5 41.4446L118.5 36.4446L136.5 28.4446L148 24.4446L154.5 22.9446H161V56.4446H1Z" fill="url(#paint0_linear)" />
                                                    <path d="M1.53467 37.3042C12.0258 38.6957 28.2515 31.6169 40.8712 33.2614C53.2112 35.4517 57.709 40.964 76.1321 43.7489C109.473 48.9169 141.225 20.052 160.419 23.001" stroke="#FFAB2B" strokeWidth="1.5" strokeLinecap="round" />
                                                    <defs>
                                                        <linearGradient id="paint0_linear" x1="0.999999" y1="-0.0554165" x2="1" y2="45.9446" gradientUnits="userSpaceOnUse">
                                                            <stop stopColor="#FFAB2B" />
                                                            <stop offset="1" stopColor="white" stopOpacity="0" />
                                                        </linearGradient>
                                                    </defs>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dashboard-finance-section-cards-section-table-section dashboard-finance-section-cards-section-table-section-order">
                                        <div className="dashboard-deposit-buttons">
                                            <button className={`dashboard-deposit-button dashboard-deposit-button--available ${status === 1 ? 'dashboard-deposit-button--active' : ''}`} onClick={() => { this.setState({ status: 1 }, () => this.showAccounts()) }}>
                                                <div className='dashboard-deposit-button-circle'></div>
                                                <p className='dashboard-deposit-button-title'>{t("Available")}</p>
                                            </button>
                                            <button className={`dashboard-deposit-button dashboard-deposit-button--progress ${status === 2 ? 'dashboard-deposit-button--active' : ''}`} onClick={() => { this.setState({ status: 2 }, () => this.showAccounts()) }}>
                                                <div className='dashboard-deposit-button-circle'></div>
                                                <p className='dashboard-deposit-button-title'>{t("orders")}</p>
                                            </button>
                                            <button className={`dashboard-deposit-button dashboard-deposit-button--reject ${status === 3 ? 'dashboard-deposit-button--active' : ''}`} onClick={() => { this.setState({ status: 3 }, () => this.showAccounts()) }}>
                                                <div className='dashboard-deposit-button-circle'></div>
                                                <p className='dashboard-deposit-button-title'>{t("refusal")}</p>
                                            </button>
                                        </div>
                                        <table className="dashboard-finance-section-cards-section-table">
                                            <tbody>
                                                {
                                                    accounts.length > 0 ?
                                                        accounts.map((account, index) => (
                                                            <Fragment key={index}>
                                                                <tr className="dashboard-finance-section-cards-section-row dashboard-finance-section-cards-section-row-order" key={index}>
                                                                    {account.accountName &&
                                                                        <td className="dashboard-finance-section-cards-section-cell">
                                                                            <img src={accountIcon} className="dashboard-finance-section-cards-section-cell-icon" />
                                                                            <div className="dashboard-finance-section-cards-section-cell-main-section">
                                                                                <Link to={"/dashboard/finance/accounts/" + account.accountId} className="dashboard-finance-section-cards-section-cell-content">{account.accountName}</Link>
                                                                                <p className="dashboard-finance-section-cards-section-cell-title">{t('account name')}</p>
                                                                            </div>
                                                                        </td>}
                                                                    {account.orderDate &&
                                                                        <td className="dashboard-finance-section-cards-section-cell">
                                                                            <img src={accountIcon} className="dashboard-finance-section-cards-section-cell-icon" />
                                                                            <div className="dashboard-finance-section-cards-section-cell-main-section">
                                                                                <p className="dashboard-finance-section-cards-section-cell-content">{account.orderDate}</p>
                                                                                <p className="dashboard-finance-section-cards-section-cell-title">{t('date')}</p>
                                                                            </div>
                                                                        </td>}
                                                                    {account.iban &&
                                                                        <td className="dashboard-finance-section-cards-section-cell">
                                                                            <p className="dashboard-finance-section-cards-section-cell-content">{account.iban}</p>
                                                                            <p className="dashboard-finance-section-cards-section-cell-title">{t('account number')}</p>
                                                                        </td>}
                                                                    {account.organizedCurrentBalance &&
                                                                        <td className="dashboard-finance-section-cards-section-cell">
                                                                            <p className="dashboard-finance-section-cards-section-cell-content">
                                                                                <span className={"dashboard-finance-section-cards-section-amount-currency dashboard-finance-section-cards-section-amount-currency__" + account.currName.toLowerCase()}>{account.currencyIcon}</span>
                                                                                <span className="dashboard-finance-section-cards-section-amount-whole-part">{account.organizedCurrentBalance.wholePart}</span>
                                                                                <span className="dashboard-finance-section-cards-section-amount-fractional-part">{account.organizedCurrentBalance.fractionalPart}</span>
                                                                            </p>
                                                                            <p className="dashboard-finance-section-cards-section-cell-title">{t('balance')}</p>
                                                                        </td>}
                                                                    <td className="dashboard-finance-section-cards-section-cell">
                                                                        <p className="dashboard-finance-section-cards-section-cell-content">{account.branchName}</p>
                                                                        <p className="dashboard-finance-section-cards-section-cell-title">{t('branch')}</p>
                                                                    </td>
                                                                    <td className="dashboard-finance-section-cards-section-cell">
                                                                        <p className="dashboard-finance-section-cards-section-cell-content">{account.currName}</p>
                                                                        <p className="dashboard-finance-section-cards-section-cell-title">{t('currency')}</p>
                                                                    </td>
                                                                    {account.status &&
                                                                        <td className="dashboard-finance-section-cards-section-cell">
                                                                            <p className="dashboard-finance-section-cards-section-cell-content">{account.status}</p>
                                                                            <p className="dashboard-finance-section-cards-section-cell-title">{t('Status')}</p>
                                                                        </td>}
                                                                    {account.contract &&
                                                                        <td className="dashboard-finance-section-cards-section-cell">
                                                                            <button className='dashboard-finance-section-cards-section-cell-button dashboard-finance-section-cards-section-cell-button-contract' onClick={() => this.downloadContract(account.contract, t('Contracts'))}>
                                                                                <img src={pdfIcon} className="dashboard-finance-section-cards-section-cell-icon" />
                                                                                {t('Contracts')}
                                                                            </button>
                                                                        </td>}
                                                                    {account.adoc &&
                                                                        <td className="dashboard-finance-section-cards-section-cell">
                                                                            <button className='dashboard-finance-section-cards-section-cell-button dashboard-finance-section-cards-section-cell-button-contract' onClick={() => this.downloadADOC(account.adoc, t('ADOC'))}>
                                                                                <svg width="28" height="23" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M6.75 7.40625C6.75 7.1875 6.5625 7.03125 6.375 7.03125H5.625C5.40625 7.03125 5.25 7.1875 5.25 7.40625V10.0312H3.71875C3.375 10.0312 3.21875 10.4375 3.4375 10.6562L5.6875 12.9062C5.84375 13.0625 6.125 13.0625 6.28125 12.9062L8.53125 10.6562C8.75 10.4375 8.59375 10.0312 8.25 10.0312H6.75V7.40625ZM11.5312 3.0625L8.90625 0.46875C8.625 0.1875 8.25 0 7.875 0H1.46875C0.65625 0.03125 0 0.6875 0 1.53125V14.5312C0 15.3438 0.65625 16 1.46875 16H10.4688C11.3125 16 12 15.3438 12 14.5312V4.125C12 3.75 11.8125 3.34375 11.5312 3.0625ZM7.96875 1.625L10.375 4.03125H7.96875V1.625ZM10.5 14.5312H1.46875V1.53125H6.46875V4.78125C6.46875 5.1875 6.8125 5.53125 7.21875 5.53125H10.5V14.5312Z" fill="#0179D2" />
                                                                                </svg>
                                                                                {t('ADOC')}
                                                                            </button>
                                                                        </td>}
                                                                    {account.edoc &&
                                                                        <td className="dashboard-finance-section-cards-section-cell">
                                                                            <button className='dashboard-finance-section-cards-section-cell-button dashboard-finance-section-cards-section-cell-button-contract' onClick={() => this.downloadEDOC(account.edoc, t('EDOC'))}>
                                                                                <svg width="28" height="23" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M6.75 7.40625C6.75 7.1875 6.5625 7.03125 6.375 7.03125H5.625C5.40625 7.03125 5.25 7.1875 5.25 7.40625V10.0312H3.71875C3.375 10.0312 3.21875 10.4375 3.4375 10.6562L5.6875 12.9062C5.84375 13.0625 6.125 13.0625 6.28125 12.9062L8.53125 10.6562C8.75 10.4375 8.59375 10.0312 8.25 10.0312H6.75V7.40625ZM11.5312 3.0625L8.90625 0.46875C8.625 0.1875 8.25 0 7.875 0H1.46875C0.65625 0.03125 0 0.6875 0 1.53125V14.5312C0 15.3438 0.65625 16 1.46875 16H10.4688C11.3125 16 12 15.3438 12 14.5312V4.125C12 3.75 11.8125 3.34375 11.5312 3.0625ZM7.96875 1.625L10.375 4.03125H7.96875V1.625ZM10.5 14.5312H1.46875V1.53125H6.46875V4.78125C6.46875 5.1875 6.8125 5.53125 7.21875 5.53125H10.5V14.5312Z" fill="#0179D2" />
                                                                                </svg>
                                                                                {t('EDOC')}
                                                                            </button>
                                                                        </td>}
                                                                    {account.mustBeSign && localStorage.getItem("isAsan") === "true" &&
                                                                        <td className="dashboard-finance-section-cards-section-cell">
                                                                            <button className='dashboard-finance-section-cards-section-cell-button dashboard-finance-section-cards-section-cell-button-sign' onClick={() => this.handleSign(account.orderId,)}>
                                                                                <img src={signIcon} className="dashboard-finance-section-cards-section-cell-icon" />
                                                                                {t('Sign')}
                                                                            </button>
                                                                        </td>}
                                                                    {status == 1 &&
                                                                        <td className="dashboard-finance-section-cards-section-cell">
                                                                            <div className="dashboard-finance-section-cards-section-button-group">
                                                                                <Dropdown
                                                                                    dropdownItems={[
                                                                                        { title: this.props.t('extract'), url: "/dashboard/finance/accounts/extract/" + account.accountId },
                                                                                        { title: this.props.t('details') },
                                                                                        { title: this.props.t('change name') },
                                                                                    ]}
                                                                                    dropdownItemHandler={(i) => this.dropdownItemHandler(i, account)}
                                                                                />
                                                                            </div>
                                                                        </td>}
                                                                </tr>
                                                            </Fragment>
                                                        ))
                                                        :
                                                        <div className="dashboard-finance-section-invisible-cards-section">
                                                            <img src={cardsIcon} className="dashboard-finance-section-invisible-cards-section-image" />
                                                            <div className="dashboard-finance-section-invisible-cards-section-paragraph">
                                                                <p className="dashboard-finance-section-invisible-cards-section-text">{t('if you want a new account, please order.')}</p>
                                                            </div>
                                                            {localStorage.getItem('checkResident') === "1" &&
                                                                (
                                                                    localStorage.getItem("isAsan") === "true" ?
                                                                        <Link to='/dashboard/orders/account' class="dashboard-finance-section-invisible-cards-section-button">{t('Open an account')}</Link>
                                                                        : <button onClick={() => alertState(true, 'warning', null, t('log in with Asan İmza'))} class="dashboard-finance-section-invisible-cards-section-button">{t('Open an account')}</button>
                                                                )}
                                                        </div>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                    }
                </div>
            </Fragment >
        )
    }
}

export default translate("translation")(Accounts);