import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { translate } from 'react-i18next';
import { request } from './../../../config';
import AlertPopup from './../../../copms/AlertPopup/AlertPopup';
import OutsideClickHandler from 'react-outside-click-handler';
import transferConfirmation from './../img/transfer-confirmation.svg';
import RadioButton from "../../../copms/RadioButton/RadioButtonSolid";
import FatchaInfoModal from '../../../copms/Modal/FatchaInfoModal';
import pdfImg from '../img/pdf-new.svg'
import AsanVerification from '../../../copms/Modal/AsanVerificationOrderDebitCard';
import OrderSuccessModal from '../../../copms/Modal/AccountOrderSuccessModal';
import SimpleModal from '../../../copms/Modal/SimpleModal';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ListItemText from '@mui/material/ListItemText';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function base64ToArrayBuffer(base64) {
    let binaryString = window.atob(base64);
    let binaryLen = binaryString.length;
    let bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
        let ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes;
}

class PlasticCard extends Component {
    state = {
        step: 1,
        open: false,
        custType: null,
        branches: [],
        selectedBranchIndex: -1,
        selectedBranchIndexCount: 0,
        dropdownVisibilityBranches: false,
        currencies: [],
        selectedCurrencyIndex: -1,
        selectedCurrencyIndexCount: 0,
        dropdownVisibilityCurrencies: false,
        purposes: [],
        selectedPurposes: [],
        selectedPurposeIndex: -1,
        selectedPurposeIndexCount: 0,
        dropdownVisibilityPurposes: false,
        accounts: [],
        selectedAccountIndex: -1,
        selectedAccountIndexCount: 0,
        dropdownVisibilityAccounts: false,
        serials: [],
        selectedSerialIndex: -1,
        selectedSerialIndexCount: 0,
        dropdownVisibilitySerials: false,
        chkAsan: false,
        chkDvx: false,
        chkContract: false,
        amount: '',
        amountOutline: false,
        serialNumber: '',
        serialNumberOutline: false,
        note: '',
        fee: '',
        questions: [],
        orderId: null,
        contract: '',
        successModal: false,
        successModalData: null,
        signSuccessOpen: false,
        confirmedOperation: false,
        verificationOpen: false,
        verificationCode: null,
        selectedPurposesIsEmpty: false
    }

    async getDropdownsData() {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let getOrderPayloadRequestBody = {
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }
        await fetch(request("order/accountOrderPayload", getOrderPayloadRequestBody))
            .then(res => res.text())
            .then((getOrderPayloadResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getOrderPayloadResponse = JSON.parse(getOrderPayloadResponseJSON);
                if (getOrderPayloadResponse.status.statusCode !== 1) {
                    if (getOrderPayloadResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getOrderPayloadResponse.status.statusCode));
                    }
                }
                else {
                    this.setState({
                        custType: getOrderPayloadResponse.payload.custType,
                        branches: getOrderPayloadResponse.payload.branches ?? [], currencies: getOrderPayloadResponse.payload.currencies ?? [],
                        purposes: getOrderPayloadResponse.payload.purposes ?? [], note: getOrderPayloadResponse.payload.note,
                        accounts: getOrderPayloadResponse.payload.accounts ?? [],
                        serials: getOrderPayloadResponse.payload.serial ?? []
                    });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });

        this.props.updateAppState('isLoadingTransparentBackground', false);
    }

    createAccountOrder = () => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let createAccountOrderRequestBody = {
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            accountId: this.state.accounts[this.state.selectedAccountIndex].id,
            purposeId: this.state.selectedPurposes.map(item => item.id),
            currCode: this.state.currencies[this.state.selectedCurrencyIndex].currCode,
            branchId: this.state.branches[this.state.selectedBranchIndex].id,
            serial: this.state.serials[this.state.selectedSerialIndex],
            circulationAmount: this.state.amount,
            serialNumber: this.state.serialNumber,
            chkAsan: this.state.chkAsan,
            chkDvx: this.state.chkDvx,
        }
        fetch(request("order/createAccountOrder", createAccountOrderRequestBody))
            .then(res => res.text())
            .then((createAccountOrderResponseJSON) => {
                const JSON = require('true-json-bigint');
                let createAccountOrderResponse = JSON.parse(createAccountOrderResponseJSON);
                if (createAccountOrderResponse.status.statusCode !== 1) {
                    if (createAccountOrderResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(createAccountOrderResponse.status.statusCode));
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    this.setState({
                        step: 2, orderId: createAccountOrderResponse.orderId, questions: createAccountOrderResponse.questions,
                        answers: createAccountOrderResponse.questions.map(question => ({
                            questionId: question.questionId,
                            answerId: question.answers.find(answer => answer.isDefault == 1).answerId
                        }))
                    });
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    getSignFileDVX = () => {
        this.props.updateAppState('isLoadingTransparentBackground', true);

        let customerType = this.state.custType != 0 ? 'Individual' : 'Juridical';
        let certCode = customerType == 'Juridical' ? JSON.parse(localStorage.getItem('custObject')).company.certCode : JSON.parse(localStorage.getItem('custObject')).certCode

        let getSignFileDVXRequestBody = {
            userId: localStorage.getItem('userId'),
            token: localStorage.getItem('token'),
            phoneNumber: localStorage.getItem('phoneNumber'),
            certCode,
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
        }
        fetch(request("order/getSignFileDVX", getSignFileDVXRequestBody))
            .then(res => res.text())
            .then((getSignFileDVXResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getSignFileDVXResponse = JSON.parse(getSignFileDVXResponseJSON);
                if (getSignFileDVXResponse.status.statusCode !== 1) {
                    if (getSignFileDVXResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getSignFileDVXResponse.status.statusCode));
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                    this.setState({ verificationOpen: true, verificationCode: getSignFileDVXResponse.verificationCode });
                    let signFileRequestBody = {
                        userId: localStorage.getItem('userId'),
                        id: getSignFileDVXResponse.id,
                        voen: getSignFileDVXResponse.voen,
                        token: localStorage.getItem('token'),
                        phoneNumber: localStorage.getItem('phoneNumber'),
                        certCode,
                        transactionId: getSignFileDVXResponse.transactionId,
                        documentBytes: getSignFileDVXResponse.templeteFile,
                        lang: localStorage.getItem('i18nextLng').toUpperCase(),
                    }
                    fetch(request("order/signFileDVX", signFileRequestBody))
                        .then(res => res.text())
                        .then((signDepositFileResponseJSON) => {
                            const JSON = require('true-json-bigint');
                            let signDepositFileResponse = JSON.parse(signDepositFileResponseJSON);
                            if (signDepositFileResponse.status.statusCode !== 1) {
                                if (signDepositFileResponse.status.statusCode === 200) {
                                    this.props.logout();
                                } else {
                                    this.props.updateAppState('isLoadingTransparentBackground', false);
                                    this.setState({ verificationOpen: false });
                                    this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(signDepositFileResponse.status.statusCode), '/dashboard/home');
                                }
                            } else {
                                this.props.updateAppState('isLoadingTransparentBackground', false);
                                this.setState({ verificationOpen: false });
                                this.createAccountOrder();
                            }
                        });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    accountOrderContract = () => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let getAccountOrderContractRequestBody = {
            orderId: this.state.orderId,
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            answers: this.state.answers
        }
        fetch(request("order/accountOrderContract", getAccountOrderContractRequestBody))
            .then(res => res.text())
            .then((getAccountOrderContractResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getAccountOrderContractResponse = JSON.parse(getAccountOrderContractResponseJSON);
                if (getAccountOrderContractResponse.status.statusCode !== 1) {
                    if (getAccountOrderContractResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else if (getAccountOrderContractResponse.status.statusCode === 573) {
                        this.setState({ fatchaInfo: true })
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getAccountOrderContractResponse.status.statusCode));
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    this.setState({ successModal: true, successModalData: { branch: this.state.branches[this.state.selectedBranchIndex].name, commission: getAccountOrderContractResponse.fee, account: this.state.accounts[this.state.selectedAccountIndex].iban }, contract: getAccountOrderContractResponse?.contract })
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    getOrderFee = (selectedCurrencyIndex) => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let getAccountOrderFeeRequestBody = {
            currCode: this.state.currencies[selectedCurrencyIndex].currCode,
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }
        fetch(request("order/getAccountOrderFee", getAccountOrderFeeRequestBody))
            .then(res => res.text())
            .then((getAccountOrderFeeResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getAccountOrderFeeResponse = JSON.parse(getAccountOrderFeeResponseJSON);
                if (getAccountOrderFeeResponse.status.statusCode !== 1) {
                    if (getAccountOrderFeeResponse.status.statusCode === 200) {
                        this.props.logout();
                    }
                    // else {
                    //     this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getAccountOrderFeeResponse.status.statusCode));
                    // }
                }
                this.setState({ fee: getAccountOrderFeeResponse.fee ?? '' })
                this.props.updateAppState('isLoadingTransparentBackground', false);
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    closeSuccessFunction = () => {
        this.props.history.push('/');
    }

    componentDidMount() {
        this.getDropdownsData();
    }

    setAmountOutline = () => {
        if (!this.state.amount.length > 0) {
            this.setState({ amountOutline: true });
        } else {
            this.setState({ amountOutline: false });
        }
    };

    setSerialNumberOutline = () => {
        if (this.state.serialNumber.length < 7 ) {
            this.setState({ serialNumberOutline: true });
        } else {
            this.setState({ serialNumberOutline: false });
        }
    };

    updateFatchaAnswer = (questionId, answerId) => {
        const updatedAnswers = [...this.state.answers];
        const answerIndex = updatedAnswers.findIndex(item => item.questionId === questionId);

        if (answerIndex !== -1) {
            updatedAnswers[answerIndex].answerId = answerId;
            this.setState({ answers: updatedAnswers });
        }
    };

    downloadPdf = (file, filename) => {
        let arrrayBuffer = base64ToArrayBuffer(file); //data is the base64 encoded string
        let blob = new Blob([arrrayBuffer], { type: "application/pdf" });
        let link = window.URL.createObjectURL(blob);
        window.open(link, filename);
        let a = document.createElement("a");
        a.href = window.URL.createObjectURL(blob);
        a.download = filename || "filename.pdf"; // Set the desired filename
        a.click();
        window.URL.revokeObjectURL(a.href);
    }

    handleSign = () => {
        this.props.updateAppState('isLoadingTransparentBackground', true);

        let customerType = this.state.custType != 0 ? 'Individual' : 'Juridical';
        let certCode = customerType == 'Juridical' ? JSON.parse(localStorage.getItem('custObject')).company.certCode : JSON.parse(localStorage.getItem('custObject')).certCode

        let getSignFileRequestBody = {
            orderId: this.state.orderId,
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            langType: localStorage.getItem('i18nextLng').toUpperCase(),
            certCode: certCode,
            userId: localStorage.getItem('userId'),
            phoneNumber: localStorage.getItem('phoneNumber')
        }

        fetch(request("order/getAccountOrderSignFile", getSignFileRequestBody))
            .then(res => res.text())
            .then((getSignFileResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getSignFileResponse = JSON.parse(getSignFileResponseJSON);
                if (getSignFileResponse.status.statusCode !== 1) {
                    if (getSignFileResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getSignFileResponse.status.statusCode));
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    this.props.updateAppState('isLoadingTransparentBackground', false);

                    this.setState({ verificationOpen: true, verificationCode: getSignFileResponse.verificationCode });
                    let signFileRequestBody = {
                        token: localStorage.getItem('token'),
                        transactionId: getSignFileResponse.transactionId,
                        userId: localStorage.getItem('userId'),
                        orderId: this.state.orderId,
                        certCode: certCode,
                        phoneNumber: localStorage.getItem('phoneNumber')
                    }
                    fetch(request("order/signAccountOrderFile", signFileRequestBody))
                        .then(res => res.text())
                        .then((signDepositFileResponseJSON) => {
                            const JSON = require('true-json-bigint');
                            let signDepositFileResponse = JSON.parse(signDepositFileResponseJSON);
                            if (signDepositFileResponse.status.statusCode !== 1) {
                                if (signDepositFileResponse.status.statusCode === 200) {
                                    this.props.logout();
                                } else {
                                    this.props.updateAppState('isLoadingTransparentBackground', false);
                                    this.setState({ verificationOpen: false });
                                    this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(signDepositFileResponse.status.statusCode), '/dashboard/home');
                                }
                            } else {
                                this.props.updateAppState('isLoadingTransparentBackground', false);
                                this.setState({ verificationOpen: false, signSuccessOpen: true });
                            }
                        });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    confirmAll() {
        let { amount, selectedBranchIndex, selectedCurrencyIndex, selectedPurposes, selectedAccountIndex, chkAsan, chkDvx, serialNumber, selectedSerialIndex } = this.state;
        if (amount.length > 0 && serialNumber.length > 6 && selectedBranchIndex >= 0 && selectedPurposes.length > 0 && selectedAccountIndex >= 0 && selectedCurrencyIndex >= 0 && selectedSerialIndex >= 0 && chkAsan && (this.state.custType != 1 ? chkDvx : true)) {
            this.setState({ confirmedOperation: true })
        } else {
            this.setState({ confirmedOperation: false })
        }
    }

    onChangeAmount(val) {
        if (/^(\d+(\.\d{0,2})?|\.?\d{1,2})$/.test(val) || val.length == 0) {
            this.setState({ amount: val, amountOutline: val.length == 0 ? true : false }, () => this.confirmAll());
        }
    }

    onChangeSerialNumber(val) {
        if (/^[0-9]*$/.test(val) || val.length == 0) {
            this.setState({ serialNumber: val, serialNumberOutline: val.length < 7 ? true : false }, () => this.confirmAll());
        }
    }

    render() {
        let { t, history } = this.props;
        let { open, custType, popupType, title, feedbackMessage, step, answers, fatchaInfo, note, accounts, selectedAccountIndex, selectedAccountIndexCount, signSuccessOpen,
            dropdownVisibilityAccounts, chkAsan, chkDvx, fee, questions, chkContract, branches, contract, verificationOpen, verificationCode, successModal, successModalData,
            selectedBranchIndex, dropdownVisibilityBranches, dropdownVisibilityCurrencies, currencies, selectedCurrencyIndex, amount, confirmedOperation, amountOutline,
            selectedBranchIndexCount, selectedCurrencyIndexCount, selectedSerialIndexCount, selectedSerialIndex, dropdownVisibilitySerials, serials, serialNumberOutline, serialNumber, 
            purposes, selectedPurposes, selectedPurposesIsEmpty } = this.state;

        return (
            <>
                <AlertPopup open={open} closeFunction={() => this.setState({ open: false })} popupType={popupType} title={title} feedbackMessage={feedbackMessage} closeSuccessFunction={this.closeSuccessFunction} />
                <AsanVerification open={verificationOpen} verificationCode={verificationCode} />
                <FatchaInfoModal open={fatchaInfo} close={() => { this.setState({ fatchaInfo: false }) }} title={t("Please visit the branch to clarify certain questions regarding account opening.")} />
                <OrderSuccessModal custType={custType} closeFunction={() => custType != 0 ? this.setState({ successModal: false, step: 3 }) : history.push("/dashboard/finance/accounts")} data={successModalData} open={successModal} />
                <SimpleModal open={signSuccessOpen} close={() => this.setState({ signSuccessOpen: false }, history.push("/dashboard/home"))} title={t("The contract was successfully signed. Awaiting confirmation from the bank.")} />

                <div className="dashboard-header-route">
                    <div className="dashboard-header-route-left">
                        <button onClick={() => history.push("/dashboard/home")}>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.7344 21.2188L12.6719 20.3281C12.9062 20.0938 12.9062 19.7188 12.6719 19.5312L5.40625 12.2188H21.4375C21.7188 12.2188 22 11.9844 22 11.6562V10.3438C22 10.0625 21.7188 9.78125 21.4375 9.78125H5.40625L12.6719 2.51562C12.9062 2.32812 12.9062 1.95312 12.6719 1.71875L11.7344 0.828125C11.5469 0.59375 11.1719 0.59375 10.9375 0.828125L1.14062 10.625C0.90625 10.8594 0.90625 11.1875 1.14062 11.4219L10.9375 21.2188C11.1719 21.4531 11.5469 21.4531 11.7344 21.2188Z" fill="#2E3131" />
                            </svg>
                        </button>
                        <h2>{t('orders')}</h2>
                    </div>
                </div>
                <div className="dashboard-payment-container">
                    <div className={`dashboard-payment-container-data-section`} style={{ marginTop: '1px' }}>
                        <div className="dashboard-transfer-list-header">
                            <div className="dashboard-transfer-list-header-container dashboard-transfer-list-header-container__left">
                                <NavLink exact to="/dashboard/orders/credit" className="dashboard-transfer-list-header-link" activeClassName="dashboard-transfer-list-header-container dashboard-transfer-list-header-link__active">{t("credit")}</NavLink>
                                <NavLink exact to="/dashboard/orders/plastic-card" className="dashboard-transfer-list-header-link" activeClassName="dashboard-transfer-list-header-container dashboard-transfer-list-header-link__active">{t("Plastic card")}</NavLink>
                                {localStorage.getItem('checkResident') === "1" && localStorage.getItem("isAsan") === "true" &&
                                    <NavLink exact to="/dashboard/orders/account" className="dashboard-transfer-list-header-link" activeClassName="dashboard-transfer-list-header-container dashboard-transfer-list-header-link__active">{t("Account")}</NavLink>
                                }
                            </div>
                        </div>
                        <div className="dashboard-payment-container-data-section-content">
                            <div className="dashboard-payment-container-data-section-content-container">
                                {step === 1 &&
                                    <div className="dashboard-payment-container-data-section-content-container-form-container flex-column">
                                        <h3 className="dashboard-payment-container-data-section-content-container-title">{t('order info')}</h3>

                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('ID number')}</label>
                                            <div className="d-flex justify-content-between">
                                                <div className="w-50 mr-2">
                                                    <OutsideClickHandler onOutsideClick={() => { this.setState({ dropdownVisibilitySerials: false }) }}>
                                                        <div className={"dashboard-payment-container-data-section-content-container-form-select-box " + (selectedSerialIndexCount > 1 && selectedSerialIndex < 0 || selectedSerialIndexCount === 1 && selectedSerialIndex < 0 && !dropdownVisibilitySerials ? "input-outline" : "")}>
                                                            <button className={"dashboard-payment-container-data-section-content-container-form-select-box-button"} onClick={e => { e.preventDefault(); this.setState({ dropdownVisibilitySerials: !dropdownVisibilitySerials }); if (selectedSerialIndex < 0) this.setState({ selectedSerialIndexCount: ++this.state.selectedSerialIndexCount }) }}>
                                                                <div className="dashboard-payment-container-data-section-content-container-form-select" style={{ color: selectedSerialIndex < 0 && '#BDC3C7' }}>{selectedSerialIndex >= 0 ? serials[selectedSerialIndex] : t('Choose')}</div>
                                                                <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                                    <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                                    </svg>
                                                                </div>
                                                            </button>
                                                            {
                                                                serials.length !== 0 &&
                                                                <ul className={(dropdownVisibilitySerials === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                                    {
                                                                        serials.map((item, index) => (
                                                                            <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={index}>
                                                                                <button type="button" className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button" onClick={e => { e.preventDefault(); this.setState({ selectedSerialIndex: index, dropdownVisibilitySerials: false }, () => this.confirmAll()) }}>{item}</button>
                                                                            </li>
                                                                        ))
                                                                    }
                                                                </ul>
                                                            }
                                                        </div>
                                                    </OutsideClickHandler>
                                                </div>
                                                <div className="dashboard-payment-container-data-section-content-container-form-input-group">
                                                    <div className={"dashboard-payment-container-data-section-content-container-form-input-box " + (serialNumberOutline ? "input-outline" : "")}>
                                                        <input type="text" maxLength={8} value={serialNumber} onChange={e => this.onChangeSerialNumber(e.target.value)} placeholder='1234567' className="dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole" onBlur={this.setSerialNumberOutline} />
                                                    </div>
                                                </div>
                                            </div>
                                            <label className="dashboard-payment-container-data-section-content-container-form-label my-0" style={{ color: '#8E8E8E' }}>{t('If your ID information has changed, please provide the new ID number.')}</label>
                                        </div>

                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('branch')}</label>
                                            <OutsideClickHandler onOutsideClick={() => { this.setState({ dropdownVisibilityBranches: false }) }}>
                                                <div className={"dashboard-payment-container-data-section-content-container-form-select-box " + (selectedBranchIndexCount > 1 && selectedBranchIndex < 0 || selectedBranchIndexCount === 1 && selectedBranchIndex < 0 && !dropdownVisibilityBranches ? "input-outline" : "")}>
                                                    <button className={"dashboard-payment-container-data-section-content-container-form-select-box-button"} onClick={e => { e.preventDefault(); this.setState({ dropdownVisibilityBranches: !dropdownVisibilityBranches }); if (selectedBranchIndex < 0) this.setState({ selectedBranchIndexCount: ++this.state.selectedBranchIndexCount }) }}>
                                                        <div className="dashboard-payment-container-data-section-content-container-form-select" style={{ color: selectedBranchIndex < 0 && '#BDC3C7' }}>{selectedBranchIndex >= 0 ? branches[selectedBranchIndex].name : t('Choose')}</div>
                                                        <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                            <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                            </svg>
                                                        </div>
                                                    </button>
                                                    {
                                                        branches.length !== 0 &&
                                                        <ul className={(dropdownVisibilityBranches === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                            {
                                                                branches.map((branch, index) => (
                                                                    <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={index}>
                                                                        <button type="button" className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button" onClick={e => { e.preventDefault(); this.setState({ selectedBranchIndex: index, dropdownVisibilityBranches: false }, () => this.confirmAll()) }}>{branch.name}</button>
                                                                    </li>
                                                                ))
                                                            }
                                                        </ul>
                                                    }
                                                </div>
                                            </OutsideClickHandler>
                                        </div>

                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('currency')}</label>
                                            <OutsideClickHandler onOutsideClick={() => { this.setState({ dropdownVisibilityCurrencies: false }) }}>
                                                <div className={"dashboard-payment-container-data-section-content-container-form-select-box " + (selectedCurrencyIndexCount > 1 && selectedCurrencyIndex < 0 || selectedCurrencyIndexCount === 1 && selectedCurrencyIndex < 0 && !dropdownVisibilityCurrencies ? "input-outline" : "")}>
                                                    <button className={"dashboard-payment-container-data-section-content-container-form-select-box-button"} onClick={e => { e.preventDefault(); this.setState({ dropdownVisibilityCurrencies: !dropdownVisibilityCurrencies }); if (selectedCurrencyIndex < 0) this.setState({ selectedCurrencyIndexCount: ++this.state.selectedCurrencyIndexCount }) }}>
                                                        <div className="dashboard-payment-container-data-section-content-container-form-select" style={{ color: selectedCurrencyIndex < 0 && '#BDC3C7' }}>{selectedCurrencyIndex >= 0 ? currencies[selectedCurrencyIndex].shortName : t('Choose')}</div>
                                                        <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                            <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                            </svg>
                                                        </div>
                                                    </button>
                                                    {
                                                        currencies.length !== 0 &&
                                                        <ul className={(dropdownVisibilityCurrencies === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                            {
                                                                currencies.map((item, index) => (
                                                                    <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={index}>
                                                                        <button type="button" className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button" onClick={e => { e.preventDefault(); this.getOrderFee(index); this.setState({ selectedCurrencyIndex: index, dropdownVisibilityCurrencies: false }, () => this.confirmAll()) }}>{item.shortName}</button>
                                                                    </li>
                                                                ))
                                                            }
                                                        </ul>
                                                    }
                                                </div>
                                            </OutsideClickHandler>
                                        </div>

                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Purpose of use')}</label>
                                            <FormControl className='dashboard-payment-container-data-section-content-container-form-select-box-multiple' sx={{ width: "100%" }}>
                                                <Select
                                                    required
                                                    className={`dashboard-payment-container-data-section-content-container-form-select-box-multiple-select ${selectedPurposesIsEmpty ? "dashboard-payment-container-data-section-content-container-form-select-box-multiple-select-isEmpty" : ""}`}
                                                    multiple
                                                    displayEmpty
                                                    value={selectedPurposes}
                                                    onChange={(event) => this.setState({ selectedPurposes: event.target.value.includes('selectAll') ? (selectedPurposes.length === purposes.length ? [] : purposes) : event.target.value, selectedPurposesIsEmpty: event.target.value.length == 0 }, () => this.confirmAll())}
                                                    onBlur={() => this.setState({ selectedPurposesIsEmpty: selectedPurposes.length == 0 })}
                                                    input={<OutlinedInput />}
                                                    renderValue={(selected) => {
                                                        if (selected.length === 0) {
                                                            return <label>{t('Choose')}</label>
                                                        }
                                                        return selected.map(option => option.description).join(', ')
                                                    }
                                                    }
                                                    IconComponent={() => <KeyboardArrowDownOutlinedIcon color='disabled' className='mr-3' />}>
                                                    <MenuItem value="selectAll">
                                                        <Checkbox checked={selectedPurposes.length === purposes.length} />
                                                        <ListItemText primary={t('all')} />
                                                    </MenuItem>
                                                    {purposes.map((option) => (
                                                        <MenuItem key={option.id} value={option}>
                                                            <Checkbox checked={selectedPurposes.indexOf(option) > -1} />
                                                            <ListItemText primary={option.description} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>

                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Expected turnover')}</label>
                                            <div className={"dashboard-payment-container-data-section-content-container-form-input-box " + (amountOutline ? "input-outline" : "")}>
                                                <input type="text" value={amount} onChange={e => this.onChangeAmount(e.target.value)} placeholder='10 000.00' className="dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole" onBlur={this.setAmountOutline} />
                                            </div>
                                        </div>

                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Account purpose')}</label>
                                            <div className="dashboard-payment-container-data-section-content-container-form-select-box">
                                                <button disabled className="dashboard-payment-container-data-section-content-container-form-select-box-button">
                                                    <div className="dashboard-payment-container-data-section-content-container-form-select" style={{ color: '#BDC3C7' }}>{note}</div>
                                                    <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                        <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                        </svg>
                                                    </div>
                                                </button>
                                            </div>
                                        </div>

                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Account for service fees')}</label>
                                            <OutsideClickHandler onOutsideClick={() => { this.setState({ dropdownVisibilityAccounts: false }) }}>
                                                <div className={"dashboard-payment-container-data-section-content-container-form-select-box " + (selectedAccountIndexCount > 1 && selectedAccountIndex < 0 || selectedAccountIndexCount === 1 && selectedAccountIndex < 0 && !dropdownVisibilityAccounts ? "input-outline" : "")}>
                                                    <button className={"dashboard-payment-container-data-section-content-container-form-select-box-button"} onClick={e => { e.preventDefault(); this.setState({ dropdownVisibilityAccounts: !dropdownVisibilityAccounts }); if (selectedAccountIndex < 0) this.setState({ selectedAccountIndexCount: ++this.state.selectedAccountIndexCount }) }}>
                                                        <div className="dashboard-payment-container-data-section-content-container-form-select" style={{ color: selectedAccountIndex < 0 && '#BDC3C7' }}>{selectedAccountIndex >= 0 ? (accounts[selectedAccountIndex].iban + " / " + accounts[selectedAccountIndex].currentBalance + " " + accounts[selectedAccountIndex].currName) : t('Choose')}</div>
                                                        <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                            <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                            </svg>
                                                        </div>
                                                    </button>
                                                    {
                                                        accounts.length !== 0 &&
                                                        <ul className={(dropdownVisibilityAccounts === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                            {
                                                                accounts.map((item, index) => (
                                                                    <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={index}>
                                                                        <button type="button" className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button" onClick={e => { e.preventDefault(); this.setState({ selectedAccountIndex: index, dropdownVisibilityAccounts: false }, () => this.confirmAll()) }}>{item.iban} / {item.currentBalance} {item.currName}</button>
                                                                    </li>
                                                                ))
                                                            }
                                                        </ul>
                                                    }
                                                </div>
                                            </OutsideClickHandler>
                                        </div>

                                        {fee && <>
                                            <label className="dashboard-payment-container-data-section-content-container-form-label mb-0">{t('Service fee')}: <b>{fee}</b></label>
                                            <label className="dashboard-payment-container-data-section-content-container-form-label mt-0">
                                                <svg width="25" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8 0C12.4189 0 16 3.58113 16 8C16 12.4189 12.4189 16 8 16C3.58113 16 0 12.4189 0 8C0 3.58113 3.58113 0 8 0ZM5.8957 6.88411C5.88411 6.08278 7.71689 5.64238 8.54801 5.86258C10.6556 6.42053 7.60596 11.3228 8.13245 12.048C8.4255 12.452 9.75828 12.1341 9.75828 12.5C9.75828 12.8675 8.8543 13.8195 7.31457 13.5033C5.77318 13.1854 6.18874 12.1093 6.39735 11.2417C6.6043 10.3742 7.49669 7.97682 7.49669 7.56126C7.49669 6.25828 5.87583 7.22682 5.8957 6.88411ZM8.90066 2.43377C9.56623 2.43377 10.1043 2.97185 10.1043 3.63742C10.1043 4.30298 9.56623 4.84106 8.90066 4.84106C8.2351 4.84106 7.69702 4.30298 7.69702 3.63742C7.69702 2.97185 8.2351 2.43377 8.90066 2.43377Z" fill="#0179D2" />
                                                </svg>
                                                <i>{t('No service fee applies to the first order in each currency.')}</i>
                                            </label>
                                        </>}

                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid check">
                                            <input type="checkbox" id="chkAsan" onChange={() => this.setState({ chkAsan: !chkAsan }, () => this.confirmAll())} value={chkAsan} checked={chkAsan} style={{ width: '20px', height: '20px' }} />
                                            <label htmlFor="chkAsan" className="dashboard-payment-container-data-section-content-container-form-label">{t('I agree to the collection of my data by ASAN Finance')}</label>
                                        </div>
                                        {custType !== null && custType != 1 &&
                                            <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid check">
                                                <input type="checkbox" id="chkDvx" onChange={() => this.setState({ chkDvx: !chkDvx }, () => this.confirmAll())} value={chkDvx} checked={chkDvx} style={{ width: '20px', height: '20px' }} />
                                                <label htmlFor="chkDvx" className="dashboard-payment-container-data-section-content-container-form-label">{t('I agree to the collection of my data by DVX')}</label>
                                            </div>
                                        }
                                        <div className="dashboard-payment-container-data-section-content-container-form-footer">
                                            <button onClick={e => (custType !== null && custType != 1) ? this.getSignFileDVX() : this.createAccountOrder()} disabled={!confirmedOperation} className={`dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__blue dashboard-payment-container-data-section-content-container-form-footer-button__left ${!confirmedOperation && "dashboard-payment-container-data-section-content-container-form-footer-button__disable"}`}>{(custType !== null && custType != 1) ? t('Sign with Asan') : t('continue')}</button>
                                            <button onClick={e => { e.preventDefault(); this.props.history.goBack() }} className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__gray dashboard-payment-container-data-section-content-container-form-footer-button__right">{t('cancel')}</button>
                                        </div>
                                    </div>}

                                {step === 2 &&
                                    <div className="dashboard-payment-container-data-section-content-container-form-container flex-column">
                                        <h3 className="dashboard-payment-container-data-section-content-container-title">{t('FATCA')}</h3>
                                        {custType !== null && custType != 0 ? <>
                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Self-assessment questionnaire for determining the tax residency of natural persons')}</label>
                                            <ul className='p-0'>
                                                {questions && questions.length > 0 &&
                                                    questions.map((question, index) => {
                                                        return (
                                                            <li className='d-flex align-items-start my-3'>
                                                                <span className="font-weight-light">{index + 1}.</span>
                                                                <label className="dashboard-payment-container-data-section-content-container-form-label my-0 ml-3 mr-5 font-weight-light w-75">{question.question}</label>
                                                                {question.answers && question.answers.length > 0 && question.answers.map(answer => (
                                                                    <div className="dashboard-payment-container-data-section-content-container-form-group mr-3 align-items-center">
                                                                        <RadioButton
                                                                            value={answer.answerId}
                                                                            selectedId={answers[index].answerId}
                                                                            className="dashboard-payment-container-data-section-content-container-form-group-radio py-0"
                                                                            onChange={() => this.updateFatchaAnswer(question.questionId, answer.answerId)}
                                                                        />
                                                                        <label className="dashboard-payment-container-data-section-content-container-form-label m-0">{answer.answer}</label>
                                                                    </div>
                                                                ))}
                                                            </li>
                                                        )
                                                    })}
                                            </ul></>
                                            :
                                            <div className="dashboard-payment-container-data-section-content-container-form-container flex-column">
                                                {questions && questions.length > 0 &&
                                                    questions.map((question, index) => {
                                                        return (
                                                            <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                                <label className="dashboard-payment-container-data-section-content-container-form-label">{question.question}</label>
                                                                <Autocomplete
                                                                    disableClearable
                                                                    options={question.answers}
                                                                    getOptionLabel={(option) => option.answer}
                                                                    value={question.answers.find(item => item.answerId == answers[index].answerId)}
                                                                    onChange={(event, newValue) => this.updateFatchaAnswer(question.questionId, newValue.answerId)}
                                                                    sx={{ width: '100%' }}
                                                                    renderInput={(params) => <TextField {...params} label={t('Choose')} />}
                                                                />
                                                            </div>
                                                        )
                                                    })}

                                            </div>}
                                        <div className="dashboard-payment-container-data-section-content-container-form-footer">
                                            <button onClick={e => this.accountOrderContract()} className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__blue dashboard-payment-container-data-section-content-container-form-footer-button__left" >{t('continue')}</button>
                                            <button onClick={e => this.setState({ step: 1 })} className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__gray dashboard-payment-container-data-section-content-container-form-footer-button__right">{t('back')}</button>
                                        </div>
                                    </div>}

                                {step === 3 &&
                                    <div className="dashboard-payment-container-data-section-content-container-form-container flex-column">
                                        <h3 className="dashboard-payment-container-data-section-content-container-title">{t('Documents')}</h3>
                                        {contract && <>
                                            <div className="dashboard-payment-container-data-section-content-container-form-container-fatcha">
                                                <button onClick={() => { this.downloadPdf(contract, `${t('Contracts')}.pdf`) }}>
                                                    <img src={pdfImg} alt="contractImg" />
                                                    {t('Contracts')}
                                                </button>
                                            </div>
                                            <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid check mt-5">
                                                <input type="checkbox" id="chkContract" onChange={() => this.setState({ chkContract: !chkContract }, () => this.confirmAll())} value={chkContract} checked={chkContract} style={{ width: '20px', height: '20px' }} />
                                                <label htmlFor="chkContract" className="dashboard-payment-container-data-section-content-container-form-label">{t('I have read and agree to the contract')}</label>
                                            </div></>}
                                        <div className="dashboard-payment-container-data-section-content-container-form-footer">
                                            <button disabled={!chkContract} onClick={e => this.handleSign()} className={`dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__blue dashboard-payment-container-data-section-content-container-form-footer-button__left ${!chkContract && "dashboard-payment-container-data-section-content-container-form-footer-button__disable"}`} >{t('Sign')}</button>
                                            {/* <button onClick={e => this.setState({ step: 2 })} className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__gray dashboard-payment-container-data-section-content-container-form-footer-button__right">{t('back')}</button> */}
                                        </div>
                                    </div>}
                            </div>
                        </div >
                    </div>
                    <div className="dashboard-payment-container-confirmation-section">
                        <div className="dashboard-payment-container-confirmation-section-header">
                            <h3 className="dashboard-payment-container-confirmation-section-header-title">{t('What happens after you place your order')}</h3>
                        </div>
                        <div className="dashboard-payment-container-confirmation-section-content">
                            <img src={transferConfirmation} className="dashboard-payment-container-confirmation-section-content-image" />
                            <div className="dashboard-payment-container-confirmation-section-content-description">
                                <p>{t('order content')}</p>
                            </div>
                        </div>
                    </div>
                </div >
            </>
        );
    }
}

export default translate("translation")(PlasticCard);