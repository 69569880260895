import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { NavLink, Link } from 'react-router-dom';
import $ from 'jquery';
import StartDatePicker from './StartDatePicker/StartDatePicker';
import EndDatePicker from './EndDatePicker/EndDatePicker';
import Checkbox from '@mui/material/Checkbox';
import lastOperationsIcon from './../img/last-operations-icon.png';
import { request } from '../../../config';
import YesNoModal from "../../../copms/Modal/YesNoModal"
import AsanVerification from '../../../copms/Modal/AsanVerification';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
// import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
// import { request } from '../../../config';
// import AlertPopup from './../../../copms/AlertPopup/AlertPopup';
// import OutsideClickHandler from 'react-outside-click-handler';
// import transferConfirmation from './../img/transfer-confirmation.svg';
// import { statusCodes } from '../../../helpers/statusCodes';

function base64ToArrayBuffer(base64) {
    let binaryString = window.atob(base64);
    let binaryLen = binaryString.length;
    let bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
        let ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes;
}
function saveFile(name, type, data) {
    if (data !== null && navigator.msSaveBlob)
        return navigator.msSaveBlob(new Blob([data], { type: type }), name);
    var a = $("<a style='display: none;'/>");
    var url = window.URL.createObjectURL(new Blob([data], { type: type }));
    a.attr("href", url);
    a.attr("download", name);
    $("body").append(a);
    a[0].click();
    window.URL.revokeObjectURL(url);
    a.remove();
}

class History extends Component {
    state = {
        status: 1,
        startDate: null,
        endDate: null,
        currentPage: 1,
        lastPage: 0,
        applications: [],
        id: null,
        checkedItems: [],
        selectAllChecked: false,
        verificationOpen: false,
        verificationCode: null
    }

    downloadFile = (bytes) => {
        let arrrayBuffer = base64ToArrayBuffer(bytes); //data is the base64 encoded string
        saveFile(this.props.t('Template'), "application/xlsx", arrrayBuffer)
    }

    getBase64(file, cb) {
        if (file) {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                cb(reader.result)
            };
            reader.onerror = function (error) {

            };
        }
    }

    updateDate = (name, value) => {
        this.setState({ [name]: value });
    };

    getFormatedDate(date) {
        if (date) {
            let formatedDate = {
                day: (date.getDate() < 9) ? ('0' + date.getDate()) : date.getDate(),
                month: (date.getMonth() < 9) ? ('0' + (date.getMonth() + 1)) : (date.getMonth() + 1),
                year: date.getFullYear().toString()
            }
            return formatedDate.year + '-' + formatedDate.month + '-' + formatedDate.day;
        }
        return null;
    }

    selectPage(e, page) {
        e.preventDefault();
        this.setState({ currentPage: page }, () => this.getAuthorizationList())
    }

    componentDidMount() {
        this.getAuthorizationList();
    }

    // getPayload = () => {
    //     this.props.updateAppState('isLoadingTransparentBackground', true);
    //     let getBatchSalaryPayloadRequestBody = {
    //         token: localStorage.getItem('token')
    //     }
    //     fetch(request("salary/getBatchSalaryPayload", getBatchSalaryPayloadRequestBody))
    //         .then(res => res.text())
    //         .then((getBatchSalaryPayloadResponseJSON) => {
    //             const JSON = require('true-json-bigint');
    //             let getBatchSalaryPayloadResponse = JSON.parse(getBatchSalaryPayloadResponseJSON);
    //             if (getBatchSalaryPayloadResponse.status.statusCode != 1) {
    //                 if (getBatchSalaryPayloadResponse.status.statusCode === 200) {
    //                     this.props.logout();
    //                 } else {
    //                     this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getBatchSalaryPayloadResponse.status.statusCode));
    //                     this.props.updateAppState('isLoadingTransparentBackground', false);
    //                 }
    //             }
    //             else {
    //                 this.props.updateAppState('isLoadingTransparentBackground', false);
    //                 this.setState({ payload: getBatchSalaryPayloadResponse.payload }, () => this.getAuthorizationList());
    //             }
    //         },
    //             (error) => {
    //                 this.props.history.push("/technical-break");
    //             });
    // }

    getAuthorizationList = () => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let getSalaryApplicationsRequestBody = {
            token: localStorage.getItem('token'),
            beginDate: this.getFormatedDate(this.state.startDate),
            endDate: this.getFormatedDate(this.state.endDate),
            pageNumber: this.state.currentPage,
        }
        fetch(request("salary/getSalaryApplications", getSalaryApplicationsRequestBody))
            .then(res => res.text())
            .then((getSalaryApplicationsResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getSalaryApplicationsResponse = JSON.parse(getSalaryApplicationsResponseJSON);
                if (getSalaryApplicationsResponse.status.statusCode !== 1) {
                    if (getSalaryApplicationsResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getSalaryApplicationsResponse.status.statusCode));
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                    this.setState({
                        checkedItems: [], selectAllChecked: false,
                        lastPage: getSalaryApplicationsResponse.totalPage,
                        applications: getSalaryApplicationsResponse.applications || []
                    });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    getFile = (id, serviceId, fileType) => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let getBatchSalaryFileByIdRequestBody = {
            token: localStorage.getItem('token'),
            id, serviceId, fileType
        }
        fetch(request("salary/getBatchSalaryFileById", getBatchSalaryFileByIdRequestBody))
            .then(res => res.text())
            .then((getBatchSalaryFileByIdResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getBatchSalaryFileByIdResponse = JSON.parse(getBatchSalaryFileByIdResponseJSON);
                if (getBatchSalaryFileByIdResponse.status.statusCode !== 1) {
                    if (getBatchSalaryFileByIdResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getBatchSalaryFileByIdResponse.status.statusCode));
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    let arrrayBuffer = base64ToArrayBuffer(getBatchSalaryFileByIdResponse.file);
                    saveFile(getBatchSalaryFileByIdResponse.fileName, `application/${getBatchSalaryFileByIdResponse.fileName.substring(getBatchSalaryFileByIdResponse.fileName.lastIndexOf('.'))}`, arrrayBuffer)
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                    // this.setState({
                    //     lastPage: getSalaryApplicationsResponse.totalPage,
                    //     applications: getSalaryApplicationsResponse.applications
                    // });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    getSignFile = () => {
        this.props.updateAppState('isLoadingTransparentBackground', true);

        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let certCode = customerType == 'Juridical' ? JSON.parse(localStorage.getItem('custObject')).company.certCode : JSON.parse(localStorage.getItem('custObject')).certCode

        let getSignFileRequestBody = {
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            idList: this.state.checkedItems,
            certCode: certCode,
            userId: localStorage.getItem('userId'),
            phoneNumber: localStorage.getItem('phoneNumber')
        }

        fetch(request("salary/getSignFile", getSignFileRequestBody))
            .then(res => res.text())
            .then((getSignFileResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getSignFileResponse = JSON.parse(getSignFileResponseJSON);
                if (getSignFileResponse.status.statusCode !== 1) {
                    if (getSignFileResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getSignFileResponse.status.statusCode));
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                    this.setState({ verificationOpen: true, verificationCode: getSignFileResponse.verificationCode })
                    let signFileRequestBody = {
                        compId: localStorage.getItem('compId'),
                        token: localStorage.getItem('token'),
                        lang: localStorage.getItem('i18nextLng').toUpperCase(),
                        idList: this.state.checkedItems,
                        transactionId: getSignFileResponse.transactionId,
                        certCode: certCode,
                        userId: localStorage.getItem('userId'),
                        phoneNumber: localStorage.getItem('phoneNumber')
                    };
                    fetch(request("salary/signFile", signFileRequestBody))
                        .then(res => res.text())
                        .then((signFileResponseJSON) => {
                            const JSON = require('true-json-bigint');
                            let signFileResponse = JSON.parse(signFileResponseJSON);
                            if (signFileResponse.status.statusCode !== 1) {
                                if (signFileResponse.status.statusCode === 200) {
                                    this.props.logout();
                                } else {
                                    this.setState({
                                        verificationOpen: false,
                                    });
                                    this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(signFileResponse.status.statusCode));

                                }
                            } else {
                                this.setState({ verificationOpen: false });
                                this.props.alertState(true, 'info', this.props.t('successful operation'), this.props.t(signFileResponse.status.statusCode), null, null, this.getAuthorizationList());
                            }
                        });
                    // this.setState({
                    //     checkedItems: [], selectAllChecked: false,
                    //     lastPage: getSalaryApplicationsResponse.totalPage,
                    //     applications: getSalaryApplicationsResponse.applications || []
                    // });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        const id = parseInt(name, 10);

        this.setState(prevState => {
            let checkedItems = [...prevState.checkedItems];

            if (name === 'selectAll') {
                if (checked) {
                    checkedItems = prevState.applications.map(item => item.id);
                } else {
                    checkedItems = [];
                }
                return {
                    checkedItems,
                    selectAllChecked: checked,
                };
            } else {
                if (checked) {
                    if (!checkedItems.includes(id)) {
                        checkedItems.push(id);
                    }
                } else {
                    checkedItems = checkedItems.filter(itemId => itemId !== id);
                }
                const allChecked = prevState.applications.length === checkedItems.length;
                return {
                    checkedItems,
                    selectAllChecked: allChecked,
                };
            }
        });
    };

    render() {
        let { verificationOpen, verificationCode, applications, selectAllChecked, checkedItems } = this.state;
        let { t, alertState } = this.props;
        return (
            <>
                <AsanVerification open={verificationOpen} verificationCode={verificationCode} />
                <div className="dashboard-header-route">
                    <div className="dashboard-header-route-left">
                        <button onClick={() => this.props.history.goBack()}>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.7344 21.2188L12.6719 20.3281C12.9062 20.0938 12.9062 19.7188 12.6719 19.5312L5.40625 12.2188H21.4375C21.7188 12.2188 22 11.9844 22 11.6562V10.3438C22 10.0625 21.7188 9.78125 21.4375 9.78125H5.40625L12.6719 2.51562C12.9062 2.32812 12.9062 1.95312 12.6719 1.71875L11.7344 0.828125C11.5469 0.59375 11.1719 0.59375 10.9375 0.828125L1.14062 10.625C0.90625 10.8594 0.90625 11.1875 1.14062 11.4219L10.9375 21.2188C11.1719 21.4531 11.5469 21.4531 11.7344 21.2188Z" fill="#2E3131" />
                            </svg>
                        </button>
                        <h2>{t('orders')}</h2>
                    </div>
                </div>
                <div className="dashboard-salary-container">
                    <div className="dashboard-salary-container-data-section" style={{ marginTop: '1px' }}>
                        <div className="dashboard-transfer-list-header">
                            <div className="dashboard-transfer-list-header-container dashboard-transfer-list-header-container__left">
                                <NavLink exact to="/dashboard/salary-project/order" className="dashboard-transfer-list-header-link" activeClassName="dashboard-transfer-list-header-container dashboard-transfer-list-header-link__active">{t("Order")}</NavLink>
                                <NavLink exact to="/dashboard/salary-project/history" className="dashboard-transfer-list-header-link" activeClassName="dashboard-transfer-list-header-container dashboard-transfer-list-header-link__active">{t("history")}</NavLink>
                                <NavLink exact to="/dashboard/salary-project/authorization" className="dashboard-transfer-list-header-link" activeClassName="dashboard-transfer-list-header-container dashboard-transfer-list-header-link__active">{t("authorization")}</NavLink>
                                <NavLink exact to="/dashboard/salary-project/list" className="dashboard-transfer-list-header-link" activeClassName="dashboard-transfer-list-header-container dashboard-transfer-list-header-link__active">{t("List of active salary cards")}</NavLink>
                            </div>
                        </div>
                        <div className="dashboard-salary-order-container">
                            <div className="dashboard-salary-order-container-box">
                                {/* <div className="dashboard-salary-order-container-box-select dashboard-salary-order-container-box-select-filter">
                                    <label>{t("Əməliyyatın növü")}</label>
                                    <FormControl className='dashboard-payment-container-data-section-content-container-form-select-box-multiple' sx={{ width: "100%" }}>
                                        <Select
                                            required
                                            className={`dashboard-payment-container-data-section-content-container-form-select-box-multiple-select`}
                                            multiple
                                            displayEmpty
                                            value={formData.services}
                                            onChange={(event) => this.setValues(event.target.value.includes('selectAll') ? (formData.services.length === payload.services.length ? [] : payload.services) : event.target.value, "services")}
                                            input={<OutlinedInput />}
                                            renderValue={(selected) => {
                                                if (formData.services.length === payload.services.length)
                                                    return <p>{t('all')}</p>
                                                return selected.map(option => option.description).join(', ')
                                            }
                                            }
                                            IconComponent={() => <KeyboardArrowDownOutlinedIcon color='disabled' className='mr-3' />}>
                                            <MenuItem value="selectAll">
                                                <Checkbox defaultChecked checked={formData.services.length === payload.services.length} />
                                                <ListItemText primary={t('all')} />
                                            </MenuItem>
                                            {payload.services.map((option) => (
                                                <MenuItem key={option.id} value={option}>
                                                    <Checkbox checked={formData.services.indexOf(option) > -1} />
                                                    <ListItemText primary={option.description} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div> */}
                                <div className="dashboard-salary-order-container-box-select w-100">
                                    <label>{t("By date")}</label>
                                    <div className="dashboard-salary-order-container-box-select-datepicker">
                                        <div className="dashboard-salary-order-container-box-select-datepicker-input">
                                            <StartDatePicker placeholder={t("Start date")} value={this.state.startDate} updateDate={this.updateDate} />
                                        </div>
                                        <div className="dashboard-salary-order-container-box-select-datepicker-input">
                                            <EndDatePicker placeholder={t("End date")} value={this.state.endDate} updateDate={this.updateDate} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="dashboard-salary-order-container-box justify-content-end">
                                <button className="dashboard-salary-order-container-box-button dashboard-salary-order-container-box-button-main" onClick={this.getAuthorizationList}>{t("Search")}</button>
                                <button className="dashboard-salary-order-container-box-button dashboard-salary-order-container-box-button-outline" onClick={() => this.setState({ startDate: null, endDate: null })}>{t("Clear")}</button>
                                <button className="dashboard-salary-order-container-box-button dashboard-salary-order-container-box-button-main" disabled={checkedItems.length == 0} onClick={()=> localStorage.getItem("isAsan") ==="true" ? this.getSignFile() : alertState(true, 'warning', null, t('log in with Asan Imza'))}>{t("Sign with Asan")}</button>
                                {/* <button className="dashboard-salary-order-container-box-button dashboard-salary-order-container-box-button-outline">
                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                                    <path d="M18 12.5V13.5C18 14.9001 18 15.6002 17.7275 16.135C17.4878 16.6054 17.1054 16.9878 16.635 17.2275C16.1002 17.5 15.4001 17.5 14 17.5H7C5.59987 17.5 4.8998 17.5 4.36502 17.2275C3.89462 16.9878 3.51217 16.6054 3.27248 16.135C3 15.6002 3 14.9001 3 13.5V12.5M14.6667 8.33333L10.5 12.5M10.5 12.5L6.33333 8.33333M10.5 12.5V2.5" stroke="#0179D2" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                {t("download")}
                            </button> */}
                            </div>
                            {applications.length === 0 ?
                                <div className="dashboard-last-operations-section-content" style={{ minHeight: '70vh', justifyContent: 'center' }}>
                                    <img src={lastOperationsIcon} className="dashboard-last-operations-section-content-image" alt="lastOperationsIcon" />
                                    <p className="dashboard-last-operations-section-content-status">{t('you do not currently have any active transactions')}</p>
                                </div>
                                : <>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>
                                                    <Checkbox
                                                        name="selectAll"
                                                        checked={selectAllChecked}
                                                        onChange={this.handleCheckboxChange}
                                                        inputProps={{ "aria-label": "controlled" }}
                                                        sx={{
                                                            color: "#DADADA",
                                                            "&.Mui-checked": {
                                                                color: "#0179D2",
                                                            },
                                                        }}
                                                    />
                                                </th>
                                                <th>{t("date")}</th>
                                                <th>{t("Type of operation")}</th>
                                                <th>{t("Number of cards")}</th>
                                                {applications.some(item => item.amount) && <th>{t("Service fee")}</th>}
                                                {applications.some(item => item.currency) && <th>{t("currency")}</th>}
                                                <th>{t("Document")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {applications.length !== 0 && applications.map(item => (
                                                <tr>
                                                    <td>
                                                        <Checkbox
                                                            checked={checkedItems.includes(item.id)}
                                                            onChange={this.handleCheckboxChange}
                                                            name={item.id.toString()}
                                                            inputProps={{ "aria-label": "controlled" }}
                                                            sx={{
                                                                color: "#DADADA",
                                                                "&.Mui-checked": {
                                                                    color: "#0179D2",
                                                                },
                                                            }}
                                                        />
                                                    </td>
                                                    <td>{item.operationDate}</td>
                                                    <td>{item.operationName}</td>
                                                    <td>{item.cardCount}</td>
                                                    {applications.some(item => item.amount) &&
                                                        <td>
                                                            {item?.amount}
                                                        </td>
                                                    }
                                                    {applications.some(item => item.currency) &&
                                                        <td>
                                                            {item?.currency}
                                                        </td>
                                                    }
                                                    <td>
                                                        <button onClick={() => this.getFile(item.id, item.serviceId, item.application)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                <path d="M2.70039 2.4C2.70039 1.2402 3.64059 0.3 4.80039 0.3H14.2761L21.3004 7.32426V21.6C21.3004 22.7598 20.3602 23.7 19.2004 23.7H4.80039C3.64059 23.7 2.70039 22.7598 2.70039 21.6V2.4Z" stroke="#344054" stroke-width="0.6" />
                                                                <path d="M14.4004 0L21.6004 7.2H16.8004C15.4749 7.2 14.4004 6.12548 14.4004 4.8V0Z" fill="#344054" />
                                                                <path d="M15.0449 15.1168C14.4461 15.1168 13.6966 15.2212 13.4514 15.258C12.4367 14.1983 12.1479 13.5959 12.083 13.4353C12.171 13.2092 12.4772 12.3502 12.5207 11.2469C12.5422 10.6946 12.4255 10.2818 12.1738 10.0202C11.9226 9.75896 11.6185 9.73865 11.5313 9.73865C11.2255 9.73865 10.7126 9.89327 10.7126 10.9287C10.7126 11.8271 11.1314 12.7804 11.2472 13.0267C10.6372 14.803 9.98224 16.0189 9.84317 16.269C7.39189 17.1919 7.2002 18.0843 7.2002 18.3372C7.2002 18.7917 7.52387 19.063 8.06602 19.063C9.38324 19.063 10.5853 16.8516 10.784 16.4676C11.7196 16.0948 12.9717 15.8639 13.29 15.8088C14.2031 16.6786 15.2591 16.9107 15.6976 16.9107C16.0275 16.9107 16.8002 16.9107 16.8002 16.1162C16.8002 15.3785 15.8547 15.1168 15.0449 15.1168ZM14.9814 15.6383C15.6929 15.6383 15.8809 15.8736 15.8809 15.998C15.8809 16.0761 15.8513 16.3308 15.4699 16.3308C15.128 16.3308 14.5375 16.1331 13.9566 15.7043C14.1989 15.6725 14.5574 15.6383 14.9814 15.6383ZM11.4939 10.2442C11.5588 10.2442 11.6015 10.265 11.6367 10.3138C11.8414 10.5978 11.6764 11.5256 11.4755 12.2516C11.2817 11.6291 11.1362 10.6738 11.3409 10.3378C11.3809 10.2722 11.4266 10.2442 11.4939 10.2442ZM11.1484 15.8014C11.406 15.281 11.6947 14.5226 11.852 14.0937C12.1666 14.6204 12.5899 15.1095 12.8347 15.3746C12.0725 15.5353 11.4958 15.6958 11.1484 15.8014ZM7.71188 18.4066C7.69491 18.3865 7.6924 18.3441 7.70519 18.2931C7.732 18.1864 7.93695 17.6573 9.41928 16.9942C9.20703 17.3285 8.87521 17.8062 8.51069 18.1631C8.25409 18.4033 8.05429 18.5251 7.91682 18.5251C7.86764 18.5251 7.79989 18.5117 7.71188 18.4066Z" fill="#344054" />
                                                            </svg>
                                                        </button>
                                                    </td>
                                                    {/* <td>
                                                        <button>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                                <g clip-path="url(#clip0_4_6361)">
                                                                    <path d="M14.0003 14H8.66701M1.66699 14.3334L5.36651 12.9105C5.60313 12.8195 5.72145 12.774 5.83214 12.7146C5.93046 12.6618 6.02419 12.6009 6.11235 12.5324C6.21161 12.4554 6.30124 12.3658 6.48051 12.1865L14.0003 4.66671C14.7367 3.93033 14.7367 2.73642 14.0003 2.00004C13.264 1.26366 12.0701 1.26366 11.3337 2.00004L3.81385 9.51985C3.63458 9.69912 3.54494 9.78876 3.46792 9.88801C3.39951 9.97617 3.33859 10.0699 3.28582 10.1682C3.2264 10.2789 3.18089 10.3972 3.08988 10.6339L1.66699 14.3334ZM1.66699 14.3334L3.03907 10.766C3.13726 10.5107 3.18635 10.3831 3.27055 10.3246C3.34414 10.2735 3.43519 10.2542 3.52319 10.271C3.62388 10.2902 3.72058 10.3869 3.91398 10.5803L5.42004 12.0864C5.61344 12.2798 5.71014 12.3765 5.72937 12.4772C5.74617 12.5652 5.72685 12.6562 5.67576 12.7298C5.61729 12.814 5.48965 12.8631 5.23437 12.9613L1.66699 14.3334Z" stroke="#667085" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                                </g>

                                                            </svg>
                                                        </button>

                                                    </td> */}
                                                    {/* <td>
                                                        <button className='button-close'>
                                                            Bağlı
                                                        </button>
                                                        <button className="button-open">
                                                            Açıq
                                                        </button>
                                                    </td> */}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <div style={{ width: '100%', marginTop: '15px', marginBottom: '15px', display: 'flex', justifyContent: 'center' }}>
                                        <ul className="dashboard-pagination">
                                            {this.state.currentPage != 1 && <li><a href="#" onClick={(e) => this.selectPage(e, this.state.currentPage - 1)}>{t('previous-page')}</a></li>}
                                            {
                                                Array.from({ length: this.state.lastPage }).map((item, index) => {
                                                    if (this.state.lastPage > 3) {
                                                        if (this.state.currentPage - 1 == index + 1) {
                                                            return (
                                                                <>
                                                                    {this.state.currentPage > 2 && <li><a href="#" onClick={(e) => this.selectPage(e, 1)}>1</a></li>}

                                                                    {this.state.currentPage > 3 && <li><a href="#" style={{ cursor: 'default' }} onClick={(e) => { e.preventDefault(); }}>...</a></li>}
                                                                    <li><a href="#" onClick={(e) => this.selectPage(e, index + 1)}>{index + 1}</a></li>
                                                                </>
                                                            )
                                                        }
                                                        if (this.state.currentPage == index + 1) {
                                                            return (
                                                                <>
                                                                    <li className="active" ><a href="#" style={{ cursor: 'default' }} onClick={e => e.preventDefault()}>{index + 1}</a></li>
                                                                </>
                                                            )
                                                        }
                                                        if (this.state.currentPage + 1 == index + 1) {
                                                            return (
                                                                <>
                                                                    <li><a href="#" onClick={(e) => this.selectPage(e, index + 1)}>{index + 1}</a></li>
                                                                    {this.state.currentPage < this.state.lastPage - 2 && <li ><a href="#" style={{ cursor: 'default' }} onClick={(e) => { e.preventDefault(); }}>...</a></li>}

                                                                    {this.state.currentPage + 1 < this.state.lastPage && <li ><a href="#" onClick={(e) => this.selectPage(e, this.state.lastPage)}>{this.state.lastPage}</a></li>}
                                                                </>
                                                            )
                                                        }
                                                    } else {
                                                        return (
                                                            <li className={index + 1 == this.state.currentPage ? "active" : ""}><a href="#" style={this.state.lastPage == 1 ? { borderRadius: '5px' } : {}} onClick={(e) => this.selectPage(e, index + 1)}>{index + 1}</a></li>
                                                        )
                                                    }
                                                })

                                            }
                                            {this.state.currentPage != this.state.lastPage && this.state.lastPage > 1 && <li><a href="#" onClick={(e) => this.selectPage(e, this.state.currentPage + 1)}>{t('next-page')}</a></li>}
                                        </ul>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default translate("translation")(History);