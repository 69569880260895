import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { request } from '../../../config';
import AlertPopup from './../../../copms/AlertPopup/AlertPopup';
import OutsideClickHandler from 'react-outside-click-handler';
import transferConfirmation from './../img/transfer-confirmation.svg';
import { statusCodes } from '../../../helpers/statusCodes';

class Credit extends Component {
    state = {
        branchs: [],
        selectedBranchIndex: -1,
        dropdownVisibilityBranchs: false,
        creditTime: [],
        selectedCreditTimeIndex: -1,
        dropdownVisibilityCreditTime: false,
        creditType: [],
        selectedCreditTypeIndex: -1,
        dropdownVisibilityCreditType: false,
        exchange: [],
        selectedExchangeIndex: -1,
        dropdownVisibilityExchange: false,
        open: false,
        amount: '',
        confirmedOperation: false,

        amountOutline: false,
        selectedBranchIndexCount: 0,
        selectedCreditTimeIndexCount: 0,
        selectedCreditTypeIndexCount: 0,
        selectedExchangeIndexCount: 0
    }

    componentDidMount() {
        this.branchList();
    }

    async branchList() {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getBranchMapListRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }

        let getOrderKindTypeListRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            orderType: 1,
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            orderType: 1,
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }

        await fetch(request("util/getBranchMapList/" + localStorage.getItem('i18nextLng').toUpperCase(), getBranchMapListRequestBody))
            .then(res => res.text())
            .then((getBranchMapListrResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getBranchMapListrResponse = JSON.parse(getBranchMapListrResponseJSON);
                if (getBranchMapListrResponse.status.statusCode !== 1) {
                    if (getBranchMapListrResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getBranchMapListrResponse.status.statusCode));
                    }
                }
                else {
                    this.setState({ branchs: getBranchMapListrResponse.respBranchMapList });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });

        await fetch(request("order/getOrderKindList", getOrderKindTypeListRequestBody))
            .then(res => res.text())
            .then((getOrderKindTypeListResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getOrderKindTypeList = JSON.parse(getOrderKindTypeListResponseJSON);
                if (getOrderKindTypeList.status.statusCode !== 1) {
                    if (getOrderKindTypeList.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        getOrderKindTypeList.status.statusCode === statusCodes.ACCESS_DENIED_OPERATION ?
                            this.props.alertState(
                                true,
                                "warning",
                                this.props.t("Information"),
                                this.props.t(getOrderKindTypeList.status.statusCode),
                                null,
                                true
                            ) :
                            this.props.alertState(
                                true,
                                'error',
                                this.props.t('an error occurred'),
                                this.props.t(getOrderKindTypeList.status.statusCode),
                            )
                    }
                }
                else {
                    this.setState({ creditType: getOrderKindTypeList.respOrderTypeList });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
        await fetch(request("order/getOrderPeriodList", getOrderKindTypeListRequestBody))
            .then(res => res.text())
            .then((getOrderKindTypeListResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getOrderKindTypeList = JSON.parse(getOrderKindTypeListResponseJSON);
                if (getOrderKindTypeList.status.statusCode !== 1) {
                    if (getOrderKindTypeList.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        getOrderKindTypeList.status.statusCode === statusCodes.ACCESS_DENIED_OPERATION ?
                            this.props.alertState(
                                "warning",
                                this.props.t("Information"),
                                this.props.t(getOrderKindTypeList.status.statusCode),
                                null,
                                true
                            ) :
                            this.props.alertState(
                                true, 'error',
                                this.props.t('an error occurred'),
                                this.props.t(getOrderKindTypeList.status.statusCode),
                            )
                    }
                }
                else {
                    this.setState({ creditTime: getOrderKindTypeList.respOrderPeriodList });
                }

                this.props.updateAppState('isLoadingTransparentBackground', false);
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
        let exchangeRequestBody = {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            type: 0
        }
        if (localStorage.getItem('compId')) {
            exchangeRequestBody.compId = localStorage.getItem('compId');
        }
        await fetch(request("exchange/cashlessCurrency/", exchangeRequestBody))
            .then(res => res.text())
            .then((exchangeResponseJSON) => {
                const JSON = require('true-json-bigint');
                let exchangeResponse = JSON.parse(exchangeResponseJSON);
                if (exchangeResponse.status.statusCode !== 1 && exchangeResponse.status.statusCode !== 168) {
                    if (exchangeResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(exchangeResponse.status.statusCode));
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    if (exchangeResponse.exchangeCashlessList.findIndex((item, index) => item.currency === 'AZN') === -1) {
                        exchangeResponse.exchangeCashlessList.push({ currency: 'AZN' });
                    }
                    exchangeResponse.exchangeCashlessList.sort((prev, next) => {
                        return (prev.currency < next.currency) ? -1 : (prev.currency > next.currency) ? 1 : 0;
                    })
                    this.setState({ exchange: exchangeResponse.exchangeCashlessList });
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
        // await fetch(request("exchange/cash", {}, "GET"))
        //     .then(res => res.text())
        //     .then((cashlessResponseJSON) => {
        //         const JSON = require('true-json-bigint');
        //         let cashlessResponse = JSON.parse(cashlessResponseJSON);
        //         let exchangeList = [];
        //         exchangeList.push({ currency: 'AZN' });
        //         if(cashlessResponse.exchangeCashList){cashlessResponse.exchangeCashList.map((item) => exchangeList.push(item));}
        //         this.setState({ exchange: exchangeList })
        //     })

        this.props.updateAppState('isLoadingTransparentBackground', false);
    }

    onChangeAmount(val) {
        if (/^\d*\.{0,2}?\d*$/.test(val) || val.length == 0) {
            this.setState({ amount: val }, () => this.confirmAll());
        }
    }


    confirmAll() {
        let { amount, selectedBranchIndex, selectedCreditTimeIndex, selectedCreditTypeIndex, selectedExchangeIndex } = this.state;
        if (amount.length > 0 && selectedBranchIndex >= 0 && selectedCreditTimeIndex >= 0 && selectedCreditTypeIndex >= 0 && selectedExchangeIndex >= 0) {
            this.setState({ confirmedOperation: true })
        } else {
            this.setState({ confirmedOperation: false })
        }
        if (amount.length > 0) {
            this.setState({ amountOutline: false });
        }

    }

    onSubmit(e) {
        e.preventDefault();
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let createOrderRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            orderType: 1,
            branchId: this.state.branchs[this.state.selectedBranchIndex].branchId,
            kindId: this.state.creditType[this.state.selectedCreditTypeIndex].id,
            periodId: this.state.creditTime[this.state.selectedCreditTimeIndex].id,
            ccy: this.state.exchange[this.state.selectedExchangeIndex].currency,
            amount: this.state.amount,
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            orderType: 1,
            branchId: this.state.branchs[this.state.selectedBranchIndex].branchId,
            kindId: this.state.creditType[this.state.selectedCreditTypeIndex].id,
            periodId: this.state.creditTime[this.state.selectedCreditTimeIndex].id,
            ccy: this.state.exchange[this.state.selectedExchangeIndex].currency,
            amount: this.state.amount,
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }

        fetch(request("order/createOrder" + customerType, createOrderRequestBody))
            .then(res => res.text())
            .then((createOrderResponseJSON) => {
                const JSON = require('true-json-bigint');
                let createOrderResponse = JSON.parse(createOrderResponseJSON);
                if (createOrderResponse.status.statusCode !== 1) {
                    if (createOrderResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.setState({
                            open: true,
                            popupType: 'error',
                            title: this.props.t('an error occurred'),
                            feedbackMessage: this.props.t(createOrderResponse.status.statusCode)
                        });
                    }
                }
                else {
                    this.setState({
                        open: true,
                        popupType: 'success',
                        title: this.props.t('successful operation'),
                        feedbackMessage: this.props.t('Your order has been sent successfully')
                    });
                }

                this.props.updateAppState('isLoadingTransparentBackground', false);
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }
    closeSuccessFunction = () => {
        this.props.history.push('/');

        // this.setState({
        //     selectedBranchIndex: -1,
        //     dropdownVisibilityBranchs: false,
        //     selectedCreditTimeIndex: -1,
        //     dropdownVisibilityCreditTime: false,
        //     selectedCreditTypeIndex: -1,
        //     dropdownVisibilityCreditType: false,
        //     selectedExchangeIndex: -1,
        //     dropdownVisibilityExchange: false,
        //     amount: '',
        //     confirmedOperation: false,
        // });
    }
    setAmountOutline = () => {
        if (!this.state.amount.length > 0) {
            this.setState({ amountOutline: true });
        }
        else {
            this.setState({ amountOutline: false });
        }
    }
    render() {
        let { branchs, selectedBranchIndex, dropdownVisibilityBranchs, creditType, open, title, popupType, feedbackMessage, dropdownVisibilityCreditType, selectedCreditTypeIndex, dropdownVisibilityCreditTime, dropdownVisibilityExchange, selectedCreditTimeIndex, creditTime, exchange, selectedExchangeIndex, amount, confirmedOperation, amountOutline, selectedBranchIndexCount, selectedCreditTimeIndexCount, selectedCreditTypeIndexCount, selectedExchangeIndexCount } = this.state;
        let { t } = this.props;
        return (
            <>
                <AlertPopup open={open} closeFunction={() => this.setState({ open: false })} popupType={popupType} title={title} feedbackMessage={feedbackMessage} closeSuccessFunction={this.closeSuccessFunction} />
                <div className="dashboard-header-route">
                    <div className="dashboard-header-route-left">
                        <button onClick={() => this.props.history.goBack()}>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.7344 21.2188L12.6719 20.3281C12.9062 20.0938 12.9062 19.7188 12.6719 19.5312L5.40625 12.2188H21.4375C21.7188 12.2188 22 11.9844 22 11.6562V10.3438C22 10.0625 21.7188 9.78125 21.4375 9.78125H5.40625L12.6719 2.51562C12.9062 2.32812 12.9062 1.95312 12.6719 1.71875L11.7344 0.828125C11.5469 0.59375 11.1719 0.59375 10.9375 0.828125L1.14062 10.625C0.90625 10.8594 0.90625 11.1875 1.14062 11.4219L10.9375 21.2188C11.1719 21.4531 11.5469 21.4531 11.7344 21.2188Z" fill="#2E3131" />
                            </svg>
                        </button>
                        <h2>{t('orders')}</h2>
                    </div>
                </div>
                <div className="dashboard-payment-container">
                    <div className="dashboard-payment-container-data-section" style={{ marginTop: '1px' }}>
                        <div className="dashboard-transfer-list-header">
                            <div className="dashboard-transfer-list-header-container dashboard-transfer-list-header-container__left">
                                <NavLink exact to="/dashboard/orders/credit" className="dashboard-transfer-list-header-link" activeClassName="dashboard-transfer-list-header-container dashboard-transfer-list-header-link__active">{t("credit")}</NavLink>
                                <NavLink exact to="/dashboard/orders/plastic-card" className="dashboard-transfer-list-header-link" activeClassName="dashboard-transfer-list-header-container dashboard-transfer-list-header-link__active">{t("Plastic card")}</NavLink>
                                {localStorage.getItem('checkResident') === "1" && localStorage.getItem("isAsan") === "true" &&
                                    <NavLink exact to="/dashboard/orders/account" className="dashboard-transfer-list-header-link" activeClassName="dashboard-transfer-list-header-container dashboard-transfer-list-header-link__active">{t("Account")}</NavLink>
                                }
                            </div>
                        </div>
                        <form className="dashboard-payment-container-data-section-content" onSubmit={e => this.onSubmit(e)}>
                            <div className="dashboard-payment-container-data-section-content-container">
                                <h3 className="dashboard-payment-container-data-section-content-container-title">{t('order info')}</h3>
                                <div className="dashboard-payment-container-data-section-content-container-form">
                                    <div className="dashboard-payment-container-data-section-content-container-form-container">
                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('branch')} <span style={{ color: 'red' }}>*</span></label>
                                            <OutsideClickHandler onOutsideClick={() => { this.setState({ dropdownVisibilityBranchs: false }) }}>
                                                <div className={"dashboard-payment-container-data-section-content-container-form-select-box " + (selectedBranchIndexCount > 1 && selectedBranchIndex < 0 || selectedBranchIndexCount === 1 && selectedBranchIndex < 0 && !dropdownVisibilityBranchs ? "input-outline" : "")}>
                                                    <button className={"dashboard-payment-container-data-section-content-container-form-select-box-button"} onClick={e => { e.preventDefault(); this.setState({ dropdownVisibilityBranchs: !dropdownVisibilityBranchs }); if (selectedBranchIndex < 0) this.setState({ selectedBranchIndexCount: ++this.state.selectedBranchIndexCount }) }}>
                                                        <div className="dashboard-payment-container-data-section-content-container-form-select" style={{ color: selectedBranchIndex < 0 && '#BDC3C7' }}>{selectedBranchIndex >= 0 ? branchs[selectedBranchIndex].branchName : t('Choose')}</div>
                                                        <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                            <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                            </svg>
                                                        </div>
                                                    </button>
                                                    {
                                                        branchs.length !== 0 &&
                                                        <ul className={(dropdownVisibilityBranchs === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                            {
                                                                branchs.map((branch, index) => (
                                                                    <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={index}>
                                                                        <button type="button" className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button" onClick={e => { e.preventDefault(); this.setState({ selectedBranchIndex: index, dropdownVisibilityBranchs: false }, () => this.confirmAll()) }}>{branch.branchName}</button>
                                                                    </li>
                                                                ))
                                                            }
                                                        </ul>
                                                    }
                                                </div>
                                            </OutsideClickHandler>
                                        </div>

                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Loan term')} <span style={{ color: 'red' }}>*</span></label>
                                            <OutsideClickHandler onOutsideClick={() => { this.setState({ dropdownVisibilityCreditTime: false }) }}>
                                                <div className={"dashboard-payment-container-data-section-content-container-form-select-box " + (selectedCreditTimeIndexCount > 1 && selectedCreditTimeIndex < 0 || selectedCreditTimeIndexCount === 1 && selectedCreditTimeIndex < 0 && !dropdownVisibilityCreditTime ? "input-outline" : "")}>
                                                    <button className={"dashboard-payment-container-data-section-content-container-form-select-box-button"} onClick={e => { e.preventDefault(); this.setState({ dropdownVisibilityCreditTime: !dropdownVisibilityCreditTime }); if (selectedCreditTimeIndex < 0) this.setState({ selectedCreditTimeIndexCount: ++this.state.selectedCreditTimeIndexCount }) }}>
                                                        <div className="dashboard-payment-container-data-section-content-container-form-select" style={{ color: selectedCreditTimeIndex < 0 && '#BDC3C7' }}>{selectedCreditTimeIndex >= 0 ? creditTime[selectedCreditTimeIndex].name : t('Choose')}</div>
                                                        <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                            <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                            </svg>
                                                        </div>
                                                    </button>
                                                    {
                                                        creditTime.length !== 0 &&
                                                        <ul className={(dropdownVisibilityCreditTime === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                            {
                                                                creditTime.map((branch, index) => (
                                                                    <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={index}>
                                                                        <button type="button" className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button" onClick={e => { e.preventDefault(); this.setState({ selectedCreditTimeIndex: index, dropdownVisibilityCreditTime: false }, () => this.confirmAll()) }}>{branch.name}</button>
                                                                    </li>
                                                                ))
                                                            }
                                                        </ul>
                                                    }
                                                </div>
                                            </OutsideClickHandler>
                                        </div>

                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Type of loan')} <span style={{ color: 'red' }}>*</span></label>
                                            <OutsideClickHandler onOutsideClick={() => { this.setState({ dropdownVisibilityCreditType: false }) }}>
                                                <div className={"dashboard-payment-container-data-section-content-container-form-select-box " + (selectedCreditTypeIndexCount > 1 && selectedCreditTypeIndex < 0 || selectedCreditTypeIndexCount === 1 && selectedCreditTypeIndex < 0 && !dropdownVisibilityCreditType ? "input-outline" : "")}>
                                                    <button className={"dashboard-payment-container-data-section-content-container-form-select-box-button"} onClick={e => { e.preventDefault(); this.setState({ dropdownVisibilityCreditType: !dropdownVisibilityCreditType }); if (selectedCreditTypeIndex < 0) this.setState({ selectedCreditTypeIndexCount: ++this.state.selectedCreditTypeIndexCount }) }}>
                                                        <div className="dashboard-payment-container-data-section-content-container-form-select" style={{ color: selectedCreditTypeIndex < 0 && '#BDC3C7' }}>{selectedCreditTypeIndex >= 0 ? creditType[selectedCreditTypeIndex].name : t('Choose')}</div>
                                                        <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                            <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                            </svg>
                                                        </div>
                                                    </button>
                                                    {
                                                        creditType.length !== 0 &&
                                                        <ul className={(dropdownVisibilityCreditType === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                            {
                                                                creditType.map((item, index) => (
                                                                    <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={index}>
                                                                        <button type="button" className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button" onClick={e => { e.preventDefault(); this.setState({ selectedCreditTypeIndex: index, dropdownVisibilityCreditType: false }, () => this.confirmAll()) }}>{item.name}</button>
                                                                    </li>
                                                                ))
                                                            }
                                                        </ul>
                                                    }
                                                </div>
                                            </OutsideClickHandler>
                                        </div>

                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('currency')} <span style={{ color: 'red' }}>*</span></label>
                                            <OutsideClickHandler onOutsideClick={() => { this.setState({ dropdownVisibilityExchange: false }) }}>
                                                <div className={"dashboard-payment-container-data-section-content-container-form-select-box " + (selectedExchangeIndexCount > 1 && selectedExchangeIndex < 0 || selectedExchangeIndexCount === 1 && selectedExchangeIndex < 0 && !dropdownVisibilityExchange ? "input-outline" : "")}>
                                                    <button className={"dashboard-payment-container-data-section-content-container-form-select-box-button"} onClick={e => { e.preventDefault(); this.setState({ dropdownVisibilityExchange: !dropdownVisibilityExchange }); if (selectedExchangeIndex < 0) this.setState({ selectedExchangeIndexCount: ++this.state.selectedExchangeIndexCount }) }}>
                                                        <div className="dashboard-payment-container-data-section-content-container-form-select" style={{ color: selectedExchangeIndex < 0 && '#BDC3C7' }}>{selectedExchangeIndex >= 0 ? exchange[selectedExchangeIndex].currency : t('Choose')}</div>
                                                        <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                            <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                            </svg>
                                                        </div>
                                                    </button>
                                                    {
                                                        exchange.length !== 0 &&
                                                        <ul className={(dropdownVisibilityExchange === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                            {
                                                                exchange.map((item, index) => (
                                                                    <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={index}>
                                                                        <button type="button" className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button" onClick={e => { e.preventDefault(); this.setState({ selectedExchangeIndex: index, dropdownVisibilityExchange: false }, () => this.confirmAll()) }}>{item.currency}</button>
                                                                    </li>
                                                                ))
                                                            }
                                                        </ul>
                                                    }
                                                </div>
                                            </OutsideClickHandler>
                                        </div>
                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Amount')} <span style={{ color: 'red' }}>*</span></label>
                                            <div className={"dashboard-payment-container-data-section-content-container-form-input-box " + (amountOutline ? "input-outline" : "")}>
                                                <input type="text" value={amount} onChange={e => this.onChangeAmount(e.target.value)} placeholder='0.00' className="dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole" onBlur={this.setAmountOutline} />
                                            </div>
                                        </div>

                                        <div className="dashboard-payment-container-data-section-content-container-form-footer">
                                            <button onClick={e => this.setState({ step: 2 })} disabled={!confirmedOperation} className={`dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__blue dashboard-payment-container-data-section-content-container-form-footer-button__left ${!confirmedOperation && "dashboard-payment-container-data-section-content-container-form-footer-button__disable"}`}>{t('Order')}</button>
                                            <button onClick={e => { e.preventDefault(); this.props.history.goBack() }} className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__gray dashboard-payment-container-data-section-content-container-form-footer-button__right">{t('cancel')}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="dashboard-payment-container-confirmation-section">
                        <div className="dashboard-payment-container-confirmation-section-header">
                            <h3 className="dashboard-payment-container-confirmation-section-header-title">{t('What happens after you place your order')}</h3>
                        </div>
                        <div className="dashboard-payment-container-confirmation-section-content">
                            <img src={transferConfirmation} className="dashboard-payment-container-confirmation-section-content-image" />
                            <div className="dashboard-payment-container-confirmation-section-content-description">
                                <p>{t('order content')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default translate("translation")(Credit);