import React, { Component, Fragment } from 'react';
import { translate } from 'react-i18next';
import { Link } from 'react-router-dom';
import { request, ipsRequest } from '../../../../../config';
import transferConfirmation from './../../../img/transfer-confirmation.svg';
import currencyFilter from '../../../../../helpers/currencyFilter';
import CardOperationModal from '../../../../../copms/Modal/CardOperationModal';
import CreateCardOperationTemplate from './../../../../../copms/Modal/CreateCardOperationTemplate';
import NotFound from '../../../../../copms/NotFound/NotFound';
import Loading from './../../../Loading/Loading';
import OutsideClickHandler from 'react-outside-click-handler';
import { cardTypes } from '../../../../../helpers/cards';
import { formPost } from '../../../../../helpers/formPost';
import { setNumberToDecimal } from '../../../../../helpers/payments';

class BetweenInternal extends Component {
    state = {
        confirmedOperation: false,
        dropdownVisibilityBank: false,
        dropdownVisibilityBuyingAccount: false,
        dropdownVisibilitySellingAccount: false,
        banks: [],
        accounts: [],
        selectedBuyingAccount: null,
        selectedSellingAccount: null,
        selectedBank: {},
        bankFilter: "",
        crCustomerName: "",
        crCustomerNameValidation: false,
        crIban: "",
        crIbanValidation: false,
        crTaxNo: "",
        crTaxNoValidation: false,
        amount: "",
        amountValidation: false,
        purpose: "",
        purposeValidation: false,
        dtAccountId: null,
        cards: [],
        buyingAccounts: [],
        sellingAccounts: [],
        currencyBuying: '',
        currencySelling: '',
        valueBuying: '',
        valueSelling: '',
        exchangeCashlessList: [],
        exchangeRate: '',
        step: 1,
        sellingIndex: -1,
        buyingIndex: -1,
        open: false,
        createFeedbackMessage: false,
        feedbackErrorModal: null,
        firstCards: [],
        secondCards: [],
        firstCardsShow: false,
        secondCardsShow: false,
        firstCardsIndex: -1,
        secondCardsIndex: -1,
        currencyIndex: -1,
        currencyShow: false,
        openTemp: false,
        tempHave: false,
        cardData: false,
        isLoading: false,
        firstCardsStatus: 0,
        secondCardsStatus: 0,
        exchangeStatus: 0,
        amountStatus: 0,
        commission: null,
        info: null
    }

    updateOpen = (value) => {
        this.setState({ open: value });
    }
    showCards = (requestorCardNumber, destCardId, currency, amount) => {
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getCardListCustomerRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }

        fetch(request("card/getCardListFor" + customerType + "Customer", getCardListCustomerRequestBody))
            .then(res => res.text())
            .then((getCardListForCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getCardListForCustomerResponse = JSON.parse(getCardListForCustomerResponseJSON);
                if (getCardListForCustomerResponse.status.statusCode !== 1 && getCardListForCustomerResponse.status.statusCode !== 137) {
                    if (getCardListForCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getCardListForCustomerResponse.status.statusCode));
                        this.setState({ isLoading: false });
                    }
                }
                else {
                    if (getCardListForCustomerResponse.status.statusCode === 137) {
                        getCardListForCustomerResponse.cardList = [];
                    }
                    let cardList = [];
                    if (getCardListForCustomerResponse.otherCardList) {
                        cardList = [...getCardListForCustomerResponse.cardList, ...getCardListForCustomerResponse.otherCardList]
                    } else {
                        cardList = [...getCardListForCustomerResponse.cardList]
                    }
                    for (let i = 0; i < cardList.length; i++) {
                        // =========ID=========
                        let cardId = "";
                        if (typeof (cardList[i].cardId) === 'object' && getCardListForCustomerResponse.account[i].cardId.hasOwnProperty('c')) {
                            getCardListForCustomerResponse.account[i].id.c.forEach(part => {
                                cardId += part;
                            });
                        }
                        else {
                            cardId += cardList[i].cardId;
                        }
                        cardList[i].cardId = cardId;
                        // =========NUMBER=========
                        cardList[i].organizedCardNumber = cardList[i].cardNumber.substring(0, 4)
                            + " **** **** " + cardList[i].cardNumber.substring(12, 16);

                    }

                    this.setState({ firstCards: cardList, cards: cardList, secondCards: cardList, cardData: true }, () => {
                        this.setState({ firstCardsIndex: this.state.firstCards.findIndex(el => el.cardNumber === requestorCardNumber), secondCardsIndex: this.state.secondCards.findIndex(el => el.cardId == destCardId), currencyIndex: this.state.exchangeCashlessList.findIndex(el => el.currency === currency), amount: amount.toString() });
                        this.selectFirstCard(null, this.state.firstCards.findIndex(el => el.cardNumber === requestorCardNumber))
                        this.selectSecondCard(null, this.state.secondCards.findIndex(el => el.cardId == destCardId))
                    });
                    this.setState({ isLoading: false });

                    //this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    async getTempById() {

        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getCardListCustomerRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            tempId: this.props.match.params.id,
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            tempId: this.props.match.params.id,
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }

        await fetch(request("card/getCardOperationTempById", getCardListCustomerRequestBody))
            .then(res => res.text())
            .then((getCardListForCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getCardListForCustomerResponse = JSON.parse(getCardListForCustomerResponseJSON);
                if (getCardListForCustomerResponse.status.statusCode !== 1 && getCardListForCustomerResponse.status.statusCode !== 137) {
                    if (getCardListForCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.setState({ isLoading: false });

                        // this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getCardListForCustomerResponse.status.statusCode));
                        // this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    this.setState({ tempHave: true });
                    this.showCards(getCardListForCustomerResponse.requestorCardNumber, getCardListForCustomerResponse.destCardId, getCardListForCustomerResponse.currency, getCardListForCustomerResponse.amount);
                }
            });
    }

    selectFirstCard(e, index) {
        if (e) { e.preventDefault(); }
        var cards = Object.create(this.state.secondCards);
        for (let i = 0; i < cards.length; i++) {
            cards[i].unselectable = false;
        }
        cards[index].unselectable = true;
        this.setState({ firstCardsIndex: index, secondCards: cards, firstCardsShow: false, firstCardsStatus: 2 }, () => {
            // this.setState({ currencyIndex: this.state.exchangeCashlessList.findIndex(el => el.currency == this.state.firstCards[index].currency) })
            this.checkFields();
            this.cardOperationCurrency();
        });

    }

    selectSecondCard(e, index) {
        if (e) { e.preventDefault(); }
        var cards = Object.create(this.state.firstCards);
        for (let i = 0; i < cards.length; i++) {
            cards[i].unselectable = false;
        }
        cards[index].unselectable = true;
        this.setState({ secondCardsIndex: index, secondCardsShow: false, firstCards: cards, secondCardsStatus: 2 }, () => this.checkFields());
    }

    selectExchange(e, index) {
        e.preventDefault();
        this.setState({ currencyIndex: index, currencyShow: false, exchangeStatus: 2 });
    }

    onChangeAmount(val) {
        if (/^(\d+(\.\d{0,2})?|\.?\d{1,2})$/.test(val) || val.length === 0) {
            this.setState({ amount: val }, () => this.checkFields())
        }
    }

    checkFields() {
        let { firstCardsIndex, secondCardsIndex, amount } = this.state;
        if (firstCardsIndex >= 0 && secondCardsIndex >= 0 && parseFloat(amount) > 0) {
            this.setState({ confirmedOperation: true });
        } else {
            this.setState({ confirmedOperation: false });
        }
    }

    cardOperationCurrency() {
        let cardOperationCurrencyListRequestBody = {
            cardOperationType: 1,
            currency: this.state.firstCards[this.state.firstCardsIndex]?.currency ?? "AZN"
        }
        fetch(request("exchange/cardOperationCurrency", cardOperationCurrencyListRequestBody))
            .then(res => res.text())
            .then((cardOperationCurrencyResponseJSON) => {
                const JSON = require('true-json-bigint');
                let cardOperationCurrencyResponse = JSON.parse(cardOperationCurrencyResponseJSON);
                if (cardOperationCurrencyResponse && cardOperationCurrencyResponse.exchangeCashlessList) {
                    if (cardOperationCurrencyResponse.exchangeCashlessList.findIndex(item => item.currency == 'AZN') < 0) {
                        cardOperationCurrencyResponse.exchangeCashlessList.push({ currency: "AZN", code: 944, cashlessBuy: 1, cashlessSell: 1 });
                    }
                    this.setState({ exchangeCashlessList: cardOperationCurrencyResponse.exchangeCashlessList, currency: cardOperationCurrencyResponse.exchangeCashlessList[0] }, () => {
                        this.setState({ currencyIndex: this.state.firstCards[this.state.firstCardsIndex]?.currency ? this.state.exchangeCashlessList.findIndex(el => el.currency == this.state.firstCards[this.state.firstCardsIndex].currency) : this.state.exchangeCashlessList.findIndex(el => el.currency == "AZN") })
                    });
                } else {
                    this.setState({ exchangeCashlessList: [{ currency: "AZN", code: 944, cashlessBuy: 1, cashlessSell: 1 }], currency: { currency: "AZN", code: 944, cashlessBuy: 1, cashlessSell: 1 } }, () => {
                        this.setState({ currencyIndex: 0 })
                    });
                }
            })
    }

    componentDidMount() {
        this.setState({ isLoading: true });
        this.getCardOperationInfo();
        this.cardOperationCurrency();

        this.getTempById();

    }

    amountSplit(amount) {
        var splitedAmount = amount.split('.');
        if (splitedAmount.length > 1) {
            return {
                part1: splitedAmount[0],
                part2: splitedAmount[1].length > 1 ? splitedAmount[1] : splitedAmount[1] + '0'
            }
        } else {
            return {
                part1: amount,
                part2: '00'
            }
        }
    }

    onSubmitForm() {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let cardToCardRequestBody = {
            token: localStorage.getItem('token'),
            requestorCardId: this.state.firstCards[this.state.firstCardsIndex].cardId,
            destinationCardId: this.state.secondCards[this.state.secondCardsIndex].cardId,
            currency: this.state.exchangeCashlessList[this.state.currencyIndex].currency,
            amount: parseFloat(this.state.amount),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
        }
        fetch(ipsRequest("cardToCard", cardToCardRequestBody))
            .then(res => res.text())
            .then((cardToCardResponseJSON) => {
                const JSON = require('true-json-bigint');
                let cardToCardResponse = JSON.parse(cardToCardResponseJSON);
                if (cardToCardResponse.status.statusCode !== 1) {
                    if (cardToCardResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.setState({ createFeedbackMessage: false, open: true, feedbackErrorModal: this.props.t(cardToCardResponse.status.statusCode) });
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                    this.setState({ createFeedbackMessage: true, open: true });

                }
            })
    }

    cardToCardWithOtherCards() {
        this.props.updateAppState('isLoadingTransparentBackground', true);

        let requestBody = {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            requestorCardId: this.state.firstCards[this.state.firstCardsIndex].cardId,
            destinationCardId: this.state.secondCards[this.state.secondCardsIndex].cardId,
            currency: this.state.exchangeCashlessList[this.state.currencyIndex].currency,
            // amount: parseFloat(this.state.amount),
            amount: (this.state.amount).toString().length === 1 || (this.state.amount).toString().slice(-1) === "0" ? setNumberToDecimal(this.state.amount, 1) : this.state.amount,
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
        }

        fetch(request("card/cardStorageToCard", requestBody))
            .then(res => res.text())
            .then((responseJSON) => {
                const JSON = require('true-json-bigint');
                let response = JSON.parse(responseJSON);
                if (response.status.statusCode !== 1) {
                    if (response.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.setState({ createFeedbackMessage: false, open: true, feedbackErrorModal: this.props.t(response.status.statusCode) });
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                    formPost(response?.cardStorage?.tokenUrl, {
                        AMOUNT: response?.cardStorage?.amount,
                        CURRENCY: response?.cardStorage?.currency,
                        ORDER: response?.cardStorage?.order,
                        DESC: response?.cardStorage?.desc,
                        MERCH_NAME: response?.cardStorage?.merchName,
                        MERCH_URL: response?.cardStorage?.merchUrl,
                        // MERCHANT: response?.cardStorage?.merchName,
                        TERMINAL: response?.cardStorage?.terminal,
                        EMAIL: response?.cardStorage?.email,
                        TRTYPE: response?.cardStorage?.trtype,
                        COUNTRY: response?.cardStorage?.country,
                        MERCH_GMT: response?.cardStorage?.merchGmt,
                        TIMESTAMP: response?.cardStorage?.timeStamp,
                        NONCE: response?.cardStorage?.nonce,
                        BACKREF: response?.cardStorage?.backref,
                        P_SIGN: response?.cardStorage?.psign,
                        TOKEN: response?.cardStorage?.token,
                        LANG: localStorage.getItem('i18nextLng').toUpperCase()
                    })
                }
            })
    }

    updateOpen = (value) => {
        this.setState({ open: value });
    }

    updateOpenTemp = (value) => {
        this.setState({ openTemp: value });
    }

    cancel() {
        this.setState({ step: 1, firstCardsIndex: -1, secondCardsIndex: -1, amount: "", currencyIndex: -1, confirmedOperation: false, })
    }

    showFeeAmount = () => {
        this.setState({ step: 2 });
        this.setState({ confirmedOperation: true });
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getFeeAmountRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            cardOperationType: 1,
            amount: parseFloat(this.state.amount),
            senderType: 1,
            custType: 2,
            cardNumber: this.state.firstCards[this.state.firstCardsIndex].cardNumber,
            cardId: this.state.firstCards[this.state.firstCardsIndex].cardId,
            destCardNumber: this.state.secondCards[this.state.secondCardsIndex].cardNumber,
            cardCcyCode: this.state.exchangeCashlessList.find(currency => currency.currency === this.state.firstCards[this.state.firstCardsIndex].currency).code,
            ccyCode: this.state.exchangeCashlessList[this.state.currencyIndex].code,
            currency: this.state.exchangeCashlessList[this.state.currencyIndex].currency
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            cardOperationType: 1,
            amount: parseFloat(this.state.amount),
            senderType: 1,
            custType: 1,
            cardNumber: this.state.firstCards[this.state.firstCardsIndex].cardNumber,
            cardId: this.state.firstCards[this.state.firstCardsIndex].cardId,
            destCardNumber: this.state.secondCards[this.state.secondCardsIndex].cardNumber,
            cardCcyCode: this.state.exchangeCashlessList.find(currency => currency.currency === this.state.firstCards[this.state.firstCardsIndex].currency).code,
            ccyCode: this.state.exchangeCashlessList[this.state.currencyIndex].code,
            currency: this.state.exchangeCashlessList[this.state.currencyIndex].currency
        }
        fetch(ipsRequest("getC2cFeeAmount", getFeeAmountRequestBody))
            .then(res => res.text())
            .then((getFeeAmountResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getFeeAmountResponse = JSON.parse(getFeeAmountResponseJSON);
                if (getFeeAmountResponse.status.statusCode !== 1) {
                    if (getFeeAmountResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.setState({ createFeedbackMessage: false, open: true, feedbackErrorModal: this.props.t(getFeeAmountResponse.status.statusCode) });
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    this.setState({
                        commission: getFeeAmountResponse.feeAmount
                    })
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    getCardOperationInfo = async () => {
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getCardOperationInfoRequest = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            cardOperationType: "CARD_TO_OWN_CARD"
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            cardOperationType: "CARD_TO_OWN_CARD"
        }
        await fetch(request("util/getCardOperationInfo", getCardOperationInfoRequest))
            .then(res => res.json())
            .then((getCardOperationInfoResponse) => {
                if (getCardOperationInfoResponse.status.statusCode !== 1) {
                    if (getCardOperationInfoResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getCardOperationInfoResponse.status.statusCode));
                    }
                }
                else {
                    this.setState({ info: getCardOperationInfoResponse.info });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    render() {
        let { t, history } = this.props;
        let { isLoading, confirmedOperation, step, open, firstCards, firstCardsIndex, tempHave, firstCardsShow, secondCards, secondCardsIndex, secondCardsShow, createFeedbackMessage, feedbackErrorModal, amount, exchangeCashlessList, currencyShow, currencyIndex,
            openTemp, cardData, firstCardsStatus, secondCardsStatus, exchangeStatus, amountStatus, commission, info } = this.state;
        let requestBody;
        if (this.state.firstCards[this.state.firstCardsIndex] !== undefined
            && this.state.secondCards[this.state.secondCardsIndex] !== undefined
            && this.state.exchangeCashlessList[this.state.currencyIndex] !== undefined) {
            requestBody = localStorage.getItem('compId') === null ? {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase(),
                requestorCardNumber: this.state.firstCards[this.state.firstCardsIndex].cardNumber,
                destinationCardNumber: this.state.secondCards[this.state.secondCardsIndex].cardNumber,
                cardOperationType: 1,
                currency: this.state.exchangeCashlessList[this.state.currencyIndex].currency,
                amount: parseFloat(this.state.amount),
                requestorCardId: Number(this.state.firstCards[this.state.firstCardsIndex].cardId),
                destinationCardId: Number(this.state.secondCards[this.state.secondCardsIndex].cardId),
            } : {
                custId: localStorage.getItem('custId'),
                compId: localStorage.getItem('compId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase(),
                requestorCardNumber: this.state.firstCards[this.state.firstCardsIndex].cardNumber,
                destinationCardNumber: this.state.secondCards[this.state.secondCardsIndex].cardNumber,
                cardOperationType: 1,
                currency: this.state.exchangeCashlessList[this.state.currencyIndex].currency,
                amount: parseFloat(this.state.amount),
                requestorCardId: Number(this.state.firstCards[this.state.firstCardsIndex].cardId),
                destinationCardId: Number(this.state.secondCards[this.state.secondCardsIndex].cardId),
            };
        }
        return (
            <Fragment>
                <CreateCardOperationTemplate open={openTemp} updateOpen={this.updateOpenTemp} requestURL={'saveCardOperationTemp'} requestBody={requestBody} successClose={() => { history.push('/dashboard/transfer/card-operation') }} />
                <CardOperationModal open={open} updateOpen={this.updateOpen} successClose={() => { history.push('/dashboard/transfer/card-operation') }} errorClose={() => { this.setState({ step: 1 }) }} createFeedbackMessage={createFeedbackMessage} feedbackErrorModal={feedbackErrorModal} history={history} />
                {
                    isLoading ?
                        <div className="dashboard-container-main-section">
                            <Loading />
                        </div>
                        :
                        !tempHave ?
                            <NotFound />
                            :
                            <Fragment>
                                <div className="dashboard-header-route">
                                    <div className="dashboard-header-route-left">
                                        <button onClick={() => this.props.history.push('/dashboard/transfer/card-operation')}>
                                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M11.7344 21.2188L12.6719 20.3281C12.9062 20.0938 12.9062 19.7188 12.6719 19.5312L5.40625 12.2188H21.4375C21.7188 12.2188 22 11.9844 22 11.6562V10.3438C22 10.0625 21.7188 9.78125 21.4375 9.78125H5.40625L12.6719 2.51562C12.9062 2.32812 12.9062 1.95312 12.6719 1.71875L11.7344 0.828125C11.5469 0.59375 11.1719 0.59375 10.9375 0.828125L1.14062 10.625C0.90625 10.8594 0.90625 11.1875 1.14062 11.4219L10.9375 21.2188C11.1719 21.4531 11.5469 21.4531 11.7344 21.2188Z" fill="#2E3131" />
                                            </svg>
                                        </button>
                                        <h2>{t('Between my own cards')}</h2>
                                    </div>
                                </div>
                                <div className="dashboard-payment-container">
                                    <div className="dashboard-payment-container-data-section">
                                        <div className="dashboard-payment-container-data-section-header">
                                            <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                                                <h2 className="dashboard-payment-container-data-section-header-title">{t('Make a transfer')}</h2>
                                            </div>
                                            <button className="dashboard-payment-container-data-section-header-button" onClick={() => { this.setState({ openTemp: true }) }} disabled={!confirmedOperation}>{t("Save as template")}</button>
                                        </div>

                                        <form className="dashboard-payment-container-data-section-content">
                                            <div className="dashboard-payment-container-data-section-content-container">
                                                <h3 className="dashboard-payment-container-data-section-content-container-title">{t('Payment settings')}</h3>
                                                <div className="dashboard-payment-container-data-section-content-container-form">
                                                    <div className="dashboard-payment-container-data-section-content-container-form-container">
                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Withdrawal card')} <span style={{ color: 'red' }}>*</span></label>
                                                            <OutsideClickHandler onOutsideClick={(e) => { e.preventDefault(); this.setState({ firstCardsShow: false, firstCardsStatus: firstCardsShow ? firstCardsIndex >= 0 ? 2 : 1 : firstCardsStatus }) }}>
                                                                <div className={`dashboard-payment-container-data-section-content-container-form-select-box ${firstCardsStatus == 1 ? "input-outline" : ""}`}>
                                                                    <button className={`dashboard-payment-container-data-section-content-container-form-select-box-button ${step >= 2 ? "dashboard-payment-container-data-section-content-container-form-select-box-button__disable" : "dashboard-payment-container-data-section-content-container-form-select-box-button"}`} onClick={e => { e.preventDefault(); this.setState({ firstCardsShow: !firstCardsShow, firstCardsStatus: firstCardsShow ? firstCardsIndex >= 0 ? 2 : 1 : firstCardsStatus }); }}>
                                                                        <div className="dashboard-payment-container-data-section-content-container-form-select" style={firstCardsIndex >= 0 || (cardData === true && (!firstCards || firstCards.length === 0)) ? {} : { color: '#BDC3C7' }}>
                                                                            {
                                                                                cardData === true &&
                                                                                firstCards
                                                                                && firstCardsIndex >= 0
                                                                                && firstCards.length > 0
                                                                                // && firstCards[firstCardsIndex].cardNumber + ' / ' + firstCards[firstCardsIndex].balance + ' ' + firstCards[firstCardsIndex].currency
                                                                                && firstCards[firstCardsIndex].organizedCardNumber + ' / ' + (firstCards[firstCardsIndex].cardType === cardTypes.CARD_STORAGE ? firstCards[firstCardsIndex].holder : `${firstCards[firstCardsIndex].balance} ${firstCards[firstCardsIndex].currency}`)
                                                                            }
                                                                            {
                                                                                cardData === true &&
                                                                                (!firstCards || firstCards.length === 0)
                                                                                && t('The card list is empty')
                                                                            }
                                                                            {
                                                                                ((cardData === true
                                                                                    && (firstCards && firstCards.length > 0)
                                                                                    && firstCardsIndex < 0)
                                                                                    || cardData === false)
                                                                                && t('Choose')
                                                                            }
                                                                        </div>
                                                                        <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                                            <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                                            </svg>
                                                                        </div>
                                                                    </button>
                                                                    <ul className={(firstCardsShow === false || step >= 2) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                                        {
                                                                            firstCards && firstCards.length > 0 &&
                                                                            firstCards.map((card, index) => {
                                                                                if (!card.unselectable)
                                                                                    return (
                                                                                        <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={index}>
                                                                                            <button onClick={e => this.selectFirstCard(e, index)} disabled={!Boolean(card.cardStatus)} className={!Boolean(card.cardStatus) ? "dashboard-payment-container-data-section-content-container-form-select-box-list-item-button__disable" : "dashboard-payment-container-data-section-content-container-form-select-box-list-item-button"}>{card.organizedCardNumber} / {card.cardType === cardTypes.CARD_STORAGE ? card.holder : `${card.balance} ${card.currency}`}</button>
                                                                                        </li>
                                                                                    );
                                                                            })
                                                                        }

                                                                    </ul>
                                                                </div>
                                                            </OutsideClickHandler>
                                                        </div>

                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Incoming card')} <span style={{ color: 'red' }}>*</span></label>
                                                            <OutsideClickHandler onOutsideClick={(e) => { e.preventDefault(); this.setState({ secondCardsShow: false, secondCardsStatus: secondCardsShow ? secondCardsIndex >= 0 ? 2 : 1 : secondCardsStatus }) }}>
                                                                <div className={`dashboard-payment-container-data-section-content-container-form-select-box ${secondCardsStatus == 1 ? "input-outline" : ""}`}>
                                                                    <button className={`dashboard-payment-container-data-section-content-container-form-select-box-button ${step >= 2 ? "dashboard-payment-container-data-section-content-container-form-select-box-button__disable" : "dashboard-payment-container-data-section-content-container-form-select-box-button"}`} onClick={e => { e.preventDefault(); this.setState({ secondCardsShow: !secondCardsShow, secondCardsStatus: secondCardsShow ? secondCardsIndex >= 0 ? 2 : 1 : secondCardsStatus }); }}>
                                                                        <div className="dashboard-payment-container-data-section-content-container-form-select" style={secondCardsIndex >= 0 || (cardData === true && (!secondCards || secondCards.length === 0)) ? {} : { color: '#BDC3C7' }}>
                                                                            {
                                                                                cardData === true &&
                                                                                secondCards
                                                                                && secondCardsIndex >= 0
                                                                                && secondCards.length > 0
                                                                                && secondCards[secondCardsIndex].cardNumber + ' / ' + secondCards[secondCardsIndex].balance + ' ' + secondCards[secondCardsIndex].currency
                                                                            }
                                                                            {
                                                                                cardData === true &&
                                                                                (!secondCards || secondCards.length === 0)
                                                                                && t('The card list is empty')
                                                                            }
                                                                            {
                                                                                ((cardData === true
                                                                                    && (secondCards && secondCards.length > 0)
                                                                                    && secondCardsIndex < 0)
                                                                                    || cardData === false)
                                                                                && t('Choose')
                                                                            }
                                                                        </div>
                                                                        <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                                            <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                                            </svg>
                                                                        </div>
                                                                    </button>
                                                                    <ul className={(secondCardsShow === false || step >= 2) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                                        {
                                                                            secondCards && secondCards.length > 0 &&
                                                                            secondCards.map((card, index) => {
                                                                                if (!card.unselectable && card.cardType !== cardTypes.CARD_STORAGE)
                                                                                    return (
                                                                                        <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={index}>
                                                                                            <button onClick={e => this.selectSecondCard(e, index)} className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button">{card.cardNumber} / {card.balance} {card.currency}</button>
                                                                                        </li>
                                                                                    );
                                                                            })
                                                                        }

                                                                    </ul>
                                                                </div>
                                                            </OutsideClickHandler>
                                                        </div>
                                                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                            <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                                <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Amount')} <span style={{ color: 'red' }}>*</span></label>
                                                                <div className={`dashboard-payment-container-data-section-content-container-form-input-box ${amountStatus == 1 && (amount.length < 1 || amount == "0") ? "input-outline" : ""}`}>
                                                                    <input onBlur={() => this.setState({ amountStatus: 1 })} onChange={e => this.onChangeAmount(e.target.value)} value={amount} type="text" maxLength={12} disabled={step > 1} className={`dashboard-payment-container-data-section-content-container-form-input ${step > 1 && " dashboard-payment-container-data-section-content-container-form-input__disable"}`} placeholder="0.00" />
                                                                    <div className="dashboard-payment-container-data-section-content-container-form-icon dashboard-payment-container-data-section-content-container-form-icon__currency">{(exchangeCashlessList && exchangeCashlessList.length > 0) && currencyIndex >= 0 && exchangeCashlessList[currencyIndex]?.currency}</div>
                                                                </div>
                                                            </div>

                                                            <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                                <label className="dashboard-payment-container-data-section-content-container-form-label">{t('currency')} <span style={{ color: 'red' }}>*</span></label>
                                                                <OutsideClickHandler onOutsideClick={(e) => { e.preventDefault(); this.setState({ currencyShow: false, exchangeStatus: currencyShow ? currencyIndex >= 0 ? 2 : 1 : exchangeStatus }) }}>
                                                                    <div className={`dashboard-payment-container-data-section-content-container-form-select-box ${exchangeStatus == 1 ? "input-outline" : ""}`}>
                                                                        <button className={`dashboard-payment-container-data-section-content-container-form-select-box-button ${step >= 2 ? "dashboard-payment-container-data-section-content-container-form-select-box-button__disable" : "dashboard-payment-container-data-section-content-container-form-select-box-button"}`} onClick={e => { e.preventDefault(); this.setState({ currencyShow: !currencyShow, exchangeStatus: currencyShow ? currencyIndex >= 0 ? 2 : 1 : exchangeStatus }); }}>
                                                                            <div className="dashboard-payment-container-data-section-content-container-form-select">{(exchangeCashlessList && exchangeCashlessList.length > 0) ? currencyIndex >= 0 && exchangeCashlessList[currencyIndex]?.currency : 'select'}</div>
                                                                            <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                                                <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                                                </svg>
                                                                            </div>
                                                                        </button>
                                                                        <ul className={(currencyShow === false || step >= 2) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                                            {
                                                                                exchangeCashlessList && exchangeCashlessList.length > 0 &&
                                                                                exchangeCashlessList.map((item, index) => {
                                                                                    return (
                                                                                        <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item">
                                                                                            <button onClick={e => this.selectExchange(e, index)} className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button">{item.currency}</button>
                                                                                        </li>
                                                                                    );
                                                                                })
                                                                            }

                                                                        </ul>
                                                                    </div>
                                                                </OutsideClickHandler>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>

                                    </div>
                                    {
                                        step == 1 ?
                                            <div className="dashboard-payment-container-confirmation-section">
                                                <div className="dashboard-payment-container-confirmation-section-header">
                                                    <h3 className="dashboard-payment-container-confirmation-section-header-title">{t('How do I transfer between my own cards?')}</h3>
                                                </div>
                                                <div className="dashboard-payment-container-confirmation-section-content">
                                                    <img src={transferConfirmation} className="dashboard-payment-container-confirmation-section-content-image" />
                                                    <div className="dashboard-payment-container-confirmation-section-content-description">
                                                        {/* <p>{t('between my card content')}</p> */}
                                                        <p dangerouslySetInnerHTML={{ __html: info }}></p>
                                                    </div>
                                                    <div className="dashboard-payment-container-data-section-content-container-form-footer">
                                                        <button onClick={e => { firstCards[firstCardsIndex].cardType !== cardTypes.CARD_STORAGE ? this.showFeeAmount() : this.setState({ step: 2 }) }} disabled={!confirmedOperation} className={`dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__blue dashboard-payment-container-data-section-content-container-form-footer-button__left ${!confirmedOperation && "dashboard-payment-container-data-section-content-container-form-footer-button__disable"}`}>{t('continue')}</button>
                                                        <button onClick={e => this.props.history.push('/dashboard/transfer/card-operation')} className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__gray dashboard-payment-container-data-section-content-container-form-footer-button__right">{t('cancel')}</button>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className="dashboard-payment-container-confirmation-section">
                                                <div className="dashboard-payment-container-confirmation-section-header">
                                                    <h3 className="dashboard-payment-container-confirmation-section-header-title">{t('Confirmation of transfer')}</h3>
                                                </div>
                                                <div className="dashboard-payment-container-confirmation-section-content">
                                                    <h3 className="dashboard-payment-container-confirmation-section-amount">
                                                        <span className="dashboard-payment-container-confirmation-section-amount-currency dashboard-payment-container-confirmation-section-amount-currency__azn">{currencyFilter(exchangeCashlessList && exchangeCashlessList.length > 0 && currencyIndex >= 0 && exchangeCashlessList[currencyIndex].currency)}</span>
                                                        <span className="dashboard-payment-container-confirmation-section-amount-whole">{this.amountSplit(amount).part1}</span>
                                                        <span className="dashboard-payment-container-confirmation-section-amount-fractional">{this.amountSplit(amount).part2}</span>
                                                    </h3>
                                                    <ul className="dashboard-payment-container-confirmation-section-list">
                                                        <li className="dashboard-payment-container-confirmation-section-list-item">
                                                            <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Withdrawal card')}</p>
                                                            <p className="dashboard-payment-container-data-section-content-container-form-group-data">{firstCards && firstCardsIndex >= 0 && firstCards.length > 0 && firstCards[firstCardsIndex].cardNumber}</p>
                                                        </li>
                                                        <li className="dashboard-payment-container-confirmation-section-list-item">
                                                            <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Incoming card')}</p>
                                                            <p className="dashboard-payment-container-data-section-content-container-form-group-data">{secondCards && secondCardsIndex >= 0 && secondCards.length > 0 && secondCards[secondCardsIndex].cardNumber}</p>
                                                        </li>
                                                        <li className="dashboard-payment-container-confirmation-section-list-item">
                                                            <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('currency')}</p>
                                                            <p className="dashboard-payment-container-data-section-content-container-form-group-data">{exchangeCashlessList && exchangeCashlessList.length > 0 && currencyIndex >= 0 && exchangeCashlessList[currencyIndex].currency}</p>
                                                        </li>
                                                        <li className="dashboard-payment-container-confirmation-section-list-item">
                                                            <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Amount')}</p>
                                                            <p className="dashboard-payment-container-data-section-content-container-form-group-data">{this.amountSplit(amount).part1}.{this.amountSplit(amount).part2} {exchangeCashlessList && exchangeCashlessList.length > 0 && currencyIndex >= 0 && exchangeCashlessList[currencyIndex].currency}</p>
                                                        </li>
                                                        {
                                                            commission !== null &&
                                                            <li className="dashboard-payment-container-confirmation-section-list-item">
                                                                <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Commission')}</p>
                                                                <p className="dashboard-payment-container-data-section-content-container-form-group-data">{commission} {firstCards[firstCardsIndex].currency}</p>
                                                            </li>
                                                        }
                                                        <li className="dashboard-payment-container-confirmation-section-list-item">
                                                            <div className="dashboard-payment-container-data-section-content-container-form-footer">
                                                                <button onClick={e => firstCards[firstCardsIndex].cardType === cardTypes.CARD_STORAGE ? this.cardToCardWithOtherCards() : this.onSubmitForm()} className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__blue dashboard-payment-container-data-section-content-container-form-footer-button__left">{t('Confirm')}</button>
                                                                <button onClick={e => this.cancel()} className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__gray dashboard-payment-container-data-section-content-container-form-footer-button__right">{t('cancel')}</button>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                    }
                                </div>
                            </Fragment>
                }
            </Fragment>
        )
    }
}


export default translate("translation")(BetweenInternal);
