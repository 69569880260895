import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

import { request , requestCardStorage} from "../../../config";
import CardSlider from './CardSlider/CardSlider';
import lastOperationsIcon from "./../img/last-operations-icon.png";
import masterCardIcon from "./../img/card-icon.svg";
import visaCardIcon from './../img/visa-icon.svg';
import addIcon from "./../img/add.svg";
import cardBackground from "./../img/card-background.png";
import paperIcon from "./../img/paper.svg";
import { translate } from 'react-i18next';
import Dropdown from './../../../copms/Dropdown/Dropdown';
import Modal from '../../../copms/Modal/CardModal.js';
import currencyFilter from './../../../helpers/currencyFilter';
import Loading from './../Loading/Loading';
import News from './../../../copms/Modal/News';
import CampaignPopup from '../../../copms/Modal/CampaignPopup';
import YesNoModal from './../../../copms/Modal/YesNoModal';
import PinUnblockSuccess from './../../../copms/Modal/PinUnblockSuccess';
import AddNewCardModal from '../Finance/CardStorage/AddNewCardModal/AddNewCardModal';
import DigitalCardInfoModal from '../Finance/DigitalCard/DigitalCardInfoModal/DigitalCardInfoModal';

import { cardTypes, staticCardsTypes , getDigitalCardInfo, setPaymentTypeIcon} from '../../../helpers/cards';

class Home extends Component {
    state = {
        campaigns: [],
        cards: [],
        accounts: [],
        statements: [],
        currentCardIndex: 0,
        open: false,
        modalCardObj: {},
        currentCard: {
            organizedCurrentBalance: {}
        },
        slideIndex: null,
        sliderModal: false,
        isLoading: false,
        successModal: false,
        successModalData: null,
        unblockPinModalOpen: false,
        pinUnblockAmount: null,
        visibility:null,
        campaignCustId:null,
        screenTypeId:null,
        image:null,
        campaignId:null,
        note: "",
        imageVersionNoCampaign: !localStorage.getItem('imageVersionNoCampaign')  ? null : localStorage.getItem('imageVersionNoCampaign'),
        openAddNewCardModal: false,
        openDigitalCardInfoModal: false,
        cardId: null,
        pageNumber: 1
    }
    
    generateNewCardsList =(prevCardsList, checkDigital)=>{
        let activateDigitalCardBtn= {cardId:"digitalCard", cardType: staticCardsTypes.ACTIVATE_DIGITAL_CARD_BTN, cardStatus: staticCardsTypes.ACTIVATE_DIGITAL_CARD_BTN};
        let staticCards = [
            {cardId:"addCards", cardType: staticCardsTypes.ADD_CARDS_BTN, cardStatus: staticCardsTypes.ADD_CARDS_BTN}
        ];

        if(!checkDigital){
            staticCards.unshift(activateDigitalCardBtn)
        }
        let cards = prevCardsList ;
        localStorage.getItem('compId') == null && cards.push(...staticCards)

        return this.setState({ cards: cards });
    }

    updateCurrentCard = (value) => {
        let i = this.state.currentCardIndex;
        if (value === 'prev') {
            if (this.state.currentCardIndex > 0) {
                i--;
            }
            else {
                i = this.state.cards.length - 1;
            }
        }
        else {
            if (value === 'next') {
                if (this.state.currentCardIndex < this.state.cards.length - 1) {
                    i++;
                }
                else {
                    i = 0;
                }
            }
        }
        this.setState({ currentCardIndex: i });
        this.setState({ currentCard: this.state.cards[i] });
    }
    updateCardState = (index) => {
        this.setState({ currentCardIndex: index });
        this.setState({ currentCard: this.state.cards[index] });
    }
    updateOpenModal = (value) => {
        this.setState({ open: value });
    }
    toggleAddNewCardModal=()=>{
        this.setState({openAddNewCardModal: !this.state.openAddNewCardModal})
    }
    toggleDigitalCardInfoModal=()=>{
        this.setState({openDigitalCardInfoModal: !this.state.openDigitalCardInfoModal})
    }
    async showCards(initial) {
        if (!initial) {
            this.props.updateAppState('isLoadingTransparentBackground', true);
        }
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getAccountListForCustomerRequestBody = customerType === "Juridical" ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }
        await fetch(request("card/getCardListFor" + customerType + "Customer", getAccountListForCustomerRequestBody))
            .then(res => res.text())
            .then((getCardListForCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getCardListForCustomerResponse = JSON.parse(getCardListForCustomerResponseJSON);
                if (getCardListForCustomerResponse.status.statusCode !== 1 && getCardListForCustomerResponse.status.statusCode !== 137) {
                    if (getCardListForCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getCardListForCustomerResponse.status.statusCode))
                        if (!initial) {
                            this.props.updateAppState('isLoadingTransparentBackground', false);
                        }
                    }
                }
                else {
                    if (getCardListForCustomerResponse.status.statusCode === 137) {
                        getCardListForCustomerResponse.cardList = [];
                    }
                    let cardList = [];
                    if (getCardListForCustomerResponse.otherCardList) {
                        cardList= [...getCardListForCustomerResponse.cardList, ...getCardListForCustomerResponse.otherCardList]
                    }else{
                        cardList =  [...getCardListForCustomerResponse.cardList]
                    }
                   
                    for (let i = 0; i < cardList.length; i++) {
                        // =========ID=========
                        let cardId = "";
                        if (typeof (cardList[i].cardId) === 'object' && getCardListForCustomerResponse.account[i].cardId.hasOwnProperty('c')) {
                            getCardListForCustomerResponse.account[i].id.c.forEach(part => {
                                cardId += part;
                            });
                        }
                        else {
                            cardId += cardList[i].cardId;
                        }
                        cardList[i].cardId = cardId;
                        // =========HOLDER=========
                        let holderArray = cardList[i].holder.split(' ');
                        let lowercaseName = holderArray[0].toLowerCase();
                        let lowercaseSurname = holderArray[1].toLowerCase();
                        cardList[i].organizedHolder = {
                            name: holderArray[0][0] + lowercaseName.substring(1, lowercaseName.length),
                            surname: holderArray[1][0] + lowercaseSurname.substring(1, lowercaseSurname.length)
                        };
                        // =========NUMBER=========
                        cardList[i].organizedCardNumber = cardList[i].cardNumber.substring(0, 4)
                            + " **** **** " + cardList[i].cardNumber.substring(12, 16);
                        // =========EXPIRYDATE=========
                        if(cardList[i].expiryDate){
                        let expiryDate = new Date(cardList[i].expiryDate);
                        cardList[i].organizedExpiryDate = {
                            month: (expiryDate.getMonth() < 9) ? ('0' + (expiryDate.getMonth() + 1)) : (expiryDate.getMonth() + 1),
                            year: expiryDate.getFullYear().toString().substring(2, 4)
                        }
                    }
                        // =========BALANCE=========
                        if (cardList[i].balance) {
                          if (cardList[i].balance.toString() === "0") {
                            cardList[i].organizedCurrentBalance = {
                              wholePart: "0",
                              fractionalPart: "00",
                            };
                          } else {
                            if (
                              cardList[i].balance.toString().indexOf(".") !== -1
                            ) {
                              let currentBalanceArray = cardList[i].balance
                                .toString()
                                .split(".");
                              cardList[i].organizedCurrentBalance = {
                                wholePart: currentBalanceArray[0],
                                fractionalPart:
                                  currentBalanceArray[1].length === 1
                                    ? currentBalanceArray[1] + "0"
                                    : currentBalanceArray[1],
                              };
                            } else {
                              cardList[i].organizedCurrentBalance = {
                                wholePart: cardList[i].balance.toString(),
                                fractionalPart: "00",
                              };
                            }
                          }
                        } else {
                          cardList[i].organizedCurrentBalance = {
                            wholePart: "0",
                            fractionalPart: "00",
                          };
                        }
                        // =========CURRENCY=========
                        cardList[i].currencyIcon = currencyFilter(cardList[i].currency);
                    }
                    localStorage.setItem("checkDigital", getCardListForCustomerResponse.checkDigital)
                    this.generateNewCardsList(cardList, getCardListForCustomerResponse.checkDigital);
                    this.setState({ pinUnblockAmount: getCardListForCustomerResponse.pinUnblockAmount });
                    // this.setState({currentCardIndex: this.state.cards.length - 1});
                    this.setState({ currentCard: this.state.cards[this.state.currentCardIndex] });
                    if (!initial) {
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }
    showOtherCardStatement = () => {
          this.props.updateAppState('isLoadingTransparentBackground', true);
      
          let requestBody = {
            token: localStorage.getItem('token'),
            pageNumber: this.state.pageNumber,
            cardId: null
          }
          fetch(requestCardStorage("storage/getCardStorageOperationHistory", requestBody))
          .then(res => res.text())
          .then((getCardStatementForCustomerResponseJSON) => {
              const JSON = require('true-json-bigint');
              let getCardStatementForCustomerResponse = JSON.parse(getCardStatementForCustomerResponseJSON);
              if (getCardStatementForCustomerResponse.status.statusCode !== 1) {
                if (getCardStatementForCustomerResponse.status.statusCode === 200) {
                    this.props.logout();
                } else {
                    this.setState({ isLoading: false });
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
              }
              else {
                  for (let i = 0; i < getCardStatementForCustomerResponse.cardStorageOperInfos.length; i++) {
                    getCardStatementForCustomerResponse.cardStorageOperInfos[i].purpose = getCardStatementForCustomerResponse.cardStorageOperInfos[i].name;
                    getCardStatementForCustomerResponse.cardStorageOperInfos[i].trDate = getCardStatementForCustomerResponse.cardStorageOperInfos[i].operDate;
                      // =========NUMBER=========
                      getCardStatementForCustomerResponse.cardStorageOperInfos[i].organizedCardNumber = getCardStatementForCustomerResponse.cardStorageOperInfos[i].cardNumber?.substring(0, 4)
                          + " **** **** " + getCardStatementForCustomerResponse.cardStorageOperInfos[i].cardNumber?.substring(12, 16);
                      // =========AMOUNT=========
                      if (getCardStatementForCustomerResponse.cardStorageOperInfos[i].amount.toString() === "0") {
                          getCardStatementForCustomerResponse.cardStorageOperInfos[i].amount = {
                              wholePart: "0",
                              fractionalPart: "00"
                          }
                      }
                      else {
                          if (getCardStatementForCustomerResponse.cardStorageOperInfos[i].amount.toString().indexOf('.') !== -1) {
                              let amountArray = getCardStatementForCustomerResponse.cardStorageOperInfos[i].amount.toString().split('.');
                              getCardStatementForCustomerResponse.cardStorageOperInfos[i].amount = {
                                  wholePart: amountArray[0],
                                  fractionalPart: (amountArray[1].length === 1) ? (amountArray[1] + '0') : amountArray[1]
                              }
                          }
                          else {
                              let amountArray = getCardStatementForCustomerResponse.cardStorageOperInfos[i].amount.toString().split('.');
                              getCardStatementForCustomerResponse.cardStorageOperInfos[i].amount = {
                                  wholePart: getCardStatementForCustomerResponse.cardStorageOperInfos[i].amount.toString(),
                                  fractionalPart: "00"
                              }
                          }
                      }
                    }
                  this.setState({ statements: [...this.state.statements, ...getCardStatementForCustomerResponse.cardStorageOperInfos ]});
                this.props.updateAppState('isLoadingTransparentBackground', false);
              }
          },
              (error) => {
                  this.props.history.push("/technical-break");
              });
  
      }
    async showStatements() {
        const changeDateType = () => {
            let currentDate = new Date();
            let date = (currentDate.getDate() < 10) ? ("0" + currentDate.getDate()) : currentDate.getDate();
            let month = (currentDate.getMonth() + 1 < 10) ? ("0" + (currentDate.getMonth() + 1)) : (currentDate.getMonth() + 1);
            let year = currentDate.getFullYear();
            return date + '.' + month + '.' + year;
        }
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getCardStatementForCustomerRequestBody = customerType === "Juridical" ? {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            startDate: changeDateType(), //temporary
            endDate: changeDateType(),
            compId: localStorage.getItem('compId'),
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            startDate: changeDateType(), //temporary
            endDate: changeDateType(),

        }
        await fetch(request("card/getCardStatementFor" + customerType + "Customer", getCardStatementForCustomerRequestBody))
            .then(res => res.text())
            .then((getCardStatementForCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getCardStatementForCustomerResponse = JSON.parse(getCardStatementForCustomerResponseJSON);
                if (getCardStatementForCustomerResponse.status.statusCode !== 1 && getCardStatementForCustomerResponse.status.statusCode !== 137 && getCardStatementForCustomerResponse.status.statusCode !== 165) {
                    if (getCardStatementForCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getCardStatementForCustomerResponse.status.statusCode));
                        this.setState({ isLoading: false });
                    }
                }
                else {
                    if (getCardStatementForCustomerResponse.status.statusCode === 137 || getCardStatementForCustomerResponse.status.statusCode === 165) {
                        getCardStatementForCustomerResponse.cardStamentList = [];
                    }
                    for (let i = 0; i < getCardStatementForCustomerResponse.cardStamentList.length; i++) {
                        // =========NUMBER=========
                        getCardStatementForCustomerResponse.cardStamentList[i].organizedCardNumber = getCardStatementForCustomerResponse.cardStamentList[i].cardNumber.substring(0, 4)
                            + " **** **** " + getCardStatementForCustomerResponse.cardStamentList[i].cardNumber.substring(12, 16);
                        // =========DATE=========
                        getCardStatementForCustomerResponse.cardStamentList[i].organizedDate = getCardStatementForCustomerResponse.cardStamentList[i].trDate.substring(0, getCardStatementForCustomerResponse.cardStamentList[i].trDate.length - 3);
                        // =========AMOUNT=========
                        if (getCardStatementForCustomerResponse.cardStamentList[i].dtAmount === 0) {
                            getCardStatementForCustomerResponse.cardStamentList[i].amount = getCardStatementForCustomerResponse.cardStamentList[i].crAmount;
                        }
                        else {
                            if (getCardStatementForCustomerResponse.cardStamentList[i].crAmount === 0) {
                                getCardStatementForCustomerResponse.cardStamentList[i].amount = getCardStatementForCustomerResponse.cardStamentList[i].dtAmount;
                            }
                        }
                        if (getCardStatementForCustomerResponse.cardStamentList[i].amount.toString() === "0") {
                            getCardStatementForCustomerResponse.cardStamentList[i].amount = {
                                wholePart: "0",
                                fractionalPart: "00"
                            }
                        }
                        else {
                            if (getCardStatementForCustomerResponse.cardStamentList[i].amount.toString().indexOf('.') !== -1) {
                                let amountArray = getCardStatementForCustomerResponse.cardStamentList[i].amount.toString().split('.');
                                getCardStatementForCustomerResponse.cardStamentList[i].amount = {
                                    wholePart: amountArray[0],
                                    fractionalPart: (amountArray[1].length === 1) ? (amountArray[1] + '0') : amountArray[1]
                                }
                            }
                            else {
                                let amountArray = getCardStatementForCustomerResponse.cardStamentList[i].amount.toString().split('.');
                                getCardStatementForCustomerResponse.cardStamentList[i].amount = {
                                    wholePart: getCardStatementForCustomerResponse.cardStamentList[i].amount.toString(),
                                    fractionalPart: "00"
                                }
                            }
                        }
                    }
                    this.setState({ statements: getCardStatementForCustomerResponse.cardStamentList });
                    this.showOtherCardStatement();
                }
            },
                (error) => {

                    this.props.history.push("/technical-break");
                });
    }
    async showCampains() {
        let getCampaignListRequestBody = {
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            token: localStorage.getItem('token'),
            custId:parseInt(localStorage.getItem('custId')),
            imageVersionNoCampaign: this.state.imageVersionNoCampaign,
        }
        if (localStorage.getItem('compId')) {
            getCampaignListRequestBody.compId = parseInt(localStorage.getItem('compId'));
        }
        fetch(request("util/getCampaignListv1", getCampaignListRequestBody))
            .then(res => res.text())
            .then((getCampaignListResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getCampaignListResponse = JSON.parse(getCampaignListResponseJSON);
                if (getCampaignListResponse.status.statusCode !== 1) {
                    if (getCampaignListResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getCampaignListResponse.status.statusCode));
                        this.setState({ isLoading: false });
                    }
                }
                else {
                    getCampaignListResponse.respCampaignList.map((item, index) => {
                        getCampaignListResponse.respCampaignList[index].image = 'data:image/;base64,' + getCampaignListResponse.respCampaignList[index].image;
                    });

                    localStorage.getItem("imageVersionNoCampaign") !== getCampaignListResponse?.imageVersionNoCampaign && 
                       localStorage.setItem("campaigns", JSON.stringify(getCampaignListResponse?.respCampaignList));


                    this.setState({ 
                        campaigns: JSON.parse(localStorage.getItem('campaigns')) , 
                        visibility:getCampaignListResponse?.visibility, screenTypeId: getCampaignListResponse?.screenTypeId, campaignCustId: getCampaignListResponse?.campaignCustId, image: 'data:image/;base64,' + getCampaignListResponse?.image, campaignId:getCampaignListResponse?.campaignId, note: getCampaignListResponse?.note });
                
                        localStorage.setItem("imageVersionNoCampaign", getCampaignListResponse.imageVersionNoCampaign);
                        localStorage.setItem("sendRequestCampaigns", "0");

                    }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }
    async showAccounts() {
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getAccountListForCustomerRequestBody = customerType === "Juridical" ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            isBalanceAccount: 0
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            isBalanceAccount: 0
        }
        this.setState({ custId: localStorage.getItem('custId'), token: localStorage.getItem('token') });
        await fetch(request("account/getAccountListFor" + customerType + "Customer", getAccountListForCustomerRequestBody))
            .then(res => res.text())
            .then((getAccountListForCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getAccountListForCustomerResponse = JSON.parse(getAccountListForCustomerResponseJSON);
                if (getAccountListForCustomerResponse.status.statusCode !== 1 && getAccountListForCustomerResponse.status.statusCode !== 126) {
                    if (getAccountListForCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getAccountListForCustomerResponse.status.statusCode))
                        this.setState({ isLoading: false });
                    }
                }
                else {
                    if (getAccountListForCustomerResponse.status.statusCode === 126) {
                        getAccountListForCustomerResponse.accountList = [];
                    }
                    for (let i = 0; i < getAccountListForCustomerResponse.accountList.length; i++) {
                        // =========ID=========
                        let accountId = "";
                        if (typeof (getAccountListForCustomerResponse.accountList[i].accountId) === 'object' && getAccountListForCustomerResponse.account[i].accountId.hasOwnProperty('c')) {
                            getAccountListForCustomerResponse.account[i].id.c.forEach(part => {
                                accountId += part;
                            });
                        }
                        else {
                            accountId += getAccountListForCustomerResponse.accountList[i].accountId;
                        }
                        getAccountListForCustomerResponse.accountList[i].accountId = accountId;
                        // =========BALANCE=========
                        if (getAccountListForCustomerResponse.accountList[i].currentBalance.toString() === "0") {
                            getAccountListForCustomerResponse.accountList[i].currentBalance = {
                                wholePart: "0",
                                fractionalPart: "00"
                            }
                        }
                        else {
                            if (getAccountListForCustomerResponse.accountList[i].currentBalance.toString().indexOf('.') !== -1) {
                                let currentBalanceArray = getAccountListForCustomerResponse.accountList[i].currentBalance.toString().split('.');
                                getAccountListForCustomerResponse.accountList[i].currentBalance = {
                                    wholePart: currentBalanceArray[0],
                                    fractionalPart: (currentBalanceArray[1].length === 1) ? (currentBalanceArray[1] + '0') : currentBalanceArray[1]
                                }
                            }
                            else {
                                let currentBalanceArray = getAccountListForCustomerResponse.accountList[i].currentBalance.toString().split('.');
                                getAccountListForCustomerResponse.accountList[i].currentBalance = {
                                    wholePart: getAccountListForCustomerResponse.accountList[i].currentBalance.toString(),
                                    fractionalPart: "00"
                                }
                            }
                        }
                    }
                    this.setState({ accounts: getAccountListForCustomerResponse.accountList });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }
    dropdownItemHandler = (index, card) => {
        switch (index) {
            case 2:
                this.setState({
                    open: true,
                    modalCardObj: card
                });
                break;
            case 5:
                this.setState({
                    openDigitalCardInfoModal: true,
                    modalCardObj: card
                });
                break;
            // case 3:
            //     if (this.state.pinUnblockAmount) {
            //         this.setState({ modalCardObj: card, unblockPinModalOpen: true });
            //     }
            //     else {
            //         this.setState({ modalCardObj: card }, () => {
            //             this.unlockPin();
            //         });
            //     }
            //     break;
        }
    }
    dropdownItemHandlerForDigital = (index, card) => {
        switch (index) {
            case 2:
                this.setState({
                    open: true,
                    modalCardObj: card
                });
                break;
            case 3:
                this.setState({
                    openDigitalCardInfoModal: true,
                    modalCardObj: card
                });
                break;
        }
    }
    showSlider = (index) => {
        this.setState({
            sliderModal: true,
            slideIndex: index
        });
    }
    async fetchData() {
        this.setState({ isLoading: true });
        await this.showCards(true);
        await this.showAccounts();
        await this.showStatements();
        JSON.parse(localStorage.getItem('sendRequestCampaigns')) && await this.showCampains();
        this.setState({ isLoading: false });
    }
    closeSuccessModal = () => {
        return window.location.reload();
    };

    unblockFunc = () => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') == null ? 'Individual' : 'Juridical';
        let cardPinCodeUnblockRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            cardNumber: this.state.modalCardObj.cardNumber,
            cardId: this.state.modalCardObj.cardId,
            currency: this.state.modalCardObj.currency
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            cardNumber: this.state.modalCardObj.cardNumber,
            cardId: this.state.modalCardObj.cardId,
            currency: this.state.modalCardObj.currency
        }
        fetch(request("card/cardPinCodeUnblock", cardPinCodeUnblockRequestBody))
            .then(res => res.text())
            .then((cardPinCodeUnblockResponseJSON) => {
                const JSON = require('true-json-bigint');
                let cardPinCodeUnblockResponse = JSON.parse(cardPinCodeUnblockResponseJSON);
                if (cardPinCodeUnblockResponse.status.statusCode === 1) {
                    // =========FEE=========
                    let organizedFeeAmount;
                    if (cardPinCodeUnblockResponse.feeAmount.toString() === "0") {
                        organizedFeeAmount = {
                            wholePart: "0",
                            fractionalPart: "00"
                        }
                    }
                    else {
                        if (cardPinCodeUnblockResponse.feeAmount.toString().indexOf('.') !== -1) {
                            let currentBalanceArray = cardPinCodeUnblockResponse.feeAmount.toString().split('.');
                            organizedFeeAmount = {
                                wholePart: currentBalanceArray[0],
                                fractionalPart: (currentBalanceArray[1].length === 1) ? (currentBalanceArray[1] + '0') : currentBalanceArray[1]
                            }
                        }
                        else {
                            organizedFeeAmount = {
                                wholePart: cardPinCodeUnblockResponse.feeAmount.toString(),
                                fractionalPart: "00"
                            }
                        }
                    }
                    // ===============
                    let data = {
                        cardNumber: this.state.modalCardObj?.organizedCardNumber,
                        rrn: cardPinCodeUnblockResponse.rrn,
                        feeAmount: organizedFeeAmount,
                        billingDate: cardPinCodeUnblockResponse.billingDate,
                        pinUnblockAmount: cardPinCodeUnblockResponse.feeAmount
                    };
                    this.setState({ successModal: true, successModalData: data, isError: false, successTitle: this.props.t('Unblock pin success') });
                }
                else {
                    if (cardPinCodeUnblockResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(cardPinCodeUnblockResponse.status.statusCode));
                    }
                }
                this.props.updateAppState('isLoadingTransparentBackground', false);
            });
    }

   sendDigitalCardInfoSms = () =>{
      getDigitalCardInfo(
        this.props.updateAppState, 
        this.state.modalCardObj.cardId, 
        this.props.logout, 
        this.props.alertState, 
        this.props.t, 
        this.closeSuccessModal  
        );
   }

    // unblockConfirm(val) {
    //     if (val) {
    //         this.unblockFunc();
    //     }
    // }

    // unlockPin = () => {
    //     if(this.state.pinUnblockAmount) {
    //         this.setState({ unblockPinModalOpen: true });
    //     }
    //     else {
    //         this.unblockFunc();
    //     }
    // }
      
    cardSliderButtons = (status, type, cardId) => {
     if (status === 1) {
       return (
         <>
         {type !== cardTypes.DIGITAL_CARD && 
            <Link
             to={`/dashboard/finance/cards/income/${cardId}`}
             className="dashboard-card-section-content-button"
           >
             {this.props.t("Income")}
           </Link>}
           <Link
             to="/dashboard/payment/payments"
             className="dashboard-card-section-content-button"
           >
             {this.props.t("payment")}
           </Link>
           <Link
             to="/dashboard/transfer/card-operation"
             className="dashboard-card-section-content-button"
           >
             {this.props.t("transfer")}
           </Link>
           <Link
             to={`/dashboard/finance/cards/extract/${cardId}`}
             className="dashboard-card-section-content-button"
           >
             {this.props.t("history")}
           </Link>
         </>
       );
     } else if (status === 0) {
       return (
         <>
         {type !== cardTypes.DIGITAL_CARD && 
            <Link
             to={`/dashboard/finance/cards/income/${cardId}`}
             className="dashboard-card-section-content-button"
           >
             {this.props.t("Income")}
           </Link>}
           <button className="dashboard-card-section-content-button dashboard-card-section-content-button__disable">
             {this.props.t("payment")}
           </button>
           <button className="dashboard-card-section-content-button dashboard-card-section-content-button__disable">
             {this.props.t("transfer")}
           </button>
           <button className="dashboard-card-section-content-button dashboard-card-section-content-button__disable">
             {this.props.t("history")}
           </button>
         </>
       );
     } else {
       return (
         <div className="dashboard-add-new-card-section-content-invisible">
           <div className="dashboard-add-new-card-section-content-invisible-img-box"></div>
           <div className="dashboard-add-new-card-section-content-invisible-content">
             <div className="dashboard-add-new-card-section-content-invisible-content-title"></div>
             <div className="dashboard-add-new-card-section-content-invisible-content-subtitle"></div>
           </div>
         </div>
       );
     }
    }


    componentDidMount() {
        this.fetchData();
        !JSON.parse(localStorage.getItem('sendRequestCampaigns')) && this.setState({ campaigns: JSON.parse(localStorage.getItem('campaigns')) });
    }

    render() {
        let { cards, accounts, statements, campaigns, currentCard, open, modalCardObj, sliderModal, slideIndex, isLoading, successModalData, successModal, unblockPinModalOpen, pinUnblockAmount, visibility, campaignCustId, screenTypeId ,image,campaignId, note, openAddNewCardModal, openDigitalCardInfoModal } = this.state;
        let { updateAppState, updateDashboardState, t} = this.props;
        return (
            <Fragment>
                <News open={sliderModal} setOpen={(value) => { this.setState({ sliderModal: value }) }} slideIndex={slideIndex} campaigns={campaigns} />
                {visibility  &&  <CampaignPopup open={visibility} setOpen={(value) => { this.setState({ visibility: value }) }} {...{screenTypeId, campaignCustId, image,campaignId, note}} /> }
                <Modal open={open} modalCardObj={modalCardObj} updateOpenModal={this.updateOpenModal} updateAppState={updateAppState} showCards={this.showCards.bind(this)} />
                <AddNewCardModal open={openAddNewCardModal} translate={t} toggleAddNewCardModal={this.toggleAddNewCardModal}/>
                <DigitalCardInfoModal open={openDigitalCardInfoModal} translate={t} toggleDigitalCardInfoModal={this.toggleDigitalCardInfoModal} sendDigitalCardInfoSms={this.sendDigitalCardInfoSms} />
                {
                    isLoading ?
                        <div className="dashboard-container-main-section">
                            <Loading />
                        </div>
                        :
                        <>
                            {/* <PinUnblockSuccess history={this.props.history} data={successModalData} open={successModal} close={() => { this.setState({ successModal: false }) }} /> */}
                            {/* <YesNoModal onClose={() => this.setState({ unblockPinModalOpen: false })} title={t('Unblock pin content', { pinUnblockAmount: pinUnblockAmount })} open={unblockPinModalOpen} triggerFunc={val => this.unblockConfirm(val)} /> */}
                            <div className="dashboard-news-section">
                                {
                                    campaigns.map((campaign, index) => (
                                        <button className="dashboard-news-section-item" style={{ backgroundImage: `url(${campaign.image})` }} onClick={this.showSlider.bind(this, index)} key={index}></button>
                                    ))
                                }
                            </div>
                            <div className="dashboard-container-main-section" style={{ paddingTop: 0 }}>

                                <div className="dashboard-cards-account-section">
                                    {
                                        cards && 
                                            <div className="dashboard-card-section">
                                                <div className="dashboard-card-section-background">
                                                    <div className="dashboard-card-section-header">
                                                        <div className="dashboard-card-section-header-left-side">
                                                            {currentCard.cardType !== staticCardsTypes.ACTIVATE_DIGITAL_CARD_BTN && currentCard.cardType !== staticCardsTypes.ADD_CARDS_BTN  && <img src={setPaymentTypeIcon(currentCard.cardPaymentType, masterCardIcon, visaCardIcon)}
                                                                 className="dashboard-card-section-header-image" alt="cardPaymentType" />}
                                                           
                                                            <h3 className="dashboard-card-section-header-card-number">{
                                                            currentCard.cardType === staticCardsTypes.ACTIVATE_DIGITAL_CARD_BTN ? t("Digital card") : 
                                                            currentCard.cardType === staticCardsTypes.ADD_CARDS_BTN ? t("add a new card") : currentCard.organizedCardNumber}</h3>
                                                        </div>
                                                        <div className="dashboard-card-section-header-right-side">
                                                            {
                                                                currentCard.organizedCardNumber && currentCard.currency && currentCard.organizedCurrentBalance ?
                                                                    <>
                                                                        <span className={"dashboard-card-section-header-amount-currency dashboard-card-section-header-amount-currency__" + currentCard.currency.toLowerCase()}>{currentCard.currencyIcon}</span>
                                                                        <span className="dashboard-card-section-header-amount-whole-part">{currentCard.organizedCurrentBalance.wholePart}</span>
                                                                        <span className="dashboard-card-section-header-amount-fractional-part">{currentCard.organizedCurrentBalance.fractionalPart}</span>
                                                                    </>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="dashboard-card-section-content">
                                                        <CardSlider setPaymentTypeIcon={this.setPaymentTypeIcon} cards={cards} updateCurrentCard={this.updateCurrentCard} updateCardState={this.updateCardState} 
                                                        toggleAddNewCardModal={this.toggleAddNewCardModal}
                                                        />
                                                        <div className="dashboard-card-section-content-button-section">
                                                            { this.cardSliderButtons(currentCard.cardStatus,currentCard.cardType, currentCard.cardId) }
                                                            {
                                                                currentCard.cardStatus === 1 && currentCard.cardType !== cardTypes.DIGITAL_CARD && currentCard.cardType !== cardTypes.CARD_STORAGE ?
                                                                    <Dropdown
                                                                        className={"dropdown-button-operation full-height"}
                                                                        dropdownItems={[
                                                                            { title: t('extract'), url: `/dashboard/finance/cards/extract/${currentCard.cardId}` },
                                                                            { title: t('details'), url: "/dashboard/finance/cards/" + currentCard.cardId },
                                                                            { title: t('block') },
                                                                            {title: t("pin_settings"), url:"/dashboard/finance/cards/pin/settings/" + currentCard.cardId},
                                                                            {title: t("Limits management"), url: `/dashboard/finance/cards/limits/${currentCard.currency}/${currentCard.cardId} `}
                                                                        ]}
                                                                        cardId={currentCard.cardId}
                                                                        dropdownItemHandler={(i) => this.dropdownItemHandler(i, currentCard)} 
                                                                    /> 
                                                                    : currentCard.cardStatus === 1 && currentCard.cardType !== cardTypes.CARD_STORAGE ?
                                                                    <Dropdown
                                                                    className={"dropdown-button-operation full-height"}
                                                                    dropdownItems={[
                                                                        { title: t('extract'), url: "/dashboard/finance/cards/extract/" + currentCard.cardId },
                                                                        { title: t('details'), url: "/dashboard/finance/cards/" + currentCard.cardId },
                                                                        { title: t('block') },
                                                                        { title: this.props.t('Card information') }
                                                                    ]}
                                                                    cardId={currentCard.cardId}
                                                                    dropdownItemHandler={(i) => this.dropdownItemHandlerForDigital(i, currentCard)} 
                                                                /> : null
                                                                    
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                    }
                                    <div className="dashboard-account-section">
                                        <div className="dashboard-account-section-header">
                                            <h3 className="dashboard-account-section-header-title">{t('accounts')}</h3>
                                            <Link to="/dashboard/finance/accounts" className="dashboard-account-section-header-show-all">{t('all')}</Link>
                                        </div>
                                        {
                                            (accounts.length !== 0) ?
                                                <div className="dashboard-account-section-content">
                                                    <ul className="dashboard-account-section-content-list">
                                                        {
                                                            accounts.slice(0, 3).map((account, index) =>
                                                            (
                                                                <Link to={"/dashboard/finance/accounts/" + account.accountId} className="dashboard-account-section-content-list-item dashboard-account-section-content-list-item--visible" key={index}>
                                                                    <div className="dashboard-account-section-content-list-item-left-side dashboard-account-section-content-list-item-left-side--visible">
                                                                        <div className="dashboard-account-section-content-list-item-invisible-account-icon">
                                                                            <img src={paperIcon} className="dashboard-account-section-content-list-item-invisible-account-icon-image" alt="paperIcon" />
                                                                        </div>
                                                                        <div className="dashboard-account-section-content-list-item-invisible-account-name">
                                                                            <h4 className="dashboard-account-section-content-list-item-account-add">{t('current account')} ({account.currName})</h4>
                                                                            <p className="dashboard-account-section-content-list-item-account-number">{account.iban}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="dashboard-account-section-content-list-item-right-side dashboard-account-section-content-list-item-right-side--visible">
                                                                        <span className="dashboard-account-section-content-list-item-amount-whole-part">{account.currentBalance.wholePart}</span>
                                                                        <span className="dashboard-account-section-content-list-item-amount-fractional-part">{account.currentBalance.fractionalPart}</span>
                                                                        <span className="dashboard-account-section-content-list-item-amount-currency">{account.currName}</span>
                                                                    </div>
                                                                </Link>
                                                            )
                                                            )
                                                        }
                                                    </ul>
                                                </div>
                                                :
                                                <div className="dashboard-account-section-content">
                                                    <ul className="dashboard-account-section-content-list">
                                                        <li className="dashboard-account-section-content-list-item">
                                                            <div className="dashboard-account-section-content-list-item-left-side">
                                                                <div className="dashboard-account-section-content-list-item-invisible-account-icon">
                                                                    <img src={paperIcon} className="dashboard-account-section-content-list-item-invisible-account-icon-image" alt="paperIcon" />
                                                                </div>
                                                                <div className="dashboard-account-section-content-list-item-invisible-account-name">
                                                                    <h4 className="dashboard-account-section-content-list-item-account-add">{t('add a new account')}</h4>
                                                                    <p className="dashboard-account-section-content-list-item-account-number">{t('account number')}</p>
                                                                </div>
                                                            </div>
                                                            <div className="dashboard-account-section-content-list-item-right-side">
                                                                <div className="dashboard-account-section-content-list-item-invisible-amount"></div>
                                                                <div className="dashboard-account-section-content-list-item-invisible-currency"></div>
                                                            </div>
                                                        </li>
                                                        <li className="dashboard-account-section-content-list-item">
                                                            <div className="dashboard-account-section-content-list-item-left-side">
                                                                <div className="dashboard-account-section-content-list-item-invisible-account-icon">
                                                                    <img src={paperIcon} className="dashboard-account-section-content-list-item-invisible-account-icon-image" alt="paperIcon" />
                                                                </div>
                                                                <div className="dashboard-account-section-content-list-item-invisible-account-name">
                                                                    <div className="dashboard-account-section-content-list-item-invisible-account-add"></div>
                                                                    <div className="dashboard-account-section-content-list-item-invisible-account-number"></div>
                                                                </div>
                                                            </div>
                                                            <div className="dashboard-account-section-content-list-item-right-side">
                                                                <div className="dashboard-account-section-content-list-item-invisible-amount"></div>
                                                                <div className="dashboard-account-section-content-list-item-invisible-currency"></div>
                                                            </div>
                                                        </li>
                                                        <li className="dashboard-account-section-content-list-item">
                                                            <div className="dashboard-account-section-content-list-item-left-side">
                                                                <div className="dashboard-account-section-content-list-item-invisible-account-icon">
                                                                    <img src={paperIcon} className="dashboard-account-section-content-list-item-invisible-account-icon-image" alt="paperIcon" />
                                                                </div>
                                                                <div className="dashboard-account-section-content-list-item-invisible-account-name">
                                                                    <div className="dashboard-account-section-content-list-item-invisible-account-add"></div>
                                                                    <div className="dashboard-account-section-content-list-item-invisible-account-number"></div>
                                                                </div>
                                                            </div>
                                                            <div className="dashboard-account-section-content-list-item-right-side">
                                                                <div className="dashboard-account-section-content-list-item-invisible-amount"></div>
                                                                <div className="dashboard-account-section-content-list-item-invisible-currency"></div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                        }
                                    </div>
                                </div>
                                <div className="dashboard-last-operations-section">
                                    <div className="dashboard-last-operations-section-header">
                                        <h3 className="dashboard-last-operations-section-header-title">{t('recent operations')}</h3>
                                        {/* <button className="dashboard-last-operations-section-header-history">{t('history')}</button> */}
                                    </div>
                                    {
                                        (statements.length !== 0) ?
                                            <div className="dashboard-last-operations-section-content">
                                                <ul className="dashboard-last-operations-section-list">
                                                    {
                                                        statements.slice(0, 12).map((statement, index) =>
                                                            <li className="dashboard-last-operations-section-item" key={index}>
                                                                <div className="dashboard-last-operations-section-item-left-side">
                                                                    <div className="dashboard-last-operations-section-item-icon-container">
                                                                        <svg className="dashboard-last-operations-section-item-icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M0.625 0.75C0.40625 0.75 0.25 0.9375 0.25 1.125V5.625C0.25 5.84375 0.40625 6 0.625 6H5.125C5.3125 6 5.5 5.84375 5.5 5.625V5.3125C5.5 5.125 5.3125 4.9375 5.125 4.9375H2.03125C3.15625 2.8125 5.40625 1.3125 8 1.3125C11.6875 1.3125 14.6875 4.34375 14.6875 8C14.6875 11.7188 11.6562 14.6875 8 14.6875C6.25 14.6875 4.6875 14.0625 3.5 13C3.375 12.8438 3.125 12.875 3 13L2.78125 13.2188C2.625 13.375 2.625 13.625 2.78125 13.7812C4.15625 15 6 15.75 8 15.75C12.25 15.75 15.7188 12.3125 15.75 8.03125C15.75 3.78125 12.2812 0.28125 8 0.28125C5.15625 0.25 2.65625 1.8125 1.3125 4.09375V1.125C1.3125 0.9375 1.125 0.75 0.9375 0.75H0.625ZM10.6562 10.625L10.8125 10.4062C10.9375 10.25 10.9062 10 10.7188 9.90625L8.5 8.25V3.625C8.5 3.4375 8.3125 3.25 8.125 3.25H7.875C7.65625 3.25 7.5 3.4375 7.5 3.625V8.78125L10.125 10.6875C10.3125 10.8125 10.5312 10.7812 10.6562 10.625Z" fill="#849097" />
                                                                        </svg>
                                                                    </div>
                                                                    <div className="dashboard-last-operations-section-item-content">
                                                                        <div className="dashboard-last-operations-section-item-company">{statement.organizedCardNumber}</div>
                                                                        <div className="dashboard-last-operations-section-item-description">{statement.purpose}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="dashboard-last-operations-section-item-rigth-side">
                                                                    <div className="dashboard-last-operations-section-item-amount">
                                                                        <span className="dashboard-last-operations-section-item-amount-whole-part">{statement.amount.wholePart}</span>
                                                                        <span className="dashboard-last-operations-section-item-amount-fractional-part">{statement.amount.fractionalPart}</span>
                                                                        <span className="dashboard-last-operations-section-item-amount-currency">{statement.currency}</span>
                                                                    </div>
                                                                    <p className="dashboard-last-operations-section-item-category">{statement.trDate.split(' ')[0]}</p>
                                                                </div>
                                                            </li>
                                                        )
                                                    }
                                                </ul>
                                            </div>
                                            :
                                            <div className="dashboard-last-operations-section-content">
                                                <img src={lastOperationsIcon} className="dashboard-last-operations-section-content-image" alt="lastOperationsIcon" />
                                                <p className="dashboard-last-operations-section-content-status">{t('you do not currently have any active transactions')}</p>
                                                <ul className="dashboard-last-operations-section-content-invisible-list">
                                                    <li className="dashboard-last-operations-section-content-invisible-list-item">
                                                        <div className="dashboard-last-operations-section-content-invisible-list-item-circle"></div>
                                                        <div className="dashboard-last-operations-section-content-invisible-list-item-content">
                                                            <div className="dashboard-last-operations-section-content-invisible-list-item-content-title"></div>
                                                            <div className="dashboard-last-operations-section-content-invisible-list-item-content-subtitle"></div>
                                                        </div>
                                                    </li>
                                                    <li className="dashboard-last-operations-section-content-invisible-list-item">
                                                        <div className="dashboard-last-operations-section-content-invisible-list-item-circle"></div>
                                                        <div className="dashboard-last-operations-section-content-invisible-list-item-content">
                                                            <div className="dashboard-last-operations-section-content-invisible-list-item-content-title"></div>
                                                            <div className="dashboard-last-operations-section-content-invisible-list-item-content-subtitle"></div>
                                                        </div>
                                                    </li>
                                                    <li className="dashboard-last-operations-section-content-invisible-list-item">
                                                        <div className="dashboard-last-operations-section-content-invisible-list-item-circle"></div>
                                                        <div className="dashboard-last-operations-section-content-invisible-list-item-content">
                                                            <div className="dashboard-last-operations-section-content-invisible-list-item-content-title"></div>
                                                            <div className="dashboard-last-operations-section-content-invisible-list-item-content-subtitle"></div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                    }
                                </div>
                            </div>
                        </>
                }
            </Fragment>
        )
    }
}


export default translate("translation")(Home);