import React, { Component, Fragment } from 'react'
import { translate } from 'react-i18next';
import $ from 'jquery';
import OutsideClickHandler from 'react-outside-click-handler';

import { request } from '../../../../config';
import transferConfirmation from './../../img/transfer-confirmation.svg';
import NotFound from '../../../../copms/NotFound/NotFound';
import Loading from '../../Loading/Loading';
import currencyFilter from './../../../../helpers/currencyFilter';
import YesNoModal from './../../../../copms/Modal/YesNoModal';
import FriendOperationModal from '../../../../copms/Modal/FriendOperationModal';
import AcceptFriendTransferModal from '../../../../copms/Modal/AcceptFriendTransferModal';

export class FriendTransferToMe extends Component {
    state = {
        isLoading: false,
        transfer: {},
        dtCard: {},
        transferExistence: false,
        rejectOpen: false,

        acceptClicked: false,
        acceptConfirmed: false,

        cardData: false,
        dropdownVisibilityCard: false,
        cards: [],
        cardIdValidation: false,
        cardIdCount: 0,
        selectedCard: "",

        open: false,
        createFeedbackMessage: false,
        feedbackErrorModal: null,

        openCreateModal: false,
        modalData: null,
        successTitle: null,
        errorTitle: null,
        isError: false,
        info: null
    }

    base64ToArrayBuffer(byteArray, filename) {
        let bstr = atob(byteArray);
        let n = bstr.length;
        let u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename);
    }

    saveFile = (name, type, data) => {
        if (data !== null && navigator.msSaveBlob)
            return navigator.msSaveBlob(new Blob([data], { type: type }), name);
        var a = $("<a style='display: none;'/>");
        var url = window.URL.createObjectURL(new Blob([data], { type: type }));
        a.attr("href", url);
        a.attr("download", name);
        $("body").append(a);
        a[0].click();
        window.URL.revokeObjectURL(url);
        a.remove();
    }

    changeDropdownVisibilityCard = (event) => {
        event.preventDefault();
        this.setState({ dropdownVisibilityCard: !this.state.dropdownVisibilityCard }, () => {
            if (!this.state.cardIdValidation) {
                this.setState({ cardIdCount: ++this.state.cardIdCount });
            }
        });
    }

    updateCard(id, number, event) {
        event.preventDefault();
        this.setState({ cardId: id, cardNumber: number, selectedCard: event.target.textContent, dropdownVisibilityCard: false, cardIdValidation: true });
    }

    rejectTransfer = (value) => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        this.setState({
            open: false,
            createFeedbackMessage: false,
            feedbackErrorModal: null
        });
        if (value) {
            let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
            let rejectTransferToMedRequestBody = customerType === 'Juridical' ? {
                custId: localStorage.getItem('custId'),
                compId: localStorage.getItem('compId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase(),
                cardOperId: this.state.transfer.cardOperId,
                dtCardId: this.state.transfer.dtCardId,
                type: 2
            } : {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase(),
                cardOperId: this.state.transfer.cardOperId,
                dtCardId: this.state.transfer.dtCardId,
                type: 2
            }
            fetch(request("card/rejectTransferToFriendCrCust", rejectTransferToMedRequestBody))
                .then(res => res.text())
                .then((rejectTransferToMedResponseJSON) => {
                    const JSON = require('true-json-bigint');
                    let rejectTransferToMedResponse = JSON.parse(rejectTransferToMedResponseJSON);
                    if (rejectTransferToMedResponse.status.statusCode !== 1) {
                        if (rejectTransferToMedResponse.status.statusCode === 200) {
                            this.props.logout();
                        } else {
                            this.props.updateAppState('isLoadingTransparentBackground', false);
                            this.setState({ createFeedbackMessage: false, open: true, feedbackErrorModal: this.props.t(rejectTransferToMedResponse.status.statusCode) });
                        }
                    }
                    else {
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                        this.setState({ createFeedbackMessage: true, open: true });
                    }
                },
                    (error) => {
                        this.props.history.push("/technical-break");
                    });
        }
    }

    acceptTransfer = () => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        this.setState({
            open: false,
            createFeedbackMessage: false,
            feedbackErrorModal: null,

            openCreateModal: false,
            modalData: null,
            successTitle: null,
            errorTitle: null,
            isError: false
        });
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let acceptTransferToMedRequestBody = customerType === 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            cardOperId: this.state.transfer.cardOperId,
            dtAmount: this.state.transfer.dtAmount,
            crCardId: this.state.cardId,
            crCcy: this.state.transfer.dtCCY,
            crCustId: this.state.transfer.crCustId
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            cardOperId: this.state.transfer.cardOperId,
            dtAmount: this.state.transfer.dtAmount,
            crCardId: this.state.cardId,
            crCcy: this.state.transfer.dtCCY,
            crCustId: this.state.transfer.crCustId
        }
        fetch(request("card/acceptTransferToFriend", acceptTransferToMedRequestBody))
            .then(res => res.text())
            .then((acceptTransferToMedResponseJSON) => {
                const JSON = require('true-json-bigint');
                let acceptTransferToMedResponse = JSON.parse(acceptTransferToMedResponseJSON);
                if (acceptTransferToMedResponse.status.statusCode !== 1) {
                    if (acceptTransferToMedResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.updateAppState('isLoadingTransparentBackground', false);

                        if (acceptTransferToMedResponse.custId === null || acceptTransferToMedResponse.status.statusCode === 100) {
                            this.setState({ createFeedbackMessage: false, open: true, feedbackErrorModal: this.props.t(acceptTransferToMedResponse.status.statusCode) });
                        }
                        else {
                            // =========NUMBER=========
                            if (acceptTransferToMedResponse.crCardNumber) {
                                acceptTransferToMedResponse.organizedCardNumber = acceptTransferToMedResponse.crCardNumber.substring(0, 4)
                                    + " **** **** " + acceptTransferToMedResponse.crCardNumber.substring(12, 16);
                            }

                            // =========AMOUNT=========
                            if (acceptTransferToMedResponse.dtAmount.toString() === "0") {
                                acceptTransferToMedResponse.organizedAmount = {
                                    wholePart: "0",
                                    fractionalPart: "00"
                                }
                            }
                            else {
                                if (acceptTransferToMedResponse.dtAmount.toString().indexOf('.') !== -1) {
                                    let currentBalanceArray = acceptTransferToMedResponse.dtAmount.toString().split('.');
                                    acceptTransferToMedResponse.organizedAmount = {
                                        wholePart: currentBalanceArray[0],
                                        fractionalPart: (currentBalanceArray[1].length === 1) ? (currentBalanceArray[1] + '0') : currentBalanceArray[1]
                                    }
                                }
                                else {
                                    acceptTransferToMedResponse.organizedAmount = {
                                        wholePart: acceptTransferToMedResponse.dtAmount.toString(),
                                        fractionalPart: "00"
                                    }
                                }
                            }
                            this.setState({ openCreateModal: true, modalData: acceptTransferToMedResponse, isError: true, errorTitle: this.props.t(acceptTransferToMedResponse.status.statusCode) });
                        }
                    }
                }
                else {
                    // =========NUMBER=========
                    acceptTransferToMedResponse.organizedCardNumber = acceptTransferToMedResponse.dtCardNumber.substring(0, 4)
                        + " **** **** " + acceptTransferToMedResponse.dtCardNumber.substring(12, 16);

                    // =========AMOUNT=========
                    if (acceptTransferToMedResponse.dtAmount.toString() === "0") {
                        acceptTransferToMedResponse.organizedAmount = {
                            wholePart: "0",
                            fractionalPart: "00"
                        }
                    }
                    else {
                        if (acceptTransferToMedResponse.dtAmount.toString().indexOf('.') !== -1) {
                            let currentBalanceArray = acceptTransferToMedResponse.dtAmount.toString().split('.');
                            acceptTransferToMedResponse.organizedAmount = {
                                wholePart: currentBalanceArray[0],
                                fractionalPart: (currentBalanceArray[1].length === 1) ? (currentBalanceArray[1] + '0') : currentBalanceArray[1]
                            }
                        }
                        else {
                            acceptTransferToMedResponse.organizedAmount = {
                                wholePart: acceptTransferToMedResponse.dtAmount.toString(),
                                fractionalPart: "00"
                            }
                        }
                    }

                    this.props.updateAppState('isLoadingTransparentBackground', false);
                    this.setState({ openCreateModal: true, modalData: acceptTransferToMedResponse, isError: false, successTitle: this.props.t('The payment order was accepted successfully') });

                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    downloadTariffPdf = () => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        fetch(request("util/downloadTariffPdf", null, "GET"))
            .then(res => res.json())
            .then((downloadExcelExampleResponse) => {
                if (downloadExcelExampleResponse.status.statusCode !== 1) {
                    if (downloadExcelExampleResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(downloadExcelExampleResponse.status.statusCode));
                    }
                }
                else {
                    let arrrayBuffer = this.props.base64ToArrayBuffer(downloadExcelExampleResponse.bytes); //data is the base64 encoded string
                    let blob = new Blob([arrrayBuffer], { type: "application/pdf" });
                    let link = window.URL.createObjectURL(blob);
                    var newWin = window.open(link, '');
                    if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
                        this.props.saveFile(this.props.t('Qaydalar') + ".pdf", "application/pdf", arrrayBuffer)
                    }
                }
                this.props.updateAppState('isLoadingTransparentBackground', false);
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    getCardOperationInfo = async () => {
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getCardOperationInfoRequest = customerType === 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            cardOperationType: "TRANSFER_TO_FRIEND"
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            cardOperationType: "TRANSFER_TO_FRIEND"
        }
        await fetch(request("util/getCardOperationInfo", getCardOperationInfoRequest))
            .then(res => res.json())
            .then((getCardOperationInfoResponse) => {
                if (getCardOperationInfoResponse.status.statusCode !== 1) {
                    if (getCardOperationInfoResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getCardOperationInfoResponse.status.statusCode));
                    }
                }
                else {
                    this.setState({ info: getCardOperationInfoResponse.info });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }


    updateOpen = (value) => {
        this.setState({ open: value });
    }

    showCards = async () => {
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getCardListCustomerRequestBody = customerType === 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            dtCcy: this.state.transfer.dtCCY
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            dtCcy: this.state.transfer.dtCCY
        }

        return await fetch(request("card/getCardListforDestCust", getCardListCustomerRequestBody))
            .then(res => res.text())
            .then((getCardListforDestCustResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getCardListforDestCustResponse = JSON.parse(getCardListforDestCustResponseJSON);
                if (getCardListforDestCustResponse.status.statusCode !== 1 && getCardListforDestCustResponse.status.statusCode !== 137) {
                    if (getCardListforDestCustResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getCardListforDestCustResponse.status.statusCode));
                    }
                }
                else {
                    if (getCardListforDestCustResponse.status.statusCode === 137) {
                        getCardListforDestCustResponse.cardList = [];
                    }
                    for (let i = 0; i < getCardListforDestCustResponse.cardList.length; i++) {
                        // =========ID=========
                        let cardId = "";
                        if (typeof (getCardListforDestCustResponse.cardList[i].cardId) === 'object' && getCardListforDestCustResponse.account[i].cardId.hasOwnProperty('c')) {
                            getCardListforDestCustResponse.account[i].id.c.forEach(part => {
                                cardId += part;
                            });
                        }
                        else {
                            cardId += getCardListforDestCustResponse.cardList[i].cardId;
                        }
                        getCardListforDestCustResponse.cardList[i].cardId = cardId;
                        // =========NUMBER=========
                        getCardListforDestCustResponse.cardList[i].organizedCardNumber = getCardListforDestCustResponse.cardList[i].cardNumber.substring(0, 4)
                            + " **** **** " + getCardListforDestCustResponse.cardList[i].cardNumber.substring(12, 16);

                    }
                    this.setState({ cards: getCardListforDestCustResponse.cardList, cardData: true });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    showTransfer = async () => {
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getTransferByIdRequestBody = customerType === 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),

            cardOperId: this.props.match.params.id
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),

            cardOperId: this.props.match.params.id
        }
        return await fetch(request(`card/getTransferToMeById`, getTransferByIdRequestBody))
            .then(res => res.text())
            .then((getTransferByIdResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getTransferByIdResponse = JSON.parse(getTransferByIdResponseJSON);
                if (getTransferByIdResponse.status.statusCode !== 1) {
                    if (getTransferByIdResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.setState({ createFeedbackMessage: false, open: true, feedbackErrorModal: this.props.t(getTransferByIdResponse.status.statusCode) });
                    }
                }
                else {
                    getTransferByIdResponse.organizedCardNumber = getTransferByIdResponse.dtCardNumber.substring(0, 4)
                        + " **** **** " + getTransferByIdResponse.dtCardNumber.substring(12, 16);
                    // =========BALANCE=========
                    if (getTransferByIdResponse.dtAmount.toString() === "0") {
                        getTransferByIdResponse.organizedAmount = {
                            wholePart: "0",
                            fractionalPart: "00"
                        }
                    }
                    else {
                        if (getTransferByIdResponse.dtAmount.toString().indexOf('.') !== -1) {
                            let currentBalanceArray = getTransferByIdResponse.dtAmount.toString().split('.');
                            getTransferByIdResponse.organizedAmount = {
                                wholePart: currentBalanceArray[0],
                                fractionalPart: (currentBalanceArray[1].length === 1) ? (currentBalanceArray[1] + '0') : currentBalanceArray[1]
                            }
                        }
                        else {
                            getTransferByIdResponse.organizedAmount = {
                                wholePart: getTransferByIdResponse.dtAmount.toString(),
                                fractionalPart: "00"
                            }
                        }
                    }
                    this.setState({ transfer: getTransferByIdResponse, transferExistence: true }, () => {
                        this.showCards();
                    });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    componentDidMount = async () => {
        this.setState({ isLoading: true });
        await this.showTransfer();
        await this.getCardOperationInfo();
        this.setState({ isLoading: false });
    }

    render() {
        let { t, history } = this.props;
        let { isLoading, transfer, transferExistence, rejectOpen,
            open, createFeedbackMessage, feedbackErrorModal,
            acceptClicked, acceptConfirmed,
            cardData, dropdownVisibilityCard, cards, cardIdValidation, cardIdCount, selectedCard,
            openCreateModal, modalData, isError, errorTitle, successTitle, info
        } = this.state;
        return (
            <Fragment>
                <AcceptFriendTransferModal data={modalData} open={openCreateModal}
                    close={() => this.setState({ openCreateModal: false })} isError={isError}
                    successTitle={successTitle} errorTitle={errorTitle}
                    errorClose={() => { this.setState({ acceptClicked: false, acceptConfirmed: false }) }}
                    successClose={() => { history.push('/dashboard/transfer/card-operation/friend-transfer/history/2') }} />
                <FriendOperationModal open={open} updateOpen={this.updateOpen} successClose={() => { history.push('/dashboard/transfer/card-operation/friend-transfer/history/2') }} errorClose={() => { this.setState({ acceptClicked: false, acceptConfirmed: false }) }} createFeedbackMessage={createFeedbackMessage} feedbackErrorModal={feedbackErrorModal} history={history} />
                <YesNoModal onClose={() => this.setState({ rejectOpen: false })} title={t('Are you sure you want to cancel the operation?')} open={rejectOpen} triggerFunc={val => this.rejectTransfer(val)} />
                <div className="dashboard-header-route">
                    <div className="dashboard-header-route-left">
                        <button onClick={() => this.props.history.goBack()}>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.7344 21.2188L12.6719 20.3281C12.9062 20.0938 12.9062 19.7188 12.6719 19.5312L5.40625 12.2188H21.4375C21.7188 12.2188 22 11.9844 22 11.6562V10.3438C22 10.0625 21.7188 9.78125 21.4375 9.78125H5.40625L12.6719 2.51562C12.9062 2.32812 12.9062 1.95312 12.6719 1.71875L11.7344 0.828125C11.5469 0.59375 11.1719 0.59375 10.9375 0.828125L1.14062 10.625C0.90625 10.8594 0.90625 11.1875 1.14062 11.4219L10.9375 21.2188C11.1719 21.4531 11.5469 21.4531 11.7344 21.2188Z" fill="#2E3131" />
                            </svg>
                        </button>
                        <h2>{t('Transfer to friend')}</h2>
                    </div>
                    <button onClick={() => this.downloadTariffPdf()} className="dashboard-header-route-link">{t('Tariffs')}</button>
                </div>
                {
                    isLoading ?
                        <div className="dashboard-container-main-section">
                            <Loading />
                        </div>
                        :
                        // (!(items && items.length > 0)) &&
                        !transferExistence ?
                            <NotFound />
                            :
                            <div className="dashboard-payment-container">
                                <div className="dashboard-payment-container-data-section">
                                    <div className="dashboard-payment-container-data-section-header">
                                        <h3 className="dashboard-payment-container-data-section-header-title">{t('Make a transfer')}</h3>
                                    </div>
                                    <div className="dashboard-payment-container-data-section-content">
                                        <div className="dashboard-payment-container-data-section-content-container">
                                            <h3 className="dashboard-payment-container-data-section-content-container-title" style={{ color: '#0179D2' }}>{t('Transfer sender')}</h3>
                                            <div className="dashboard-payment-container-data-section-content-container-form dashboard-payment-container-data-section-content-container-form__border-bottom">
                                                <div className="dashboard-payment-container-data-section-content-container-form-group">
                                                    <div className="dashboard-payment-container-data-section-content-container-form-group-box">
                                                        <p className="dashboard-payment-container-data-section-content-container-form-group-title" style={{ color: '#586268' }}>{t('Fullname')}</p>
                                                        <p className="dashboard-payment-container-data-section-content-container-form-group-data">{transfer && transfer?.dtCustName}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dashboard-payment-container-data-section-content-container">
                                            <h3 className="dashboard-payment-container-data-section-content-container-title" style={{ color: '#0179D2' }}>{t('Transfer settings')}</h3>
                                            <div className="dashboard-payment-container-data-section-content-container-form dashboard-payment-container-data-section-content-container-form__border-bottom">
                                                <div className="dashboard-payment-container-data-section-content-container-form-group">
                                                    <div className="dashboard-payment-container-data-section-content-container-form-group-box">
                                                        <p className="dashboard-payment-container-data-section-content-container-form-group-title" style={{ color: '#586268' }}>{t('Amount')}</p>
                                                        <p className="dashboard-payment-container-data-section-content-container-form-group-data">{transfer && transfer?.dtAmount} {transfer && transfer?.dtCCY}</p>
                                                    </div>
                                                </div>
                                                {
                                                    transfer && transfer?.comment &&
                                                    <div className="dashboard-payment-container-data-section-content-container-form-group">
                                                        <div className="dashboard-payment-container-data-section-content-container-form-group-box">
                                                            <p className="dashboard-payment-container-data-section-content-container-form-group-title" style={{ color: '#586268' }}>{t('Message')}</p>
                                                            <p className="dashboard-payment-container-data-section-content-container-form-group-data">{transfer && transfer?.comment}</p>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        {
                                            transfer && transfer?.operStatus === 4 &&
                                            (
                                                !acceptClicked ?
                                                    <div className="dashboard-payment-container-data-section-content-container">
                                                        <div className="dashboard-payment-container-data-section-content-container-form">
                                                            <div className="friend-transfer-button-container">
                                                                <button className="friend-transfer-button friend-transfer-button__accept" onClick={() => { this.setState({ acceptClicked: true }) }}>
                                                                    <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M19.3906 0.9375L7.0625 13.2656L2.5625 8.71875C2.32812 8.53125 1.95312 8.53125 1.76562 8.71875L0.40625 10.0781C0.21875 10.2656 0.21875 10.6406 0.40625 10.875L6.6875 17.1094C6.92188 17.3438 7.25 17.3438 7.48438 17.1094L21.5469 3.04688C21.7344 2.85938 21.7344 2.48438 21.5469 2.25L20.1875 0.9375C20 0.703125 19.625 0.703125 19.3906 0.9375Z" fill="white" />
                                                                    </svg>
                                                                </button>
                                                                <button className="friend-transfer-button friend-transfer-button__reject" onClick={() => { this.setState({ rejectOpen: true }) }}>
                                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M10.0469 8L14.875 3.21875L15.8594 2.23438C16 2.09375 16 1.85938 15.8594 1.67188L14.8281 0.640625C14.6406 0.5 14.4062 0.5 14.2656 0.640625L8.5 6.45312L2.6875 0.640625C2.54688 0.5 2.3125 0.5 2.125 0.640625L1.09375 1.67188C0.953125 1.85938 0.953125 2.09375 1.09375 2.23438L6.90625 8L1.09375 13.8125C0.953125 13.9531 0.953125 14.1875 1.09375 14.375L2.125 15.4062C2.3125 15.5469 2.54688 15.5469 2.6875 15.4062L8.5 9.59375L13.2812 14.4219L14.2656 15.4062C14.4062 15.5469 14.6406 15.5469 14.8281 15.4062L15.8594 14.375C16 14.1875 16 13.9531 15.8594 13.8125L10.0469 8Z" fill="white" />
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="dashboard-payment-container-data-section-content-container">
                                                        <h3 className="dashboard-payment-container-data-section-content-container-title" style={{ color: '#0179D2' }}>{t('Choose a card for transfer')}</h3>
                                                        <div className="dashboard-payment-container-data-section-content-container-form">
                                                            <div className="dashboard-payment-container-data-section-content-container-form-container">

                                                                <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                                    <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Transfer to card')} <span style={{ color: 'red' }}>*</span></label>
                                                                    <OutsideClickHandler onOutsideClick={() => { this.setState({ dropdownVisibilityCard: false }) }}>
                                                                        <div className={"dashboard-payment-container-data-section-content-container-form-select-box " + (cardIdCount > 1 && !cardIdValidation || cardIdCount === 1 && !cardIdValidation && !dropdownVisibilityCard ? "input-outline" : "")}>
                                                                            <button className={acceptConfirmed ? "dashboard-payment-container-data-section-content-container-form-select-box-button dashboard-payment-container-data-section-content-container-form-select-box-button__disable" : "dashboard-payment-container-data-section-content-container-form-select-box-button"} disabled={Boolean(acceptConfirmed)} onClick={this.changeDropdownVisibilityCard}>
                                                                                {
                                                                                    selectedCard ?
                                                                                        <div className="dashboard-payment-container-data-section-content-container-form-select">{selectedCard}</div>
                                                                                        :
                                                                                        (cards && cards.length === 0 && cardData) ?
                                                                                            <div className="dashboard-payment-container-data-section-content-container-form-select">{t('The card list is empty')}</div>
                                                                                            :
                                                                                            <div className="dashboard-payment-container-data-section-content-container-form-select" style={{ color: '#BDC3C7' }}>{t("Choose")}</div>
                                                                                }
                                                                                <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                                                    <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                                                    </svg>
                                                                                </div>
                                                                            </button>
                                                                            <ul className={(dropdownVisibilityCard === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                                                {
                                                                                    cards && cards.length > 0 &&
                                                                                    cards.map((card, index) => {
                                                                                        return (
                                                                                            <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={index}>
                                                                                                <button onClick={this.updateCard.bind(this, card.cardId, card.cardNumber)} className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button" disabled={card.currency !== this.state.transfer.dtCCY}>{card.organizedCardNumber} / {card.balance} {card.currency}</button>
                                                                                            </li>
                                                                                        );
                                                                                    })
                                                                                }

                                                                            </ul>
                                                                        </div>
                                                                    </OutsideClickHandler>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                            )
                                        }

                                    </div>
                                </div>
                                {
                                    acceptConfirmed === false ?
                                        <div className="dashboard-payment-container-confirmation-section">
                                            <div className="dashboard-payment-container-confirmation-section-header">
                                                <h3 className="dashboard-payment-container-confirmation-section-header-title">{t('How to transfer to a friend')}</h3>
                                            </div>
                                            <div className="dashboard-payment-container-confirmation-section-content">
                                                <img src={transferConfirmation} className="dashboard-payment-container-confirmation-section-content-image" alt="transferConfirmationImg" />
                                                <div className="dashboard-payment-container-confirmation-section-content-description">
                                                    <p dangerouslySetInnerHTML={{ __html: info }}></p>
                                                </div>
                                                {
                                                    acceptClicked &&
                                                    <div className="dashboard-payment-container-data-section-content-container-form-footer">
                                                        {
                                                            cardIdValidation ?
                                                                <button className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__blue dashboard-payment-container-data-section-content-container-form-footer-button__left" onClick={() => { this.setState({ acceptConfirmed: true }) }}>{t('continue')}</button>
                                                                :
                                                                <button className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__disable dashboard-payment-container-data-section-content-container-form-footer-button__left">{t('continue')}</button>

                                                        }
                                                        <button onClick={() => this.setState({ acceptClicked: false })} className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__gray dashboard-payment-container-data-section-content-container-form-footer-button__right">{t('cancel')}</button>
                                                    </div>
                                                }

                                            </div>
                                        </div>
                                        :
                                        <div className="dashboard-payment-container-confirmation-section">
                                            <div className="dashboard-payment-container-confirmation-section-header">
                                                <h3 className="dashboard-payment-container-confirmation-section-header-title">{t('Confirmation of transfer')}</h3>
                                            </div>
                                            <div className="dashboard-payment-container-confirmation-section-content">
                                                <h3 className="dashboard-payment-container-confirmation-section-amount">
                                                    <span className={"dashboard-payment-container-confirmation-section-amount-currency dashboard-payment-container-confirmation-section-amount-currency__" + transfer.dtCCY?.toLocaleLowerCase()}>{currencyFilter(transfer.dtCCY)}</span>
                                                    <span className="dashboard-payment-container-confirmation-section-amount-whole">{transfer?.organizedAmount?.wholePart}</span>
                                                    <span className="dashboard-payment-container-confirmation-section-amount-fractional">{transfer?.organizedAmount?.fractionalPart}</span>
                                                </h3>
                                                <ul className="dashboard-payment-container-confirmation-section-list">
                                                    <li className="dashboard-payment-container-confirmation-section-list-item">
                                                        <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Sender name')}</p>
                                                        <p className="dashboard-payment-container-data-section-content-container-form-group-data">{transfer && transfer?.dtCustName}</p>
                                                    </li>
                                                    <li className="dashboard-payment-container-confirmation-section-list-item">
                                                        <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Incoming card')}</p>
                                                        <p className="dashboard-payment-container-data-section-content-container-form-group-data">{transfer && this.state.selectedCard}</p>
                                                    </li>
                                                    <li className="dashboard-payment-container-confirmation-section-list-item">
                                                        <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Amount')}</p>
                                                        <p className="dashboard-payment-container-data-section-content-container-form-group-data">{transfer && transfer.dtAmount && transfer.dtAmount.toFixed(2)} {transfer.dtCCY}</p>
                                                    </li>
                                                    {
                                                        transfer && transfer.comment &&
                                                        <li className="dashboard-payment-container-confirmation-section-list-item">
                                                            <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Message')}</p>
                                                            <p className="dashboard-payment-container-data-section-content-container-form-group-data">{transfer.comment}</p>
                                                        </li>
                                                    }
                                                </ul>
                                                <div className="dashboard-payment-container-data-section-content-container-form-footer">
                                                    <button className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__blue dashboard-payment-container-data-section-content-container-form-footer-button__left" onClick={this.acceptTransfer}>{t('continue')}</button>
                                                    <button onClick={() => { this.setState({ acceptConfirmed: false, cardIdValidation: false, cardIdCount: 0, selectedCard: "" }) }} className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__gray dashboard-payment-container-data-section-content-container-form-footer-button__right">{t('cancel')}</button>
                                                </div>
                                            </div>
                                        </div>
                                }
                            </div>
                }
            </Fragment>
        )
    }
}

export default translate("translation")(FriendTransferToMe);
