import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import $ from 'jquery';
import InputMask from "react-input-mask";
import InputAdornment from '@mui/material/InputAdornment';
import { request } from '../../../config';
import CreateSalaryOrderModal from './../../../copms/Modal/CreateSalaryOrderModal';
import YesNoModal from '../../../copms/Modal/YesNoModal';

function base64ToArrayBuffer(base64) {
    let binaryString = window.atob(base64);
    let binaryLen = binaryString.length;
    let bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
        let ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes;
}
function saveFile(name, type, data) {
    if (data != null && navigator.msSaveBlob)
        return navigator.msSaveBlob(new Blob([data], { type: type }), name);
    var a = $("<a style='display: none;'/>");
    var url = window.URL.createObjectURL(new Blob([data], { type: type }));
    a.attr("href", url);
    a.attr("download", name);
    $("body").append(a);
    a[0].click();
    window.URL.revokeObjectURL(url);
    a.remove();
}

class Order extends Component {
    state = {
        status: 1,
        payload: null,
        orders: [{}],
        formData: null,
        openSuccess: false,
        open: false,
    }

    componentDidMount() {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let getBatchSalaryPayloadRequestBody = {
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
        }
        fetch(request("salary/getBatchSalaryPayload", getBatchSalaryPayloadRequestBody))
            .then(res => res.text())
            .then((getBatchSalaryPayloadResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getBatchSalaryPayloadResponse = JSON.parse(getBatchSalaryPayloadResponseJSON);
                if (getBatchSalaryPayloadResponse.status.statusCode != 1) {
                    if (getBatchSalaryPayloadResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getBatchSalaryPayloadResponse.status.statusCode));
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                    this.setState({
                        payload: getBatchSalaryPayloadResponse.payload,
                        orders: [{}],
                        formData: { service: getBatchSalaryPayloadResponse.payload.services[0], legalStatus: { value: getBatchSalaryPayloadResponse.payload.legalStatus[0] }, orderType: 1 },
                    }, () => {
                        if (this.props.match.params.id) {
                            this.getOrder(this.props.match.params.id);
                        }
                    });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            window.location.reload(); // Reload the page
        }
    }

    downloadFile = () => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let getExcelFileTemplateRequestBody = {
            docType: this.state.formData.service.docType,
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
        }
        fetch(request("salary/getExcelFileTemplate", getExcelFileTemplateRequestBody))
            .then(res => res.text())
            .then((getExcelFileTemplateResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getExcelFileTemplateResponse = JSON.parse(getExcelFileTemplateResponseJSON);
                if (getExcelFileTemplateResponse.status.statusCode != 1) {
                    if (getExcelFileTemplateResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getExcelFileTemplateResponse.status.statusCode));
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                    let arrrayBuffer = base64ToArrayBuffer(getExcelFileTemplateResponse.template.fileTemplate);
                    saveFile(getExcelFileTemplateResponse.template.documentTypeName + `.${getExcelFileTemplateResponse.template.fileExtension}`, `application/${getExcelFileTemplateResponse.template.fileExtension}`, arrrayBuffer)
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    getOrder = (id) => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let getBatchSalaryOrderByIdRequestBody = {
            id,
            token: localStorage.getItem('token')
        }
        fetch(request("salary/getBatchSalaryOrderById", getBatchSalaryOrderByIdRequestBody))
            .then(res => res.text())
            .then((getBatchSalaryOrderByIdResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getBatchSalaryOrderByIdResponse = JSON.parse(getBatchSalaryOrderByIdResponseJSON);
                if (getBatchSalaryOrderByIdResponse.status.statusCode != 1) {
                    if (getBatchSalaryOrderByIdResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getBatchSalaryOrderByIdResponse.status.statusCode));
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                    const transformedOrders = getBatchSalaryOrderByIdResponse.order.data?.map(item => {
                        const transformedItem = {};
                        Object.keys(item).forEach(key => {
                            transformedItem[key] = { value: item[key] };
                        });

                        return transformedItem;
                    });
                    this.setState({
                        formData: {
                            service: getBatchSalaryOrderByIdResponse.order.service, orderType: getBatchSalaryOrderByIdResponse.order.data ? 2 : 1,
                            legalStatus: { value: getBatchSalaryOrderByIdResponse.order.legalStatus || this.state.payload.legalStatus[0] },
                            duration: { value: getBatchSalaryOrderByIdResponse.order.duration }, branch: { value: getBatchSalaryOrderByIdResponse.order.branch },
                            account: { value: getBatchSalaryOrderByIdResponse.order.account }, excelFile: { name: getBatchSalaryOrderByIdResponse.order.fileName, value: getBatchSalaryOrderByIdResponse.order.document },
                            note: { value: getBatchSalaryOrderByIdResponse.order.note },
                        },
                        orders: getBatchSalaryOrderByIdResponse.order.data ? transformedOrders : [{}]
                    })
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    chooseFile = (event, helperText) => {
        if (event && event.target && event.target.files && event.target.files[0] && event.target.files[0].name) {
            this.getBase64(event.target.files[0], (result) => {
                this.setState({ formData: { ...this.state.formData, excelFile: { value: result.split('base64,')[1] } } });
            });
            this.setState({ formData: { ...this.state.formData, excelFile: { ...this.state.formData.excelFile, name: event.target.files[0].name, helperText: event.target.files[0] ? "" : helperText } } });
        }
    }

    removeFile = () => {
        this.setState({ formData: { ...this.state.formData, excelFile: { value: null, name: "", helperText: "text" } } });
    }

    getBase64(file, cb) {
        if (file) {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                cb(reader.result)
            };
            reader.onerror = function (error) {
            };
        }
    }

    setValues = (e, helperText, selectName, minLength, maxLength) => {
        const name = selectName ?? e.target.name
        const value = selectName ? e : e.target.value
        let newFormData = {};
        newFormData = {
            ...this.state.formData,
            [name]: {
                value: value,
                helperText: value ? "" : helperText
            }
        };
        this.setState({ formData: newFormData })
    };

    setOrder = (index, e, helperText, selectName, minLength, maxLength) => {
        const name = selectName ?? e.target.name
        const value = selectName ? e : name == 'mobile' ? this.getPhoneNumber(e.target.value) : e.target.value
        const newOrders = [...this.state.orders];
        newOrders[index][name] = {
            value: value,
            helperText: (value || name == 'email') ? "" : helperText
        }
        this.setState({
            orders: newOrders

        })
    };

    handleOrderInvalid = (index, e, helperText, selectName) => {
        e.preventDefault();
        const name = selectName ?? e.target.name;
        const newOrders = [...this.state.orders];
        newOrders[index][name] = {
            ...newOrders[index][name],
            helperText: helperText
        }
        this.setState({
            orders: newOrders
        })
    };

    handleInvalid = (e, helperText, selectName) => {
        e.preventDefault();
        const name = selectName ?? e.target.name;
        const newFormData = {
            ...this.state.formData,
            [name]: {
                ...this.state.formData[name],
                helperText: helperText
            }
        };
        this.setState({
            formData: newFormData
        })
    };

    addOrder = () => {
        this.state.orders.length == 5 ?
            this.props.alertState(true, 'error', this.props.t('error'), this.props.t('You can add a maximum of 5 employees on individual orders'))
            :
            this.setState({
                orders: [...this.state.orders, {}]
            });
    };

    deleteOrder = (index) => {
        const newOrders = [...this.state.orders];
        newOrders.splice(index, 1);
        this.setState({ orders: newOrders });
    };

    getPhoneNumber = (number) => {
        ['+', '-', '(', ')', ' '].forEach(char => {
            while (number.indexOf(char) !== -1) {
                number = number.slice(0, number.indexOf(char)) + number.slice(number.indexOf(char) + 1, number.length);
            }
        });
        return number;
    }

    deleteSalaryOrder = () => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let deleteSalaryOrderRequestBody = {
            token: localStorage.getItem('token'),
            id: this.props.match.params.id
        }
        fetch(request("salary/deleteSalaryOrder", deleteSalaryOrderRequestBody))
            .then(res => res.text())
            .then((deleteSalaryOrderResponseJSON) => {
                const JSON = require('true-json-bigint');
                let deleteSalaryOrderResponse = JSON.parse(deleteSalaryOrderResponseJSON);
                if (deleteSalaryOrderResponse.status.statusCode != 1) {
                    if (deleteSalaryOrderResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(deleteSalaryOrderResponse.status.statusCode));
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    this.props.alertState(true, 'success', this.props.t('successful operation'), this.props.t(deleteSalaryOrderResponse.status.statusCode), null, null, this.props.history.push("/dashboard/salary-project/history"));
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let getBatchSalaryPayloadRequestBody = {
            token: localStorage.getItem('token'),
            socialStatus: this.state.formData?.legalStatus?.value?.id, // attention!
            orderType: this.state.formData.orderType,
            serviceId: this.state.formData.service.id,
            duration: this.state.formData?.duration?.value?.id,
            accontId: this.state.formData?.accontId?.value?.id,
            branchId: this.state.formData?.branch?.value?.id,
            note: this.state.formData.note?.value,
        }
        if (this.state.formData.orderType == 1) {
            getBatchSalaryPayloadRequestBody.document = this.state.formData.excelFile.value
        } else if (this.state.formData.orderType == 2) {
            const transformedOrders = this.state.orders.map(order => {
                const extractedValues = Object.keys(order).reduce((acc, key) => {
                    acc[key] = order[key].value;
                    return acc;
                }, {});
                return extractedValues;
            });
            getBatchSalaryPayloadRequestBody.orders = transformedOrders;
        }
        if (this.props.match.params.id) {
            getBatchSalaryPayloadRequestBody.id = this.props.match.params.id
        }
        fetch(request(`salary/${this.props.match.params.id ? "updateSalaryOrder" : "newSalaryOrder"}`, getBatchSalaryPayloadRequestBody))
            .then(res => res.text())
            .then((getBatchSalaryPayloadResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getBatchSalaryPayloadResponse = JSON.parse(getBatchSalaryPayloadResponseJSON);
                if (getBatchSalaryPayloadResponse.status.statusCode != 1) {
                    if (getBatchSalaryPayloadResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getBatchSalaryPayloadResponse.status.statusCode));
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    this.setState({
                        openSuccess: true, successData: {
                            currency: getBatchSalaryPayloadResponse.currency ?? "AZN",
                            duration: this.state.formData.duration?.value?.description,
                            count: getBatchSalaryPayloadResponse?.count,
                            branch: this.state.formData.branch?.value?.name,
                            fee: getBatchSalaryPayloadResponse.fee ?? "0 AZN",
                            account: getBatchSalaryPayloadResponse?.account
                        }
                    })
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    render() {
        let { payload, formData, orders, openSuccess, successData, open, verificationOpen, verificationCode } = this.state;
        let { t } = this.props;
        return (
            <>
                {/* <AlertPopup open={open} closeFunction={() => this.setState({ open: false })} popupType={popupType} title={title} feedbackMessage={feedbackMessage} closeSuccessFunction={this.closeSuccessFunction} /> */}
                <YesNoModal open={open} title={t('Are you sure you want to delete the operation?')} onClose={() => this.setState({ open: false })} triggerFunc={(bool) => bool ? this.deleteSalaryOrder() : this.setState({ open: false })} />
                <CreateSalaryOrderModal open={openSuccess} data={successData} successClose={() => this.props.history.push('/dashboard/salary-project/authorization')} close={() => this.props.history.push('/dashboard/salary-project/history')} />
                <div className="dashboard-header-route">
                    <div className="dashboard-header-route-left">
                        <button onClick={() => this.props.history.goBack()}>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.7344 21.2188L12.6719 20.3281C12.9062 20.0938 12.9062 19.7188 12.6719 19.5312L5.40625 12.2188H21.4375C21.7188 12.2188 22 11.9844 22 11.6562V10.3438C22 10.0625 21.7188 9.78125 21.4375 9.78125H5.40625L12.6719 2.51562C12.9062 2.32812 12.9062 1.95312 12.6719 1.71875L11.7344 0.828125C11.5469 0.59375 11.1719 0.59375 10.9375 0.828125L1.14062 10.625C0.90625 10.8594 0.90625 11.1875 1.14062 11.4219L10.9375 21.2188C11.1719 21.4531 11.5469 21.4531 11.7344 21.2188Z" fill="#2E3131" />
                            </svg>
                        </button>
                        <h2>{t('orders')}</h2>
                    </div>
                </div>
                <div className="dashboard-salary-container">
                    <div className="dashboard-salary-container-data-section" style={{ marginTop: '1px' }}>
                        <div className="dashboard-transfer-list-header">
                            <div className="dashboard-transfer-list-header-container dashboard-transfer-list-header-container__left">
                                <NavLink onClick={() => this.componentDidMount()} exact to="/dashboard/salary-project/order" className="dashboard-transfer-list-header-link" activeClassName="dashboard-transfer-list-header-container dashboard-transfer-list-header-link__active">{t("Order")}</NavLink>
                                <NavLink exact to="/dashboard/salary-project/history" className="dashboard-transfer-list-header-link" activeClassName="dashboard-transfer-list-header-container dashboard-transfer-list-header-link__active">{t("history")}</NavLink>
                                <NavLink exact to="/dashboard/salary-project/authorization" className="dashboard-transfer-list-header-link" activeClassName="dashboard-transfer-list-header-container dashboard-transfer-list-header-link__active">{t("authorization")}</NavLink>
                                <NavLink exact to="/dashboard/salary-project/list" className="dashboard-transfer-list-header-link" activeClassName="dashboard-transfer-list-header-container dashboard-transfer-list-header-link__active">{t("List of active salary cards")}</NavLink>
                                {this.props.match.params.id &&
                                    <NavLink to="/dashboard/salary-project/edit/order" className="dashboard-transfer-list-header-link" activeClassName="dashboard-transfer-list-header-container dashboard-transfer-list-header-link__active">{t("Edit")}</NavLink>
                                }
                            </div>
                        </div>
                        {payload &&
                            <div className="dashboard-salary-order-container">
                                <div className="dashboard-salary-order-container-buttons">
                                    {payload.services && payload.services.map((service, index) => (
                                        <button disabled={this.props.match.params.id} className={`dashboard-salary-order-container-button ${formData?.service?.id === service.id ? 'dashboard-salary-order-container-button__active' : ''}`} onClick={() => this.setState({
                                            formData: { service: service, orderType: formData.orderType, legalStatus: formData.legalStatus, excelFile: { value: null, name: '' } }, orders: [{}]
                                        })}>
                                            {index == 0 ? <svg xmlns="http://www.w3.org/2000/svg" width="24" height="22" viewBox="0 0 24 22" fill="none">
                                                <path d="M19 19.25V13.75M16 16.5H22M22 9.16671H2M22 11V7.51671C22 6.48995 22 5.97656 21.782 5.58439C21.5903 5.23943 21.2843 4.95896 20.908 4.7832C20.4802 4.58337 19.9201 4.58337 18.8 4.58337H5.2C4.0799 4.58337 3.51984 4.58337 3.09202 4.7832C2.7157 4.95896 2.40973 5.23943 2.21799 5.58439C2 5.97656 2 6.48994 2 7.51671V14.4834C2 15.5101 2 16.0235 2.21799 16.4157C2.40973 16.7607 2.71569 17.0411 3.09202 17.2169C3.51984 17.4167 4.0799 17.4167 5.2 17.4167H12" stroke="#849097" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg> : index == 1 ? <svg xmlns="http://www.w3.org/2000/svg" width="24" height="22" viewBox="0 0 24 22" fill="none">
                                                <path d="M2 9.16671H22V7.51671C22 6.48995 22 5.97656 21.782 5.58439C21.5903 5.23943 21.2843 4.95896 20.908 4.7832C20.4802 4.58337 19.9201 4.58337 18.8 4.58337H5.2C4.0799 4.58337 3.51984 4.58337 3.09202 4.7832C2.7157 4.95896 2.40973 5.23943 2.21799 5.58439C2 5.97656 2 6.48994 2 7.51671V14.4834C2 15.5101 2 16.0235 2.21799 16.4157C2.40973 16.7607 2.71569 17.0411 3.09202 17.2169C3.51984 17.4167 4.0799 17.4167 5.2 17.4167H11M14.5 19.25L16.525 18.8788C16.7015 18.8464 16.7898 18.8302 16.8721 18.8006C16.9452 18.7744 17.0147 18.7403 17.079 18.6991C17.1516 18.6528 17.2152 18.5944 17.3426 18.4777L21.5 14.6667C22.0523 14.1604 22.0523 13.3396 21.5 12.8334C20.9477 12.3271 20.0523 12.3271 19.5 12.8334L15.3426 16.6444C15.2152 16.7611 15.1516 16.8194 15.101 16.8859C15.0561 16.9449 15.0189 17.0086 14.9903 17.0756C14.958 17.151 14.9403 17.232 14.905 17.3938L14.5 19.25Z" stroke="#849097" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="24" height="22" viewBox="0 0 24 22" fill="none">
                                                <path d="M16 16.5H22M22 9.16671H2M22 12.375V7.51671C22 6.48995 22 5.97656 21.782 5.58439C21.5903 5.23943 21.2843 4.95896 20.908 4.7832C20.4802 4.58337 19.9201 4.58337 18.8 4.58337H5.2C4.0799 4.58337 3.51984 4.58337 3.09202 4.7832C2.7157 4.95896 2.40973 5.23943 2.21799 5.58439C2 5.97656 2 6.48994 2 7.51671V14.4834C2 15.5101 2 16.0235 2.21799 16.4157C2.40973 16.7607 2.71569 17.0411 3.09202 17.2169C3.51984 17.4167 4.0799 17.4167 5.2 17.4167H12" stroke="#849097" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>}
                                            <p className='dashboard-salary-order-container-button-title'>{service.description}</p>
                                        </button>
                                    ))}
                                </div>
                                <form action="" onSubmit={this.handleSubmit}>
                                    <RadioGroup
                                        aria-labelledby="demo-error-radios"
                                        name="quiz"
                                        sx={{ gap: '2rem' }}
                                        value={formData?.orderType}
                                        onChange={(event) => this.setState({ formData: { ...this.state.formData, excelFile: { name: '', value: null }, note: '', orderType: event.target.value }, orders: [{}] })}
                                    >
                                        {/* {formData?.service?.id != 3 && */}
                                        <div className="dashboard-salary-order-container-box">
                                            {formData?.service?.id != 3 && <>
                                                <div className="dashboard-salary-order-container-box-select">
                                                    <label>{t("Legal status")}</label>
                                                    <Autocomplete
                                                        options={payload.legalStatus ?? []}
                                                        getOptionLabel={option => option.description}
                                                        disableClearable
                                                        value={formData?.legalStatus?.value || payload.legalStatus[0]}
                                                        onChange={(event, newValue) => this.setValues(newValue, t("Required field"), "legalStatus")}
                                                        onInvalid={(event, newValue) => this.handleInvalid(event, t("Required field"), "legalStatus")}
                                                        renderInput={(params) => <TextField
                                                            {...params}
                                                            required
                                                            error={formData?.legalStatus?.helperText ? true : false}
                                                            helperText={formData?.legalStatus?.helperText}
                                                        />} />
                                                </div>
                                                <div className="dashboard-salary-order-container-box-select">
                                                    <label>{t("Card period")}</label>
                                                    <Autocomplete
                                                        options={payload.durations ?? []}
                                                        getOptionLabel={option => option.description}
                                                        value={formData?.duration?.value || null}
                                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                                        onChange={(event, newValue) => this.setValues(newValue, t("Required field"), "duration")}
                                                        onInvalid={(event, newValue) => this.handleInvalid(event, t("Required field"), "duration")}
                                                        renderInput={(params) => <TextField
                                                            {...params}
                                                            required
                                                            error={formData?.duration?.helperText ? true : false}
                                                            helperText={formData?.duration?.helperText}
                                                        />} />
                                                </div>
                                            </>
                                            }
                                            <div className="dashboard-salary-order-container-box-select">
                                                <label>{t("branch")}</label>
                                                <Autocomplete
                                                    options={payload.branches ?? []}
                                                    getOptionLabel={option => option.name}
                                                    value={formData?.branch?.value || null}
                                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                                    onChange={(event, newValue) => this.setValues(newValue, t("Required field"), "branch")}
                                                    onInvalid={(event, newValue) => this.handleInvalid(event, t("Required field"), "branch")}
                                                    renderInput={(params) => <TextField
                                                        {...params}
                                                        required
                                                        error={formData?.branch?.helperText ? true : false}
                                                        helperText={formData?.branch?.helperText}
                                                    />} />
                                            </div>
                                            {formData?.service?.id != 3 &&
                                                <div className={`dashboard-salary-order-container-box-select ${payload.accountVisible == 0 ? "disabled" : ""}`}>
                                                    <label>{t("Account for service fee deduction")}</label>
                                                    <Autocomplete
                                                        disabled={payload.accountVisible == 0}
                                                        options={payload.accounts ?? []}
                                                        getOptionLabel={option => option.iban + " / " + option.currentBalance + " " + option.currName}
                                                        value={formData?.accontId?.value || null}
                                                        onChange={(event, newValue) => this.setValues(newValue, t("Required field"), "accontId")}
                                                        onInvalid={(event, newValue) => this.handleInvalid(event, t("Required field"), "accontId")}
                                                        renderInput={(params) => <TextField
                                                            {...params}
                                                            required
                                                            error={formData?.accontId?.helperText ? true : false}
                                                            helperText={formData?.accontId?.helperText}
                                                        />} />
                                                </div>}
                                        </div>
                                        {/* } */}
                                        <div className="dashboard-salary-order-container-box-customer">
                                            <div className="dashboard-salary-order-container-box-header">
                                                <FormControlLabel disabled={this.props.match.params.id} value={1} control={<Radio sx={{ '&.Mui-checked': { color: '#6366F1' } }} />} label={t("Mass")} />
                                                {/* <button>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                        <path d="M10.6667 4.00004V3.46671C10.6667 2.71997 10.6667 2.3466 10.5213 2.06139C10.3935 1.8105 10.1895 1.60653 9.93865 1.4787C9.65344 1.33337 9.28007 1.33337 8.53333 1.33337H7.46667C6.71993 1.33337 6.34656 1.33337 6.06135 1.4787C5.81046 1.60653 5.60649 1.8105 5.47866 2.06139C5.33333 2.3466 5.33333 2.71997 5.33333 3.46671V4.00004M6.66667 7.66671V11M9.33333 7.66671V11M2 4.00004H14M12.6667 4.00004V11.4667C12.6667 12.5868 12.6667 13.1469 12.4487 13.5747C12.2569 13.951 11.951 14.257 11.5746 14.4487C11.1468 14.6667 10.5868 14.6667 9.46667 14.6667H6.53333C5.41323 14.6667 4.85318 14.6667 4.42535 14.4487C4.04903 14.257 3.74307 13.951 3.55132 13.5747C3.33333 13.1469 3.33333 12.5868 3.33333 11.4667V4.00004" stroke="#667085" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                </button> */}
                                            </div>
                                            <div className={`dashboard-salary-order-container-box ${formData?.orderType != 1 ? "disabled" : ""}`}>
                                                <div className="dashboard-salary-order-container-box-select">
                                                    <label>{t("Upload template")}</label>
                                                    <button className="dashboard-salary-order-container-box-select-upload" disabled={formData?.orderType != 1} type='button' onClick={this.downloadFile}>
                                                        <p className='dashboard-salary-order-container-button-title'>{t("Upload template")}</p>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                                                            <path d="M17.8333 12.5V13.5C17.8333 14.9001 17.8333 15.6002 17.5608 16.135C17.3211 16.6054 16.9386 16.9878 16.4682 17.2275C15.9334 17.5 15.2334 17.5 13.8333 17.5H6.83325C5.43312 17.5 4.73305 17.5 4.19828 17.2275C3.72787 16.9878 3.34542 16.6054 3.10574 16.135C2.83325 15.6002 2.83325 14.9001 2.83325 13.5V12.5M14.4999 8.33333L10.3333 12.5M10.3333 12.5L6.16659 8.33333M10.3333 12.5V2.5" stroke="white" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round" />
                                                        </svg>
                                                    </button>
                                                </div>
                                                <div className="dashboard-salary-order-container-box-select">
                                                    <label>{t("Add list")}</label>
                                                    <div className={"dashboard-salary-order-container-box-select-group " + (formData?.excelFile?.helperText ? "input-error" : "")}>
                                                        <input value={formData?.excelFile?.name} type='text' disabled={formData?.orderType != 1} className="dashboard-salary-order-container-box-select-group-input" />
                                                        {formData?.excelFile?.value ? <button className="dashboard-salary-order-container-box-select-group-input-icon" disabled={formData?.orderType != 1} onClick={this.removeFile}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                                                                <path d="M11.3334 4.00004V3.46671C11.3334 2.71997 11.3334 2.3466 11.1881 2.06139C11.0603 1.8105 10.8563 1.60653 10.6054 1.4787C10.3202 1.33337 9.94682 1.33337 9.20008 1.33337H8.13341C7.38668 1.33337 7.01331 1.33337 6.72809 1.4787C6.47721 1.60653 6.27324 1.8105 6.14541 2.06139C6.00008 2.3466 6.00008 2.71997 6.00008 3.46671V4.00004M7.33341 7.66671V11M10.0001 7.66671V11M2.66675 4.00004H14.6667M13.3334 4.00004V11.4667C13.3334 12.5868 13.3334 13.1469 13.1154 13.5747C12.9237 13.951 12.6177 14.257 12.2414 14.4487C11.8136 14.6667 11.2535 14.6667 10.1334 14.6667H7.20008C6.07998 14.6667 5.51992 14.6667 5.0921 14.4487C4.71578 14.257 4.40982 13.951 4.21807 13.5747C4.00008 13.1469 4.00008 12.5868 4.00008 11.4667V4.00004" stroke="#FC381D" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                            </svg>
                                                        </button> : <>
                                                            <div className={`dashboard-salary-order-container-box-select-group-input-icon ${false ? 'disabled' : ''}`}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                                    <g clip-path="url(#clip0_4_7030)">
                                                                        <path d="M8.00016 5.33337V10.6667M5.3335 8.00004H10.6668M14.6668 8.00004C14.6668 11.6819 11.6821 14.6667 8.00016 14.6667C4.31826 14.6667 1.3335 11.6819 1.3335 8.00004C1.3335 4.31814 4.31826 1.33337 8.00016 1.33337C11.6821 1.33337 14.6668 4.31814 14.6668 8.00004Z" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                                    </g>
                                                                    <defs>
                                                                        <clipPath id="clip0_4_7030">
                                                                            <rect width="16" height="16" fill="white" />
                                                                        </clipPath>
                                                                    </defs>
                                                                </svg>
                                                            </div>
                                                            <input className="dashboard-salary-order-container-box-select-group-input-select" disabled={formData?.orderType != 1} required id="excelFile" type="file" name="excelFile" multiple={false} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .xlsm" onChange={(e) => this.chooseFile(e, t("Upload the file"))} onInvalid={(e) => this.handleInvalid(e, t("Upload the file"))} />
                                                        </>}
                                                    </div>
                                                </div>
                                                <div className="dashboard-salary-order-container-box-select">
                                                    <label>{t("note")}</label>
                                                    <TextField
                                                        name='note'
                                                        disabled={formData?.orderType != 1}
                                                        value={formData?.orderType == 1 ? (formData?.note?.value ?? "") : ""}
                                                        onChange={(e) => this.setValues(e)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dashboard-salary-order-container-box-customer">
                                            <div className="dashboard-salary-order-container-box-header">
                                                <FormControlLabel disabled={this.props.match.params.id} value={2} control={<Radio sx={{ '&.Mui-checked': { color: '#6366F1' } }} />} label={t("individual")} />
                                                {/* <button disabled={formData?.orderType != 2}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                        <path d="M10.6667 4.00004V3.46671C10.6667 2.71997 10.6667 2.3466 10.5213 2.06139C10.3935 1.8105 10.1895 1.60653 9.93865 1.4787C9.65344 1.33337 9.28007 1.33337 8.53333 1.33337H7.46667C6.71993 1.33337 6.34656 1.33337 6.06135 1.4787C5.81046 1.60653 5.60649 1.8105 5.47866 2.06139C5.33333 2.3466 5.33333 2.71997 5.33333 3.46671V4.00004M6.66667 7.66671V11M9.33333 7.66671V11M2 4.00004H14M12.6667 4.00004V11.4667C12.6667 12.5868 12.6667 13.1469 12.4487 13.5747C12.2569 13.951 11.951 14.257 11.5746 14.4487C11.1468 14.6667 10.5868 14.6667 9.46667 14.6667H6.53333C5.41323 14.6667 4.85318 14.6667 4.42535 14.4487C4.04903 14.257 3.74307 13.951 3.55132 13.5747C3.33333 13.1469 3.33333 12.5868 3.33333 11.4667V4.00004" stroke="#667085" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                </button> */}
                                                <button type='button' disabled={formData?.orderType != 2} onClick={this.addOrder}>
                                                    +
                                                </button>
                                            </div>
                                            {orders.map((order, index) => (
                                                <div className={`dashboard-salary-order-container-box mb-5 ${formData?.orderType != 2 ? "disabled" : ""}`}>
                                                    <button disabled={formData?.orderType != 2} type='button' className="dashboard-salary-order-container-box-button-delete" onClick={() => this.deleteOrder(index)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                            <path d="M10.6667 4.00004V3.46671C10.6667 2.71997 10.6667 2.3466 10.5213 2.06139C10.3935 1.8105 10.1895 1.60653 9.93865 1.4787C9.65344 1.33337 9.28007 1.33337 8.53333 1.33337H7.46667C6.71993 1.33337 6.34656 1.33337 6.06135 1.4787C5.81046 1.60653 5.60649 1.8105 5.47866 2.06139C5.33333 2.3466 5.33333 2.71997 5.33333 3.46671V4.00004M6.66667 7.66671V11M9.33333 7.66671V11M2 4.00004H14M12.6667 4.00004V11.4667C12.6667 12.5868 12.6667 13.1469 12.4487 13.5747C12.2569 13.951 11.951 14.257 11.5746 14.4487C11.1468 14.6667 10.5868 14.6667 9.46667 14.6667H6.53333C5.41323 14.6667 4.85318 14.6667 4.42535 14.4487C4.04903 14.257 3.74307 13.951 3.55132 13.5747C3.33333 13.1469 3.33333 12.5868 3.33333 11.4667V4.00004" stroke="#667085" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                        </svg>
                                                    </button>
                                                    <div className="dashboard-salary-order-container-box-select">
                                                        <label>{t("FIN")}</label>
                                                        <TextField
                                                            required
                                                            name="pin"
                                                            disabled={formData?.orderType != 2}
                                                            error={order?.pin?.helperText ? true : false}
                                                            inputProps={{ maxLength: 7, minLength: 7 }}
                                                            placeholder={t("FIN")}
                                                            helperText={order?.pin?.helperText ?? ""}
                                                            value={order?.pin?.value ?? ""}
                                                            onChange={(e) => this.setOrder(index, e, t("Must be 7 characters long"))}
                                                            onInvalid={(e) => this.handleOrderInvalid(index, e, t("Must be 7 characters long"))} />
                                                    </div>
                                                    <div className="dashboard-salary-order-container-box-select">
                                                        <label>{t("First name, Last name, Patronymic")}</label>
                                                        <TextField
                                                            required
                                                            name="fullname"
                                                            disabled={formData?.orderType != 2}
                                                            error={order?.fullname?.helperText ? true : false}
                                                            // inputProps={{ maxLength: 7, minLength: 7 }}
                                                            placeholder={t("First name, Last name, Patronymic")}
                                                            helperText={order?.fullname?.helperText}
                                                            value={order?.fullname?.value ?? ""}
                                                            onChange={(e) => this.setOrder(index, e, t("Required field"))}
                                                            onInvalid={(e) => this.handleOrderInvalid(index, e, t("Required field"))} />
                                                    </div>
                                                    {formData?.service?.id != 1 ?
                                                        <div className="dashboard-salary-order-container-box-select">
                                                            <label>{t("account number")}</label>
                                                            <TextField
                                                                required
                                                                name="iban"
                                                                disabled={formData?.orderType != 2}
                                                                error={order?.iban?.helperText ? true : false}
                                                                // inputProps={{ maxLength: 7, minLength: 7 }}
                                                                placeholder={t("account number")}
                                                                helperText={order?.iban?.helperText}
                                                                value={order?.iban?.value ?? ""}
                                                                onChange={(e) => this.setOrder(index, e, t("Required field"))}
                                                                onInvalid={(e) => this.handleOrderInvalid(index, e, t("Required field"))} />
                                                        </div> :
                                                        <><div className="dashboard-salary-order-container-box-select">
                                                            <label>{t("ID number")}</label>
                                                            <div className="dashboard-salary-order-container-box flex-nowrap">
                                                                <Autocomplete
                                                                    options={payload.serial ?? []}
                                                                    getOptionLabel={option => option}
                                                                    disableClearable
                                                                    disabled={formData?.orderType != 2}
                                                                    value={order?.serial?.value || null}
                                                                    onChange={(event, newValue) => this.setOrder(index, newValue, t("Required field"), "serial")}
                                                                    onInvalid={(event, newValue) => this.handleOrderInvalid(index, event, t("Required field"), "serial")}
                                                                    renderInput={(params) => <TextField
                                                                        {...params}
                                                                        required
                                                                        placeholder={t("Choose")}
                                                                        error={order?.serial?.helperText ? true : false}
                                                                        helperText={order?.serial?.helperText || order?.serialNumber?.helperText}
                                                                        sx={{ "& .MuiFormHelperText-root": { whiteSpace: 'nowrap'}}}
                                                                    />} style={{width: 84}} />
                                                                <TextField
                                                                    required
                                                                    name="serialNumber"
                                                                    disabled={formData?.orderType != 2}
                                                                    error={order?.serialNumber?.helperText ? true : false}
                                                                    inputProps={{ maxLength: 8, minLength: 7 }}
                                                                    placeholder={t("1234567")}
                                                                    value={order?.serialNumber?.value ?? ""}
                                                                    onChange={(e) => (e.target.value == '' || /^[0-9]*$/.test(e.target.value)) && this.setOrder(index, e, t("Incorrectly entered"))}
                                                                    onInvalid={(e) => this.handleOrderInvalid(index, e, t("Incorrectly entered"))} />
                                                            </div>
                                                        </div>
                                                            <div className="dashboard-salary-order-container-box-select">
                                                                <label>{t("Position")}</label>
                                                                <TextField
                                                                    required
                                                                    name="position"
                                                                    disabled={formData?.orderType != 2}
                                                                    error={order?.position?.helperText ? true : false}
                                                                    // inputProps={{ maxLength: 7, minLength: 7 }}
                                                                    placeholder={t("Position")}
                                                                    helperText={order?.position?.helperText}
                                                                    value={order?.position?.value ?? ""}
                                                                    onChange={(e) => this.setOrder(index, e, t("Required field"))}
                                                                    onInvalid={(e) => this.handleOrderInvalid(index, e, t("Required field"))} />
                                                            </div>
                                                            <div className="dashboard-salary-order-container-box-select">
                                                                <label>{t("Salary")}</label>
                                                                <TextField
                                                                    required
                                                                    name="salary"
                                                                    disabled={formData?.orderType != 2}
                                                                    error={order?.salary?.helperText ? true : false}
                                                                    // inputProps={{ maxLength: 7, minLength: 7 }}
                                                                    placeholder={t("Salary")}
                                                                    helperText={order?.salary?.helperText}
                                                                    value={order?.salary?.value ?? ""}
                                                                    onChange={(e) => (e.target.value == '' || /^\d+(\.\d{0,2})?$/.test(e.target.value)) && this.setOrder(index, e, t("Required field"))}
                                                                    onInvalid={(e) => this.handleOrderInvalid(index, e, t("Required field"))} />
                                                            </div>
                                                            <div className="dashboard-salary-order-container-box-select">
                                                                <label>{t("Email")}</label>
                                                                <TextField
                                                                    type='email'
                                                                    name="email"
                                                                    disabled={formData?.orderType != 2}
                                                                    error={order?.email?.helperText ? true : false}
                                                                    inputProps={{ maxLength: 250, minLength: 8 }}
                                                                    placeholder={t("Email")}
                                                                    helperText={order?.email?.helperText || t("If not available, leave empty")}
                                                                    value={order?.email?.value ?? ""}
                                                                    onChange={(e) => this.setOrder(index, e, t("Incorrect email format"))}
                                                                    onInvalid={(e) => this.handleOrderInvalid(index, e, t("Incorrect email format"))} />
                                                            </div>
                                                            <div className="dashboard-salary-order-container-box-select">
                                                                <label>{t("phone number")}</label>
                                                                <InputMask
                                                                    required
                                                                    name="mobile"
                                                                    disabled={formData?.orderType != 2}
                                                                    mask="+\9\9\4 99 999 99 99"
                                                                    className={`dashboard-salary-order-container-box-select-input ${order?.mobile?.helperText ? "input-outline" : ""}`}
                                                                    placeholder="+994 "
                                                                    // error={formData?.mobile?.helperText ? true : false}
                                                                    // inputProps={{ maxLength: 250, minLength: 8 }}
                                                                    // helperText={formData?.mobile?.helperText}
                                                                    value={order?.mobile?.value ?? ""}
                                                                    onChange={(e) => this.setOrder(index, e, t("Incorrectly entered"))}
                                                                    onInvalid={(e) => this.handleOrderInvalid(index, e, t("Incorrectly entered"))} />
                                                                {order?.mobile?.helperText && <span className='MuiFormHelperText-root Mui-required' style={{ fontSize: '.75rem', color: '#d32f2f', marginTop: ".2rem" }}>{order?.mobile?.helperText}</span>}
                                                            </div>
                                                            <div className="dashboard-salary-order-container-box-select">
                                                                <label>{t("Code word")}</label>
                                                                <TextField
                                                                    required
                                                                    name="security"
                                                                    disabled={formData?.orderType != 2}
                                                                    error={order?.security?.helperText ? true : false}
                                                                    inputProps={{ maxLength: 8, minLength: 4 }}
                                                                    // placeholder='Email'
                                                                    helperText={order?.security?.helperText}
                                                                    value={order?.security?.value ?? ""}
                                                                    onChange={(e) => (e.target.value == '' || /^[A-Za-z0-9]+$/.test(e.target.value)) && this.setOrder(index, e, t("Code word must be a maximum of 8 characters - Latin letters or numbers."))}
                                                                    onInvalid={(e) => this.handleOrderInvalid(index, e, t("Code word must be a maximum of 8 characters - Latin letters or numbers."))}
                                                                    InputProps={{
                                                                        endAdornment: <InputAdornment position="start">
                                                                            <span>
                                                                                <span style={order?.security?.value?.length > 3 ? { color: '#0179D2' } : {}}> {order?.security?.value?.length || 0} </span>
                                                                                <span style={{ color: '#0179D2' }}>/ 8</span>
                                                                            </span>
                                                                        </InputAdornment>
                                                                    }} />
                                                            </div>
                                                        </>}
                                                    {/* <div className={`dashboard-salary-order-container-box-select ${index != 0 && "d-none"}`}>
                                                        <label>{t("note")}</label>
                                                        <TextField
                                                            name='note'
                                                            disabled={formData?.orderType != 2}
                                                            value={formData?.orderType == 2 ? (formData?.note?.value ?? "") : ""}
                                                            onChange={(e) => this.setValues(e)} />
                                                    </div> */}
                                                </div>))}
                                        </div>
                                    </RadioGroup>
                                    <div className="dashboard-salary-order-container-box justify-content-end mt-4">
                                        {/* <Button variant="text" className='dashboard-salary-order-container-box-button-submit'>{t("close")}</Button> */}
                                        {this.props.match.params.id && <button variant="contained" type='button' color='error' className='dashboard-salary-order-container-box-button-delete-order' onClick={() => this.setState({ open: true })}>{t("Delete")}</button>}
                                        <button variant="contained" type='submit' className='dashboard-salary-order-container-box-button-submit-order'>{t("save")}</button>
                                    </div>
                                </form>
                                {/* <FormHelperText>{helperText}</FormHelperText>
                                <Button sx={{ mt: 1, mr: 1 }} type="submit" variant="outlined">
                                    Check Answer
                                </Button> */}
                            </div>}
                    </div>
                </div>
            </>
        )
    }
}

export default translate("translation")(Order);