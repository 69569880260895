import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { Switch, Redirect, Route, } from 'react-router-dom';
import Credit from './Credit';
import PlasticCard from './PlasticCard';
import Account from './Account';
class CreateTransferConvertation extends Component {

    render() {
        let { updateAppState, updateDashboardState, alertState, logout } = this.props;

        return (
            <Switch>
                <Redirect exact from="/dashboard/orders/" to="/dashboard/orders/credit" />
                <Route path="/dashboard/orders/credit" render={(routeProps) => <Credit logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                <Route path="/dashboard/orders/plastic-card" render={(routeProps) => <PlasticCard logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                {localStorage.getItem('checkResident') === "1" && localStorage.getItem("isAsan") === "true" &&
                    <Route path="/dashboard/orders/account" render={(routeProps) => <Account logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                }
            </Switch>
        )
    }
}
export default translate("translation")(CreateTransferConvertation);
